import { Component, inject, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { MatFormField, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { CdkCopyToClipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MapService } from '../api/services/map.service';
import { SelectInputComponent } from '../shared/forms/select-input/select-input.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-embed-dialog',
  styles: [`
    ::ng-deep {

      .share-map-form {
        --mdc-theme-primary: #000000;

        .mdc-floating-label {
          color: #000000 !important;
        }
      }

    }
  `],
  template: `
    <div class="w-100 d-flex justify-content-between align-items-center dialog-header">
      <h2 class="align-items-center d-flex m-0 p-0">
        <span class="material-symbols-rounded me-2">integration_instructions</span>
        Embed Smap
      </h2>
      <mat-icon class="material-symbols-rounded close-button"
                mat-dialog-close
                matTooltip="Close the panel">
        close
      </mat-icon>
    </div>
    <div mat-dialog-content class="p-3 px-4">
      <h4>Instructions</h4>
      <ol>
        <li>Make the smap public so that anyone can see it as a smaplet.</li>
        <li>Copy and paste the embed code into the HTML of any web page.</li>
        <li>Embed as many smaps as you want into the same web page.</li>
      </ol>
      <h4>Smap Access</h4>
      <app-select-input [label]="'This smap’s access level is'"
                        [formControl]="accessControl"
                        [options]="accessOptions"></app-select-input>
      <h4 class="mt-3">Embed Code</h4>
      <mat-form-field class="w-100">
        <textarea [value]="iframe(data.mapId)" #embededText matInput disabled cdkTextareaAutosize></textarea>
        <button
          mat-icon-button
          matSuffix
          [cdkCopyToClipboard]="iframe(data.mapId)"
          matTooltip="Copy smap embedding code into clipboard"
          (click)="embededText.select(); snackBar.open('Code copied to clipboard', '', {duration: 2000}) ">
          <mat-icon class="material-symbols-outlined">content_copy</mat-icon>
        </button>
      </mat-form-field>
      <h4>Video Tutorial</h4>
      <iframe width="100%" height="315"
              src="https://www.youtube.com/embed/sY-TNMWRT0Q"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen></iframe>
    </div>
    <div mat-dialog-actions class="d-flex justify-content-center">
      <button mat-button mat-dialog-close>Cancel</button>
      <button mat-button color="accent" (click)="done()">OK</button>
    </div>
  `,
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatTooltipModule,
    MatIcon,
    MatFormField,
    MatInput,
    CdkTextareaAutosize,
    CdkCopyToClipboard,
    MatSuffix,
    SelectInputComponent,
    ReactiveFormsModule
  ]
})
export class EmbedDialogComponent {
  snackBar = inject(MatSnackBar);
  accessOptions = [
    {
      key: 'public',
      value: 'Public',
      icon: 'public'
    },
    {
      key: 'private',
      value: 'Private',
      icon: 'lock'
    }
  ];
  accessControl = new FormControl('private');
  private host = location.origin;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { mapId: string, isPublic: boolean },
              private matDialogRef: MatDialogRef<EmbedDialogComponent>,
              private mapService: MapService) {
    console.log(data);
    this.accessControl.setValue(data.isPublic ? 'public' : 'private');
  }

  public iframe = (mapId: string) =>
    `<iframe sandbox="allow-scripts allow-same-origin allow-popups" src="${this.host}/dashboard?mapId=${mapId}" width="100%" height="100%"></iframe>`;

  done() {
    const publish = this.accessControl.value === 'public';
    const stream = publish ?
      this.publish() : this.unpublish();
    stream
      .subscribe(() => this.matDialogRef.close({ published: publish }));
  }

  publish() {
    return this.mapService.openPublicAccessToMap(this.data.mapId);
  }

  unpublish() {
    return this.mapService.closePublicAccessToMap(this.data.mapId);
  }
}
