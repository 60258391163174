import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, REMOVE_STYLES_ON_COMPONENT_DESTROY } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { TopToolbarComponent } from './nav/top-toolbar.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions, MatTooltipModule } from '@angular/material/tooltip';
import { BlockUIModule } from 'ng-block-ui';
import { AuthTokenInterceptor } from './interceptors/auth.interceptor';
import { BlockUiInterceptor } from './interceptors/block-ui.interceptor';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatToolbarModule } from '@angular/material/toolbar';
import { PackageService } from './api/services/package.service';
import { HasPackageDirective } from './packages/has-package.directive';
import { HasModuleDirective } from './packages/has-module.directive';
import { MarkdownModule, MARKED_OPTIONS } from 'ngx-markdown';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { GlobalErrorHandler } from './shared/helpers/error.handler.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { IdleResetInterceptor } from './interceptors/idle-http.interceptor';
import { TextInputComponent } from './shared/forms/text-input/text-input.component';
import { EditModeButtonComponent } from './shared/edit-mode-btn/edit-mode-button.component';
import { AppWideStateService } from './services/app-state/app-wide-state.service';
import { isInIframe } from './shared/helpers/maporium.validators';

// A polyfill for the passive event listener, which is required for the drag and drop to work
// eslint-disable-next-line @typescript-eslint/no-empty-function
document.addEventListener("mousemove", () => {}, { passive: false, capture: true });


@NgModule({
  declarations: [
    AppComponent,
    TopToolbarComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    BlockUIModule.forRoot(),
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    HttpClientModule,
    HasPackageDirective,
    HasModuleDirective,
    MatDialogModule,
    MatBottomSheetModule,
    RouterModule.forRoot([
      { path: '', pathMatch: 'full', redirectTo: 'login' },
      { path: 'login', loadComponent: () => import('./login/login.component').then(m => m.LoginComponent) },
      {
        path: 'dashboard',
        loadComponent: () => import('./dashboard/dashboard.component').then(m => m.DashboardComponent)
      },
      {
        path: '404',
        loadComponent: () => import('./errors/404.component').then(m => m.NotFoundComponent)
      }
    ]),
    MarkdownModule.forRoot({
      markedOptions: {
        provide: MARKED_OPTIONS,
        useValue: {
          gfm: false,
          breaks: true
        }
      }
    }),
    MatTooltipModule,
    MatDividerModule,
    MatInputModule,
    MatChipsModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatToolbarModule,
    MatProgressBarModule,
    TextInputComponent,
    EditModeButtonComponent
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: BlockUiInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: IdleResetInterceptor, multi: true},
    {
      provide: REMOVE_STYLES_ON_COMPONENT_DESTROY,
      useValue: false
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: APP_INITIALIZER,
      useFactory: (packageService: PackageService) => () => packageService.init(),
      deps: [PackageService],
      multi: true
    },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: <MatTooltipDefaultOptions>{
        showDelay: 1000,  // Delay before showing the tooltip
        hideDelay: 0,     // Delay before hiding the tooltip
        touchendHideDelay: 1000, // Delay before hiding the tooltip after a touchend event
      }
    }
  ],
  bootstrap: [AppComponent],
  exports: [
    HasModuleDirective
  ]
})
export class AppModule {
  constructor(private appStateService: AppWideStateService) {
    this.appStateService.setReadMode(isInIframe());
  }
}
