import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { IdleService } from '../services/idle.service';
import { Observable, tap } from 'rxjs';
import { isInIframe } from '../shared/helpers/maporium.validators';

@Injectable()
export class IdleResetInterceptor implements HttpInterceptor {

  constructor(private idleService: IdleService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Reset the idle timer on every request
    if (!isInIframe()) {
      this.idleService.resetTimer();
    }

    return next.handle(req).pipe(
      tap({
        complete: () => {
          if (!isInIframe()) {
            this.idleService.resetTimer();
          }
        }
      })
    );
  }
}
