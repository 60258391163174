import { Injectable } from '@angular/core';
import { CommentsResource } from '../resource/comments.resource';
import { Comment, CommentEntityType } from '../interfaces/comment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CommentService {
  constructor(private resource: CommentsResource) {
  }

  addComment(entityId: string, entityType: CommentEntityType, content: string) {
    return this.resource.addComment(entityId, entityType, content);
  }

  editComment(commentId: string, content: string) {
    return this.resource.editComment(commentId, content);
  }

  replyToComment(parentId: string, content: string) {
    return this.resource.replyToComment(parentId, content);
  }

  getComments(entityId: string): Observable<Comment[]> {
    return this.resource.getComments(entityId);
  }

  deleteComment(commentId: string) {
    return this.resource.deleteComment(commentId);
  }
}
