import { AbstractControl, ValidationErrors } from '@angular/forms';

export class MaporiumValidators {
  static strictEmail(control: AbstractControl): ValidationErrors | null {
    return strictEmail(control);
  }

  static gptKey(control: AbstractControl): ValidationErrors | null {
    return getGptKeyValidator(control);
  }
}


export function strictEmail(control: AbstractControl): ValidationErrors | null {
  // Allowing "+" and additional characters in the local part
  const emailRegex = /^[a-zA-Z0-9._-]+(\+[a-zA-Z0-9._-]+)?@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  const isValid = emailRegex.test(control.value);
  return isValid ? null : { 'email': { value: control.value } };
}

export function getGptKeyValidator(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null; // No value provided, validation passes
    }

    // Define a regular expression pattern for a valid GPT key
    const gptKeyPattern = /^[a-zA-Z0-9-]*/g;

    // Test if the provided key matches the pattern
    if (gptKeyPattern.test(control.value)) {
      // Additional checks to ensure the key is not a URL or chat message
      const lowerCaseKey = control.value.toLowerCase();

      // Check if the key contains common URL patterns
      if (
        lowerCaseKey.includes('http://') ||
        lowerCaseKey.includes('https://') ||
        lowerCaseKey.includes('www.') ||
        lowerCaseKey.includes('.com') ||
        lowerCaseKey.includes('.net') ||
        lowerCaseKey.includes('.org')
      ) {
        return { invalidGptKey: true }; // Key contains URL patterns, invalid
      }

      // Check if the key contains common chat message patterns
      if (
        lowerCaseKey.includes('hello') ||
        lowerCaseKey.includes('chat') ||
        lowerCaseKey.includes('message')
      ) {
        return { invalidGptKey: true }; // Key contains chat message patterns, invalid
      }

      return null; // All checks passed, validation succeeds
    }

    return { invalidGptKey: true }; // Key format doesn't match, validation fails
}

export function isInIframe(): boolean {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}
