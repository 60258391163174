import contextMenus from 'cytoscape-context-menus';
// @ts-ignore
import * as undoRedo from 'cytoscape-undo-redo';
import cytoscape from 'cytoscape';


export function initCyPlugins() {
  // @ts-ignore
  const expandCollapse = require('cytoscape-expand-collapse');
  // @ts-ignore
  const convert = require('color-convert');
// @ts-ignore
  const coseBilkent = require('cytoscape-cose-bilkent');
// @ts-ignore
  const edgehandles = require('cytoscape-edgehandles');
// @ts-ignore
  const cxtmenu = require('cytoscape-cxtmenu');
// @ts-ignore
  const compoundDragAndDrop = require('cytoscape-compound-drag-and-drop');
// @ts-ignore
  const cyCanvas = require('cytoscape-canvas');
// @ts-ignore
  const gridGuide = require('cytoscape-grid-guide');
// @ts-ignore
  const nodeHtml = require('cytoscape-node-html-label');

  cytoscape.use(coseBilkent);
  cytoscape.use(edgehandles);
  cytoscape.use(cxtmenu);
// cytoscape.use(compoundDragAndDrop);
  cytoscape.use(cyCanvas);
  cytoscape.use(contextMenus);
  cytoscape.use(expandCollapse);
  // cytoscape.use(undoRedo);
  cytoscape.use(nodeHtml);
  gridGuide( cytoscape );
}
