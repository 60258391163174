import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import {BehaviorSubject, finalize, Observable} from 'rxjs';
import { tap } from 'rxjs/operators';
import {BlockUIService} from 'ng-block-ui';

@Injectable({ providedIn: 'root'})
export class BlockUiInterceptor implements HttpInterceptor {
  public static isLoadingSubject = new BehaviorSubject(false);

  private readonly whitelist: string[] = [
    '/api/login', // List of URLs that should not trigger BlockUi
    '/api/signup',
    '/api/refresh-token',
    '/api/gpt/prompt',
    'api/nodes/position',
    'api/nodes',
    'api/nodes/parent',
    'api/nodes/link/target',
    'api/map/user',
    'api/tags',
    'api/saved-view',
    'api/tours',
    'api/map/templates',
    'api/settings/general-info',
    'api/comment'
    // 'api/map/with-users',
  ];
  constructor(private readonly blockUiService: BlockUIService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    BlockUiInterceptor.isLoadingSubject.next(true);
    if (!this.whitelist.some((url) => req.url.includes(url))) {
      this.blockUiService.start('UI');
    }

    return next.handle(req).pipe(
      tap((t) => {
        if (!this.whitelist.some((url) => req.url.includes(url)) && t?.type !== 0) {
         this.blockUiService.stop('UI');
        }
      }, (err) => this.blockUiService.stop('UI')),
      finalize(() => {
        BlockUiInterceptor.isLoadingSubject.next(false);
      })
    );
  }
}
