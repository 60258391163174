import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appAutoResizeInput]',
  standalone: true
})
export class AutoResizeInputDirective implements OnInit {
  @Input() defaultHeight = 'auto';

  constructor(private el: ElementRef) {
    setTimeout(() => {
      this.resize();
    }, 300);
  }

  @HostListener('input')
  onInput() {
    this.resize();
  }

  ngOnInit() {
    this.resize();
  }

  resize() {
    const textarea = this.el.nativeElement;

    // Reset the height to auto to measure the scrollHeight accurately
    textarea.style.height = this.defaultHeight;

    // Check if the text is more than one line
    if (textarea.scrollHeight > textarea.clientHeight) {
      textarea.style.height = textarea.scrollHeight + 'px';
    }
  }
}
