import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { ColorSketchModule } from 'ngx-color/sketch';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MapService } from '../api/services/map.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CdkDrag, CdkDragHandle, CdkDropList, CdkDropListGroup } from '@angular/cdk/drag-drop';

import { MatExpansionModule } from '@angular/material/expansion';
import { MapTour } from '../api/interfaces/map-tour';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TextInputComponent } from '../shared/forms/text-input/text-input.component';
import { SelectInputComponent } from '../shared/forms/select-input/select-input.component';
import { SliderInputComponent } from '../shared/forms/slider-input/slider-input.component';
import { TextAreaComponent } from '../shared/forms/text-area/text-area-input.component';
import { SlideToggleInputComponent } from '../shared/forms/slide-select/slide-toggle-input.component';
import { MapStateService } from '../api/services/map-state.service';
import { MapStateDto } from '@maporium-workspace/shared';

@Component({
  selector: 'maporium-new-map-state-dialog',
  styleUrls: ['./create-new-tour-dialog.component.scss'],
  template: `
    <div class="w-100 d-flex justify-content-between align-items-center dialog-header">
      <h2 class="align-items-center d-flex m-0 p-0"><span class="material-symbols-rounded me-2">{{data ? 'edit' : 'add_circle'}}</span>
        {{data ? 'Update State' : 'Create State'}}
      </h2>
      <mat-icon class="material-symbols-rounded close-button"  mat-dialog-close matTooltip="Close the panel">
        close
      </mat-icon>
    </div>

    <div mat-dialog-content>
      <div cdkDropListGroup>
        <form [formGroup]="myForm" class="mb-3">
          <app-text-input [label]="'Title'" formControlName="name" [autoFocusAndSelect]="true"></app-text-input>
          <app-text-area [label]="'Description'" formControlName="description"></app-text-area>
        </form>
      </div>

    </div>
    <div mat-dialog-actions class="d-flex justify-content-evenly">
      <button mat-button mat-dialog-close>Cancel</button>
      <button mat-button (click)="data ? edit() : create()"
              [disabled]="!myForm.valid"
              color="accent">{{data ? 'Update' : 'Create'}}</button>
    </div>
  `,
  imports: [
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    ColorSketchModule,
    CommonModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatCheckboxModule,
    CdkDropListGroup,
    CdkDropList,
    CdkDrag,
    MatExpansionModule,
    MatIconModule,
    MatTooltipModule,
    TextInputComponent,
    SelectInputComponent,
    SliderInputComponent,
    CdkDragHandle,
    TextAreaComponent,
    SlideToggleInputComponent
  ],
  standalone: true
})

export class CreateNewMapStateDialogComponent implements OnInit {
  myForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MapTour,
    private matDialogRef: MatDialogRef<CreateNewMapStateDialogComponent>,
    private mapService: MapService,
    private mapStateService: MapStateService,
    private formBuilder: FormBuilder
  ) {
    this.myForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: '',
    });
  }

  ngOnInit(): void {
    if (this.data) {
      this.myForm.patchValue({
        name: this.data.name,
        description: this.data.description,
      });
    }
  }




  create() {
    if (this.myForm.valid) {
      const payload: MapStateDto = {
        name: this.myForm.value.name,
        description: this.myForm.value.description,
        map: this.mapService.getCurrentSelectedMapFromStore() as any,
      };
      this.mapStateService.createForMap(this.mapService.getCurrentSelectedMapFromStore()?.id, payload).subscribe((state) => {
        this.matDialogRef.close(state);
      });
    }
  }

  edit() {
    if (this.myForm.valid) {
      const payload: MapStateDto = {
        id: this.data.id,
        name: this.myForm.value.name,
        description: this.myForm.value.description,
        map: this.mapService.getCurrentSelectedMapFromStore() as any,
      }
      this.mapStateService.update(this.data.id as string, payload).subscribe((state) => {
        this.matDialogRef.close(state);
      });
    }
  }

}
