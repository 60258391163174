import { Directive, ElementRef, Input, AfterViewInit, Renderer2 } from '@angular/core';
import { LinkTarget } from '../pipes/link-target/link-target.enum';
import { DatatifyDirective } from './datatify.directive';
import { isInIframe } from '../helpers/maporium.validators';
import { SettingsService } from '../../api/services/setting.service';


@Directive({
  selector: '[appExternalLinks]',
  standalone: true
})
export class ExternalLinksDirective implements AfterViewInit {

  constructor(private el: ElementRef,
              private settingsService: SettingsService,
              private renderer: Renderer2) {}

  ngAfterViewInit() {
    const anchorElements = this.el.nativeElement.querySelectorAll('a');
    const settings = this.settingsService.getLocalStorageSettings();
    // @ts-ignore
    let linkTarget = settings.linkTarget * 1 ?? LinkTarget.SingleWindow;

    if (isInIframe() && isNaN(linkTarget)) {
      linkTarget = LinkTarget.SingleWindow;
    }
    anchorElements.forEach((anchor: HTMLAnchorElement) => {
      this.renderer.listen(anchor, 'click', (event: Event) => {
        event.preventDefault();
        DatatifyDirective.onLinkClick(event, anchor.href, linkTarget);
      });
    });
  }
}
