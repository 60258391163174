import {
  ChangeDetectorRef,
  Component,
  effect,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GraphNode, GraphNodeLink } from '../api/interfaces/graph-node.interface';
import { NodeFormComponent } from './node-form/node-form.component';
import { MapStateService } from '../api/services/map-state.service';
import { LinkFormComponent } from './link-form/link-form.component';
import { LinkViewComponent } from './link-view/link-view.component';
import { NodeViewComponent } from './node-view/node-view.component';
import { SidebarService } from './sidebar.service';
import { CytoscapeService } from '../services/cy-services/cytoscape.service';
import {
  getLinkBatchChangePropertiesFromDiff,
  getMultiElementPropertiesFromDiff,
  getNodeBatchChangePropertiesFromDiff,
  MultiUpdateEvent
} from './dtos/node-allowed-multi-change';
import { diff } from '../shared/helpers/diff';
import { isEqual, omit } from 'lodash';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { AppWideStateService } from '../services/app-state/app-wide-state.service';
import { OpenaiService } from '../api/services/openai.service';
import { isInIframe } from '../shared/helpers/maporium.validators';

@Component({
  selector: 'maporium-sidebar-form',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatTooltipModule, NodeFormComponent, LinkFormComponent, LinkViewComponent, NodeViewComponent],
  templateUrl: './sidebar-form.component.html',
  styleUrls: ['./sidebar-form.component.scss']
})
export class SidebarFormComponent implements OnChanges {
  @Input() readonly = false;
  @Input() focusedNode: GraphNode | undefined;
  @Input() focusedLink: GraphNodeLink | undefined;

  @Output() updateNode = new EventEmitter<Partial<GraphNode>>();
  @Output() updateLink = new EventEmitter<Partial<GraphNodeLink>>();
  @Output() updateMultiple = new EventEmitter<MultiUpdateEvent>();
  @Output() resetMultiple = new EventEmitter<{ linkIds: string[], nodeIds: string[] }>();
  @Output() stateReset = new EventEmitter<{ node?: Partial<GraphNode>, link?: Partial<GraphNodeLink> }>();

  isEditMode = false;
  public readModeAppState = this.appWideStateService.readMode();

  excludeFromStateOptions = [
    { key: 'false', value: 'included', icon: 'add_circle' },
    { key: 'true', value: 'excluded', icon: 'do_not_disturb_on' }
  ];

  constructor(private sidebarService: SidebarService,
              private cyService: CytoscapeService,
              private cdr: ChangeDetectorRef,
              private appWideStateService: AppWideStateService,
              private openaiService: OpenaiService,
              private mapStateService: MapStateService) {

    effect(() => {
      this.isEditMode = this.sidebarService.editMode;
      if (!this.isEditMode) {
        if (this.focusedLink?.excludedFromState || this.focusedNode?.excludedFromState) {
          this.cyService.cy?.$(':selected').unselect();
          this.focusedNode = undefined;
          this.focusedLink = undefined;
          DashboardComponent.tappedNode = null;
          DashboardComponent.tappedLink = null;
        }
      }
    });

    effect(() => {
      this.readModeAppState = this.appWideStateService.readMode();
    });
  }

  get canEdit() {
    return this.isEditMode && (!this.readonly || !isInIframe());
  }

  get isDefaultState() {
    return this.mapStateService.getCurrentLocalState()?.id === undefined;
  }

  ngOnChanges(changes: SimpleChanges): void {
    //@ts-ignore
    if (this.focusedLink?.isNew || this.focusedNode?.isNew) {
      this.sidebarService.enableEditMode();
    }
  }

  public onNodeUpdate(event: Partial<GraphNode>, hasComments?: boolean) {
    const cy = this.cyService.cy;
    if (!cy) {
      return;
    }
    const allSelected = cy.$(':selected');
    if (allSelected.length > 1) {
      const difference = diff(this.focusedNode, event);
      this.updateMultiple.emit({
        node: getNodeBatchChangePropertiesFromDiff(difference),
        general: getMultiElementPropertiesFromDiff(difference)
      });

    } else {
      const toCompare = omit(this.focusedNode, [
        'children',
        'expandcollapseRenderedCueSize',
        'expandcollapseRenderedStartY',
        'grabbedPosition',
        'x',
        'y',
        'isSaved',
        'position',
        'draggedBy',
        'touched',
        'selected',
        'expandcollapseRenderedStartX',
        'collapsed',
        'originalNode',
        'label'
      ]);
      const updateHasComments = () => {
        setTimeout(() => {
          event.hasComments = hasComments;
          this.updateNode.emit(event);
        }, 300);
      };
      if (hasComments !== undefined) {
        updateHasComments();
        return;
      }

      if (hasComments === undefined && toCompare.hasComments !== event.hasComments) {
        updateHasComments();
        this.updateNode.emit(event);
        return;
      }

      if (isEqual(toCompare, omit(event, 'originalNode'))) {
        return;
      }
      setTimeout(() => {
        this.updateNode.emit(event);
      }, 200);
    }
  }

  onReset($event: { node?: Partial<GraphNode>, link?: Partial<GraphNodeLink> }) {
    if (!this.cyService.cy) {
      return;
    }
    const selectedElements = this.cyService.cy.$(':selected');
    // Are we in multiselect ?
    if (this.cyService.cy.$(':selected').length > 1) {
      const resetPayload = {
        linkIds: selectedElements
          .filter((e) => e.isEdge())
          .map((e) => e.id()),
        nodeIds: selectedElements
          .filter((e) => e.isNode())
          .map((n) => n.id())
      };
      this.resetMultiple.emit(resetPayload);
    } else {
      this.stateReset.emit($event);
    }
  }

  public onLinkUpdate(event: Partial<GraphNodeLink>, hasComments?: boolean) {
    const cy = this.cyService.cy;
    if (!cy) {
      return;
    }
    const allSelected = cy.$(':selected');

    if (allSelected.length > 1) {
      const difference = diff(this.focusedLink, event);
      this.updateMultiple.emit({
        general: getMultiElementPropertiesFromDiff(difference),
        link: getLinkBatchChangePropertiesFromDiff(difference)
      });
    } else {
      const toCompare = omit(this.focusedLink, 'origin');

      if (isEqual(toCompare, event) && hasComments === undefined) {
        this.updateLink.emit(event);
        return;
      }
      setTimeout(() => {
        if (hasComments !== undefined) {
          event.hasComments = hasComments;
        }
        if (event.excludedFromState) {
          // @ts-ignore
          this.focusedLink.excludedFromState = true;
        }
      }, 200);

      this.updateLink.emit(event);

    }
  }

  public onMetaExtend() {
    const isNodeExtension = this.focusedNode !== undefined;
    const dto = {
      title: isNodeExtension ? this.focusedNode?.name || '' : this.focusedLink?.name || '',
      description: isNodeExtension ? this.focusedNode?.description || '' : this.focusedLink?.description || '',
      metadata: isNodeExtension ? this.focusedNode?.metadata || [] : this.focusedLink?.metadata || []
    };
    this.openaiService.extendMetadata(dto).subscribe((result) => {
      if (isNodeExtension) {
        this.onNodeUpdate({ metadata: result });
        if (this.focusedNode) {
          const node = { ...this.focusedNode };
          node.metadata = result;
          this.focusedNode = node;
          this.cdr.detectChanges();
        }
      } else {
        this.onLinkUpdate({ metadata: result });
        if (this.focusedLink) {
          const link = { ...this.focusedLink };
          link.metadata = result;
          this.focusedLink = link;
          this.cdr.detectChanges();
        }
      }
    });
  }
}
