<form [formGroup]="formGroup" autocomplete="off" *ngIf="formGroup">
  <maporium-title-description (fieldsChange)="onNameDescriptionChange($event)"
                              [isNew]="focusedNode?.isNew || false"
                              [fields]="{title: formGroup.get('name')?.value || '', description: formGroup.get('description')?.value || ''}"
                              [isEditing]="true"></maporium-title-description>
  <div class="mt-3">
    <cdk-accordion [multi]="true" class="accordion">
      <maporium-accordion-item [sections]="focusedNode?.metadata?.length || 0"
                               [title]="'Properties'">
        <maporium-meta-data-control (extendMeta)="metaExtend.emit()"
                                    formControlName="metadata"></maporium-meta-data-control>
      </maporium-accordion-item>
      <maporium-accordion-item [title]="'Comments'" [sections]="commentsCount">
        <maporium-comments (updated)="this.focusedNode!.hasComments = $event; updateNodeEvent.emit(this.focusedNode)"
                           (commentCount)="commentsCount = $event"
                           [entityId]="focusedNode!.id"
                           [entityType]="'node'">
        </maporium-comments>
      </maporium-accordion-item>
      <maporium-accordion-item [sections]="13" [title]="'Styles'">
        <maporium-node-style-light [focusedNode]="focusedNode" [formGroup]="formGroup"></maporium-node-style-light>

        <div class="justify-content-center d-flex mb-3 mt-3">
          <button (click)="toggleAdvancedStyles()"
                  class="show-more-button"
                  mat-flat-button>
            <span class="show-more-button-title">{{ showMoreStyles ? 'less' : 'more' }}</span>
            <span class="suffix-icon">
            <mat-icon>{{ showMoreStyles ? 'expand_less' : 'expand_more' }}</mat-icon>
          </span>
          </button>
        </div>

        <maporium-node-style-advanced *ngIf="showMoreStyles"
                                      [focusedNode]="focusedNode"
                                      [formGroup]="formGroup">
        </maporium-node-style-advanced>
      </maporium-accordion-item>

      @if (!isDefaultState) {
        <maporium-accordion-item [title]="'States'">
          <app-select-input formControlName="excludedFromState"
                            class="mb-1"
                            [label]="'unknown_2'"
                            [isIcon]="true"
                            [options]="excludeFromStateOptions"
                            placeholder="Decide whether to include or exclude the node in the active state">
          </app-select-input>
          <div class="row mt-2 justify-content-center no-gutter">
            <button class="reset-button w-50"
                    mat-flat-button
                    (click)="resetState()">Revert to Base
            </button>
          </div>
        </maporium-accordion-item>
      }
    </cdk-accordion>
  </div>
</form>
