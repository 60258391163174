import {Component, forwardRef, inject, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule} from '@angular/forms';
import {debounceTime, noop, tap} from 'rxjs';
import {BaseCustomInputComponent} from '../base-custom-input.component';
import {NgClass, NgIf} from '@angular/common';
import {MatSliderModule} from '@angular/material/slider';
import {MatTooltipModule} from '@angular/material/tooltip';


@Component({
  selector: 'app-slider-input',
  standalone: true,
  template: `
    <div class="row align-items-center no-gutter mt-2">
      <div class="col-4 pe-1" *ngIf="label && !isIcon">
        {{label}}
      </div>
      <div class="col-2 justify-content-center align-items-center d-flex" [matTooltip]="placeholder"  *ngIf="label && isIcon">
        <span class="material-symbols-rounded pe-1"><label>{{label}}</label></span>
      </div>
      <div [ngClass]="{'col-8': label !== undefined && label !== '' && !isIcon, 'col-10': label !== '' && isIcon}" class="ps-0 pe-4">
        <mat-slider
          class="w-100"
          [max]="max"
          [disableRipple]="true"
          [discrete]="discrete"
          [min]="min"
          [step]="step"
          [showTickMarks]="showTickMarks">
          <input matSliderThumb
                 [formControl]="formControl"
                 [value]="convertedValue">
        </mat-slider>
      </div>
    </div>

  `,
  styleUrls: ['./slider-input.component.scss'],
  imports: [FormsModule, ReactiveFormsModule, NgClass, NgIf, MatSliderModule, MatTooltipModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SliderInputComponent),
      multi: true,
    },
  ],
})
export class SliderInputComponent extends BaseCustomInputComponent{
  @Input() label = '';
  @Input() placeholder = '';
  @Input() value: number | string = 0;
  @Input() isIcon = false;
  @Input() min = 1;
  @Input() max = 5;
  @Input() step = 1;
  @Input() discrete = true;
  @Input() showTickMarks = true;

  get convertedValue(): number {
    return Number(this.value ? this.value : 0);
  }
}
