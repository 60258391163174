import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {SavedView} from '../interfaces/saved-view';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ViewResource {
  private readonly baseUrl = environment.apiUrl + '/saved-view';

  constructor(private http: HttpClient) {

  }

  getAll(mapId: string): Observable<SavedView[]> {
    return this.http.get<SavedView[]>(this.baseUrl + '/map/' + mapId);
  }

  getAllPublic(mapId: string): Observable<SavedView[]> {
    return this.http.get<SavedView[]>(environment.apiUrl + '/public/map/' + mapId + '/views');
  }

  create(view: SavedView): Observable<SavedView> {
    return this.http.post<SavedView>(this.baseUrl, view);
  }

  update(view: SavedView): Observable<SavedView> {
    return this.http.patch<SavedView>(this.baseUrl, view);
  }

  delete(view: SavedView): Observable<void> {
    return this.http.delete<void>(this.baseUrl + '/' + view.id);
  }
}
