import {ErrorHandler, Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmationDialogComponent} from '../../dialogs/confirmation-dialog.component';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(private matDialog: MatDialog) {

  }

  handleError(error: any): void {
    console.error(error);
    // eslint-disable-next-line no-prototype-builtins
    if (typeof error === 'object' && error !== null && error.hasOwnProperty('error') && error.error.statusCode !== 500) {
      return;
    }

    if (error?.includes('No refresh') || error?.includes('No refresh token')) {
      return;
    }

    const ref = this.matDialog.open(ConfirmationDialogComponent, {
      width: '35%',
      data: {
        title: 'Unexpected Error',
        message: 'An unexpected error occurred and the result of your last action may not have been saved. Please refresh the application to continue.',
        errorMessage: error?.error?.statusCode == 500 ? 'Internal Server Error 500' : error,
        icon: 'bug_report',
        confirmText: 'Refresh',
        showCancel: false,
        onConfirm: () => {
          window.location.reload();
        }
      }
    })

    // Then report to Sentry
    //Sentry.captureException(error.originalError || error);
  }
}
