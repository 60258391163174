import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {GraphMap, GraphNodeMapKey} from '../api/interfaces/graph-node.interface';
import {CreateNewMapDialogComponent} from './create-new-map-dialog.component';
import {ConfirmationDialogComponent} from './confirmation-dialog.component';
import {MapService} from '../api/services/map.service';
import {of} from 'rxjs';
import {MODULE_NAMES} from '@maporium-workspace/shared';
import { PackageService } from '../api/services/package.service';

@Injectable({providedIn: 'root'})
export class MapDialogsService {

  constructor(private matDialog: MatDialog,
              private mapService: MapService,
              private packageService: PackageService) {
  }


  edit(map: GraphMap) {
    if (map.isReadonly) return of(false);
    const ref = this.matDialog.open(CreateNewMapDialogComponent, {data: {map}, width: '35%', autoFocus: 'input'});
    return ref.afterClosed();
  }

  duplicate(map: GraphMap) {
    if (map.isReadonly) return of(false);
    const ref = this.matDialog.open(CreateNewMapDialogComponent, {data: {map, isCopy: true}, width: '35%', autoFocus: 'input'});
    return ref.afterClosed();
  }

  download(map: GraphMap) {
    if (map.isReadonly || !this.packageService.hasModule(MODULE_NAMES.MAP_EXPORT)) return;
    if (map) {
      this.mapService.exportMapWithRelationsRecursive(map).subscribe();
    }
  }

  deleteConfirmation(map: GraphMap) {
    const ref = this.matDialog.open(ConfirmationDialogComponent, {
      width: '35%',
      data: {
        title: 'Delete Smap',
        icon: 'delete',
        message: `Are you sure that you want to delete the '${map?.name}' smap?`,
        confirmText: 'Delete'
      }
    });
    return ref.afterClosed();
  }

  unshareConfirmation(map: GraphMap) {
    const ref = this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Remove Smap',
        width: '35%',
        icon: 'person_remove',
        message: `Are you sure that you want to remove the '${map?.name}' smap that was shared with you?
         You will not be able to access it again unless its owner shares it with you again.`,
        confirmText: 'Remove'
      }
    });
    return ref.afterClosed();
  }
}
