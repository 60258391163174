import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// import * as Sentry from "@sentry/angular-ivy";

import { AppModule } from './app/app.module';
// Sentry.init({
//   dsn: "https://99da920ccbfbd8c0e0d7868d7b1da025@o4505730905866240.ingest.sentry.io/4505730910912512",
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: ["localhost", "https://staging.maporium.com"],
//       routingInstrumentation: Sentry.routingInstrumentation,
//     }),
//     new Sentry.Replay(),
//   ],
//   tracesSampleRate: 1.0,
//   replaysSessionSampleRate: 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
