<div *ngIf="canEdit && !readModeAppState" class="maporium-side-wrapper pb-5">
  <div class="main-content">
    <ng-container *ngIf="focusedNode && !focusedLink">
      <maporium-node-form [focusedNode]="focusedNode"
                          [isDefaultState]="isDefaultState"
                          [excludeFromStateOptions]="excludeFromStateOptions"
                          (resetEvent)="onReset($event)"
                          (metaExtend)="onMetaExtend()"
                          (updateNodeEvent)="onNodeUpdate($event)">
      </maporium-node-form>
    </ng-container>
    <ng-container *ngIf="focusedLink && !focusedNode">
      <maporium-link-form [focusedLink]="focusedLink"
                          [isDefaultState]="isDefaultState"
                          [excludeFromStateOptions]="excludeFromStateOptions"
                          (resetEvent)="onReset($event)"
                          (extendMeta)="onMetaExtend()"
                          (updateLinkEvent)="onLinkUpdate($event)">
      </maporium-link-form>
    </ng-container>
  </div>
</div>

<ng-container *ngIf="!isEditMode || readonly || readModeAppState">
  <div class="maporium-side-wrapper pb-5">
    <div class="main-content">
      <ng-container *ngIf="focusedLink && !focusedNode">
        <maporium-link-view [focusedLink]="focusedLink"
                            (hasComments)="onLinkUpdate(focusedLink, $event);"></maporium-link-view>
      </ng-container>
      <ng-container *ngIf="focusedNode && !focusedLink">
        <maporium-node-view [focusedNode]="focusedNode"
                            (hasComments)="onNodeUpdate(focusedNode, $event)"></maporium-node-view>
        </ng-container>
    </div>
  </div>
</ng-container>
