import { Injectable, signal } from '@angular/core';
import { SettingsResource } from '../resource/settings.resource';
import { BehaviorSubject, Subject, tap } from 'rxjs';
import { LocalStorageSettings, SETTINGS_STORAGE_KEY } from '../../nav/local-storage.settings';
import { GeneralInfo } from '../interfaces/general-info';
import { DefaultValues } from '@maporium-workspace/shared';
import { MapService } from './map.service';
import { isInIframe } from '../../shared/helpers/maporium.validators';
import { LinkTarget } from '../../shared/pipes/link-target/link-target.enum';

export enum LinkViewMode {
  ALL,
  OFF,
  RELATED
}

export const DefaultSidebarWidth = 300;
export const EDGE_VIEW_MODE_KEY = 'edgeViewMode';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  public static sharedValues = {
    node: {
      nodeOverlaySelectOpacity: DefaultValues.node.nodeOverlayOpacity,
      nodeDropOverlayOpacity: DefaultValues.node.nodeDropOverlayOpacity,
      nodeDragOpacity: DefaultValues.node.nodeDragOpacity,
      nodeHighlightColor: DefaultValues.node.nodeHighlightColor,
    }
  }

  /**
   * @deprecated
   */
  public generalInfoChanged = new BehaviorSubject<Partial<GeneralInfo>>({
    autoAdjustLabelSize: false,
    autoRotateEdgeLabels: true
  });

  /**
   * @deprecated
   *
   */
  public localStorageSettingsChanged = new Subject<LocalStorageSettings>;


  /**
   * @deprecated
    */
  public newElementEditingModeSubject = new BehaviorSubject<boolean>(false);

  public linkViewModeSignal = signal<LinkViewMode>(LinkViewMode.ALL);

  constructor(private settingsResource: SettingsResource,
              private mapService: MapService) {
    const localSettings = this.getLocalStorageSettings();
    localSettings.forceOpened = false;
    this.setLocalStorageSettings(localSettings);
    const localLinkViewMode = this.getLocalLinkViewMode();
    this.setLinkViewMode(localLinkViewMode);
  }

  get linkViewMode(): LinkViewMode {
    return this.linkViewModeSignal();
  }

  setLinkViewMode(mode: LinkViewMode) {
    this.setLocalLinkViewMode(mode);
    this.linkViewModeSignal.set(mode);
  }

  setLocalLinkViewMode(mode: LinkViewMode) {
    const localStoredMap = this.mapService.getCurrentSelectedMapFromStore();
    let storedModes = JSON.parse(localStorage.getItem(EDGE_VIEW_MODE_KEY) || '{}');

    if (storedModes && localStoredMap?.id) {
      storedModes[localStoredMap.id] = mode;
    } else if (localStoredMap?.id) {
      storedModes = {};
      storedModes[localStoredMap.id] = mode;
    }
    localStorage.setItem(EDGE_VIEW_MODE_KEY, JSON.stringify(storedModes));
  }

  initLocalLinkViewMode() {
    const localLinkViewMode = this.getLocalLinkViewMode();
    this.setLinkViewMode(localLinkViewMode);
  }

  getLocalLinkViewMode(): LinkViewMode {
    const currentLocalMapId = this.mapService.getCurrentSelectedMapFromStore();
    const edgeViewMode = JSON.parse(localStorage.getItem(EDGE_VIEW_MODE_KEY) === 'undefined' ? '{}' : localStorage.getItem(EDGE_VIEW_MODE_KEY) as string);

    if (currentLocalMapId?.id) {
      return edgeViewMode[currentLocalMapId.id] || LinkViewMode.ALL;
    } else  {
      return LinkViewMode.ALL;
    }
  }

  getGeneralInfo() {
    return this.settingsResource.getGeneralInfo()
      .pipe(
        tap((generalInfo) => {
          if(generalInfo.nodeOverlayOpacity !== undefined) {
            SettingsService.sharedValues.node.nodeOverlaySelectOpacity = generalInfo.nodeOverlayOpacity;
            SettingsService.sharedValues.node.nodeDropOverlayOpacity = generalInfo.nodeDropOverlayOpacity;
            SettingsService.sharedValues.node.nodeDragOpacity = generalInfo.nodeDragOpacity;
            SettingsService.sharedValues.node.nodeHighlightColor = generalInfo.nodeHighlightColor;
          }
          this.generalInfoChanged.next(generalInfo);
        })
      );
  }

  storeSidebarWidth(width: number) {
    const localStorageSettings: LocalStorageSettings = this.getLocalStorageSettings();
    localStorageSettings.sidebarWidth = width;
    this.setLocalStorageSettings(localStorageSettings);
  }

  getSidebarWidth(): number {
    const localStorageSettings: LocalStorageSettings = this.getLocalStorageSettings();
    return localStorageSettings.sidebarWidth || DefaultSidebarWidth;
  }

  saveGeneralInfo(generalInfo: Partial<GeneralInfo>) {
    return this.settingsResource.saveGeneralInfo(generalInfo);
  }

  toggleSidebar(props?: { forceOpen?: boolean, forceClose?: boolean }) {
    const localStorageSettings: LocalStorageSettings = this.getLocalStorageSettings();
    if (props !== undefined) {
      if (props.forceOpen) {
        localStorageSettings.isSidebarOpen = true;
        localStorageSettings.forceOpened = true;
      } else if (props.forceClose) {
        localStorageSettings.isSidebarOpen = false;
        localStorageSettings.forceOpened = false;
      }
    } else {
      localStorageSettings.isSidebarOpen = !localStorageSettings.isSidebarOpen;
      localStorageSettings.forceOpened = false;
    }
    this.setLocalStorageSettings(localStorageSettings);
  }

  setLocalStorageSettings(settings: LocalStorageSettings) {
    const existingSettings: LocalStorageSettings = JSON.parse(
      localStorage.getItem(SETTINGS_STORAGE_KEY) || '{}'
    );
    const updatedSettings: LocalStorageSettings = {
      ...existingSettings,
      ...settings,
    };
    localStorage.setItem(SETTINGS_STORAGE_KEY, JSON.stringify(updatedSettings));
    this.localStorageSettingsChanged.next(updatedSettings);
  }

  getLocalStorageSettings(): LocalStorageSettings {
    const settings = localStorage.getItem(SETTINGS_STORAGE_KEY);
    if (settings) {
      return JSON.parse(settings);
    }
    return {
      isSidebarOpen: true,
      isEditMode: false,
      linkTarget: isInIframe() ? LinkTarget.SingleTab : LinkTarget.SingleWindow
    };
  }
}
