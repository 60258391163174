import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {User} from '../interfaces/user.interface';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class UserResource {
  private readonly baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}
  getOne(id: string): Observable<User> {
    return this.http.get<User>(this.baseUrl + '/user/' + id);
  }
  create(user: User): Observable<void> {
    return this.http.post<void>(this.baseUrl +'/user', user);
  }

  update(user: User): Observable<User> {
    return this.http.put<User>(this.baseUrl + '/user', user);
  }

  login(login: {email: string, password: string}): Observable<{ accessToken: string, user: User }> {
    return this.http.post<{ accessToken: string, user: User }>(this.baseUrl + '/user/login', login);
  }

  signInWithToken(token: string): Observable<{ authToken: string, refreshToken: string, user: User }> {
    return this.http.get<{ authToken: string, refreshToken: string, user: User }>(this.baseUrl + '/user/signin/' + token);
  }

  sendSignInLink(email: string): Observable<void> {
    return this.http.post<void>(this.baseUrl + '/user/send-signin-link', {email});
  }

  getAvailableUsersForMapSharing(mapId: string): Observable<User[]> {
    return this.http.get<User[]>(this.baseUrl + '/user/available-for-sharing/' + mapId);
  }
}
