import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';
import { LinkTarget } from '../pipes/link-target/link-target.enum';
import { SettingsService } from '../../api/services/setting.service';
import { isInIframe } from '../helpers/maporium.validators';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[datify]',
  standalone: true
})
export class DatatifyDirective implements AfterViewInit{

  static onLinkClick(event: Event | null, url: string, linkTarget: LinkTarget) {
    event?.preventDefault();
    const windowName = '_singleResourceWindow';
    const tabName = '_singleResourceTab';
    switch (linkTarget) {
      case LinkTarget.SingleWindow:
        window.open(url, windowName, 'width=800,height=600');
        break;
      case LinkTarget.SingleTab:
        window.open(url, tabName);
        break;
      case LinkTarget.MultiWindow:
        window.open(url, '_blank', 'noopener,noreferrer,width=800,height=600');
        break;
      case LinkTarget.MultiTab:
        window.open(url, '_blank');
        break;
      default:
        console.error('Unknown LinkTarget:', linkTarget);
        window.open(url, '_blank');
        break;
    }
  }

  constructor(private el: ElementRef,
              private settingsService: SettingsService,
              private renderer: Renderer2) {

  }

  ngAfterViewInit(): void {
    const text = this.el.nativeElement.textContent.trim();
    if (this.isValidUrl(text)) {
      const settings = this.settingsService.getLocalStorageSettings();
      // @ts-ignore
      let linkTarget = settings.linkTarget * 1 ?? LinkTarget.SingleWindow;
      if (isInIframe() && isNaN(linkTarget)) {
        linkTarget = LinkTarget.SingleWindow;
      }
      const link = this.renderer.createElement('a') as HTMLAnchorElement;
      link.style.wordBreak = 'break-all';
      link.style.wordWrap = 'break-word';
      this.renderer.setAttribute(link, 'href', '#');
      this.renderer.setAttribute(link, 'rel', 'noopener noreferrer');
      this.renderer.appendChild(link, this.renderer.createText(text));
      this.renderer.listen(link, 'click', (event) => DatatifyDirective.onLinkClick(event, text, linkTarget));
      this.renderer.insertBefore(this.el.nativeElement.parentNode, link, this.el.nativeElement);
      this.renderer.removeChild(this.el.nativeElement.parentNode, this.el.nativeElement);
    }
  }

  private isValidUrl(value: string): boolean {
    const urlRegex = /^(https?:\/\/).+\..+$/;
    return urlRegex.test(value);
  }


}
