export const Layouts = [
  {name: 'Cose Bilkent', value: 'cose-bilkent'},
  {name: 'Concentric', value: 'concentric'},
  {name: 'Position Aware', value: 'preset'},
];
export enum LayoutTypes {
  COSE_BILKENT = 'cose-bilkent',
  CONCENTRIC = 'concentric',
  PRESET = 'preset',
}
