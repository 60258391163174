// @ts-nocheck
const math = require('./math.js');

export class ShapeDrawing {

  sin0 = Math.sin(0);
  cos0 = Math.cos(0);

  sin = {} as any;
  cos = {} as any;

  ellipseStepSize = Math.PI / 40;

  constructor() {
    for (let i = 0 * Math.PI; i < 2 * Math.PI; i += this.ellipseStepSize) {
      this.sin[i] = Math.sin(i);
      this.cos[i] = Math.cos(i);
    }
  }


  drawPolygonPath(context: any, x: any, y: any, width: any, height: any, points: any) {
    const halfW = width / 2;
    const halfH = height / 2;

    if (context.beginPath) {
      context.beginPath();
    }

    context.moveTo(x + halfW * points[0], y + halfH * points[1]);

    for (let i = 1; i < points.length / 2; i++) {
      context.lineTo(x + halfW * points[i * 2], y + halfH * points[i * 2 + 1]);
    }

    context.closePath();
  }

  drawRoundPolygonPath(context: any, x: any, y: any, width: any, height: any, points: any) {

    const halfW = width / 2;
    const halfH = height / 2;
    const cornerRadius = math.getRoundPolygonRadius(width, height);

    if (context.beginPath) {
      context.beginPath();
    }

    for (let i = 0; i < points.length / 4; i++) {
      let sourceUv;

      if (i === 0) {
        sourceUv = points.length - 2;
      } else {
        sourceUv = i * 4 - 2;
      }
      const destUv = i * 4 + 2;

      const px = x + halfW * points[i * 4];
      const py = y + halfH * points[i * 4 + 1];


      const cosTheta = (-points[sourceUv] * points[destUv] - points[sourceUv + 1] * points[destUv + 1]);
      const offset = cornerRadius / Math.tan(Math.acos(cosTheta) / 2);

      const cp0x = px - offset * points[sourceUv];
      const cp0y = py - offset * points[sourceUv + 1];
      const cp1x = px + offset * points[destUv];
      const cp1y = py + offset * points[destUv + 1];

      if (i === 0) {
        context.moveTo(cp0x, cp0y);
      } else {
        context.lineTo(cp0x, cp0y);
      }

      context.arcTo(px, py, cp1x, cp1y, cornerRadius);
    }

    context.closePath();
  }

  drawRoundRectanglePath(context: any, x: any, y: any, width: any, height: any) {

    const halfWidth = width / 2;
    const halfHeight = height / 2;
    const cornerRadius = math.getRoundRectangleRadius(width, height);

    if (context.beginPath) {
      context.beginPath();
    }

    // Start at top middle
    context.moveTo(x, y - halfHeight);
    // Arc from middle top to right side
    context.arcTo(x + halfWidth, y - halfHeight, x + halfWidth, y, cornerRadius);
    // Arc from right side to bottom
    context.arcTo(x + halfWidth, y + halfHeight, x, y + halfHeight, cornerRadius);
    // Arc from bottom to left side
    context.arcTo(x - halfWidth, y + halfHeight, x - halfWidth, y, cornerRadius);
    // Arc from left side to topBorder
    context.arcTo(x - halfWidth, y - halfHeight, x, y - halfHeight, cornerRadius);
    // Join line
    context.lineTo(x, y - halfHeight);
    context.closePath();
  }

  drawBottomRoundRectanglePath(context: any, x: any, y: any, width: any, height: any) {
    const halfWidth = width / 2;
    const halfHeight = height / 2;
    const cornerRadius = math.getRoundRectangleRadius(width, height);

    if (context.beginPath) {
      context.beginPath();
    }

    // Start at top middle
    context.moveTo(x, y - halfHeight);
    context.lineTo(x + halfWidth, y - halfHeight);
    context.lineTo(x + halfWidth, y);

    context.arcTo(x + halfWidth, y + halfHeight, x, y + halfHeight, cornerRadius);
    context.arcTo(x - halfWidth, y + halfHeight, x - halfWidth, y, cornerRadius);

    context.lineTo(x - halfWidth, y - halfHeight);
    context.lineTo(x, y - halfHeight);

    context.closePath();
  }

  drawCutRectanglePath(context: any, x: any, y: any, width: any, height: any) {

    const halfWidth = width / 2;
    const halfHeight = height / 2;
    const cornerLength = math.getCutRectangleCornerLength();

    if (context.beginPath) {
      context.beginPath();
    }

    context.moveTo(x - halfWidth + cornerLength, y - halfHeight);

    context.lineTo(x + halfWidth - cornerLength, y - halfHeight);
    context.lineTo(x + halfWidth, y - halfHeight + cornerLength);
    context.lineTo(x + halfWidth, y + halfHeight - cornerLength);
    context.lineTo(x + halfWidth - cornerLength, y + halfHeight);
    context.lineTo(x - halfWidth + cornerLength, y + halfHeight);
    context.lineTo(x - halfWidth, y + halfHeight - cornerLength);
    context.lineTo(x - halfWidth, y - halfHeight + cornerLength);

    context.closePath();
  }

  drawBarrelPath(context: any, x: any, y: any, width: any, height: any) {

    const halfWidth = width / 2;
    const halfHeight = height / 2;

    const xBegin = x - halfWidth;
    const xEnd = x + halfWidth;
    const yBegin = y - halfHeight;
    const yEnd = y + halfHeight;

    const barrelCurveConstants = math.getBarrelCurveConstants(width, height);
    const wOffset = barrelCurveConstants.widthOffset;
    const hOffset = barrelCurveConstants.heightOffset;
    const ctrlPtXOffset = barrelCurveConstants.ctrlPtOffsetPct * wOffset;

    if (context.beginPath) {
      context.beginPath();
    }

    context.moveTo(xBegin, yBegin + hOffset);

    context.lineTo(xBegin, yEnd - hOffset);
    context.quadraticCurveTo(xBegin + ctrlPtXOffset, yEnd, xBegin + wOffset, yEnd);

    context.lineTo(xEnd - wOffset, yEnd);
    context.quadraticCurveTo(xEnd - ctrlPtXOffset, yEnd, xEnd, yEnd - hOffset);

    context.lineTo(xEnd, yBegin + hOffset);
    context.quadraticCurveTo(xEnd - ctrlPtXOffset, yBegin, xEnd - wOffset, yBegin);

    context.lineTo(xBegin + wOffset, yBegin);
    context.quadraticCurveTo(xBegin + ctrlPtXOffset, yBegin, xBegin, yBegin + hOffset);

    context.closePath();
  }

  drawEllipsePath(context: any, centerX: any, centerY: any, width: any, height: any) {
    if (context.beginPath) {
      context.beginPath();
    }

    if (context.ellipse) {
      context.ellipse(centerX, centerY, width / 2, height / 2, 0, 0, 2 * Math.PI);
    } else {
      let xPos, yPos;
      const rw = width / 2;
      const rh = height / 2;
      for (let i = 0 * Math.PI; i < 2 * Math.PI; i += this.ellipseStepSize) {
        xPos = centerX - (rw * this.sin[i]) * this.sin0 + (rw * this.cos[i]) * this.cos0;
        yPos = centerY + (rh * this.cos[i]) * this.sin0 + (rh * this.sin[i]) * this.cos0;

        if (i === 0) {
          context.moveTo(xPos, yPos);
        } else {
          context.lineTo(xPos, yPos);
        }
      }
    }

    context.closePath();
  }
}


