import {Component, forwardRef, Input} from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule} from '@angular/forms';
import {BaseCustomInputComponent} from '../base-custom-input.component';
import {NgClass, NgIf} from '@angular/common';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';


@Component({
  selector: 'app-slide-toggle-input',
  standalone: true,
  template: `
    <div class="row align-items-center no-gutter mt-2">
      <div class="col-10 pe-1" *ngIf="label && !isIcon">
        {{label}}
      </div>
      <div [ngClass]="{'col-2' : !tooltip, 'col-1' : tooltip}" class="justify-content-start align-items-center d-flex"
           *ngIf="label && isIcon"
           [matTooltip]="placeholder">
        <span class="material-symbols-rounded">{{label}}</span>
      </div>
      <div class="col-9 pe-1" *ngIf="tooltip && isIcon">
        {{tooltip}}
      </div>
      <div class="justify-content-end d-flex col-2">
        <mat-slide-toggle [formControl]="formControl"></mat-slide-toggle>
      </div>
    </div>
  `,
  imports: [FormsModule, ReactiveFormsModule, NgClass, NgIf, MatTooltipModule, MatSlideToggleModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SlideToggleInputComponent),
      multi: true,
    },
  ],
})
export class SlideToggleInputComponent extends BaseCustomInputComponent{
  @Input() label = '';
  @Input() placeholder = '';
  @Input() tooltip = '';
  @Input() value: string | number | undefined = '';
  @Input() isIcon = false;
}
