import { LinkTarget } from '../shared/pipes/link-target/link-target.enum';

export const SETTINGS_STORAGE_KEY = 'app-settings';
export interface LocalStorageSettings {
  isSidebarOpen?: boolean;
  isEditMode?: boolean;
  defaultImageOpacity?: number;
  sidebarWidth?: number;
  forceOpened?: boolean;
  linkTarget?: LinkTarget;
}
