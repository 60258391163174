import {Pipe, PipeTransform} from '@angular/core';
import {orderBy} from 'lodash';

@Pipe({
  name: 'orderBy',
  standalone: true
})
export class OrderByPipe implements PipeTransform {
  transform(array: any[] | undefined, property: string, direction: 'asc' | 'desc' = 'asc'): any[] | undefined {
    if (!array || !Array.isArray(array)) {
      return undefined; // Return undefined if array is undefined or not an array
    }

    // Filter out undefined or null values
    const filteredArray = array.filter(item => item && item[property] !== undefined && item[property] !== null);

    if (filteredArray.length <= 1) {
      return filteredArray;
    }

    return orderBy(filteredArray, [property], [direction]);
  }
}

