import {Component, Input} from '@angular/core';
import {DefaultValues, MODULE_NAMES} from '@maporium-workspace/shared';
import {
  EdgeShapeMapVisual,
  EdgeTypeMapVisual,
  GraphNodeLink,
  LinkDirectionMap,
} from '../../../api/interfaces/graph-node.interface';
import {ColorInputComponent} from '../../../shared/forms/color-input/color-input.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HasModuleDirective} from '../../../packages/has-module.directive';
import {SelectInputComponent} from '../../../shared/forms/select-input/select-input.component';
import {SliderInputComponent} from '../../../shared/forms/slider-input/slider-input.component';

@Component({
  template: `
    <ng-container [formGroup]="formGroup">
      <ng-container *appHasModule="MODULE_NAMES.LINK_COLOR">
        <app-color-input [value]="focusedLink?.color || DefaultValues.link.color"
                         placeholder="Set the link’s color"
                         [icon]="'palette'"
                         formControlName="color"></app-color-input>
      </ng-container>
      <app-select-input formControlName="type"
                        [label]="'moving'"
                        placeholder="Set the link’s shape"
                        [isIcon]="true"
                        *appHasModule="MODULE_NAMES.LINK_SHAPING"
                        [options]="edgeTypesVisual">
      </app-select-input>
      <app-slider-input [label]="'line_weight'"
                        [value]="toNumber(focusedLink!.weight)"
                        [isIcon]="true"
                        *appHasModule="MODULE_NAMES.LINK_STROKE"
                        placeholder="Set the thickness of the link’s curve"
                        formControlName="weight"
                        [max]="4"
                        [min]="0"
                        [step]="1">
      </app-slider-input>
      <app-select-input formControlName="shape"
                        [label]="'line_style'"
                        placeholder="Set the stroke of the link’s curve"
                        [isIcon]="true"
                        *appHasModule="MODULE_NAMES.LINK_STROKE"
                        [options]="edgeShapesVisual">
      </app-select-input>
      <app-select-input formControlName="direction"
                        [label]="'arrow_range'"
                        placeholder="Set the link’s directionality"
                        [isIcon]="true"
                        [options]="linkDirection">
      </app-select-input>
    </ng-container>
  `,
  selector: 'maporium-link-style-light',
  standalone: true,
  imports: [
    ColorInputComponent,
    FormsModule,
    HasModuleDirective,
    ReactiveFormsModule,
    SelectInputComponent,
    SliderInputComponent
  ]
})
export class LinkStyleLightComponent {
  protected readonly MODULE_NAMES = MODULE_NAMES;
  protected readonly DefaultValues = DefaultValues;
  protected readonly edgeTypesVisual = EdgeTypeMapVisual;
  protected readonly edgeShapesVisual = EdgeShapeMapVisual;
  protected readonly linkDirection = LinkDirectionMap;

  @Input() formGroup: any;
  @Input() focusedLink: GraphNodeLink | undefined;
  toNumber(value: any) {
    return Number(value || 0);
  }

}
