import * as cytoscape from 'cytoscape';
import { NodeSingular } from 'cytoscape';
import { GraphNode } from '../../api/interfaces/graph-node.interface';
import { fromEvent, throttleTime } from 'rxjs';
import { debounce } from 'lodash';
import { DefaultValues } from '@maporium-workspace/shared';

export abstract class CyMaporiumServiceCore {
  public static readonly MIN_ZOOM_LEVEL = 0.25;
  public static readonly MAX_ZOOM_LEVEL = 100;
  public static readonly ZOOM_SENSITIVITY = 0.1;
  public static readonly NESTING_ZOOM_START = 0.001;
  public cy: cytoscape.Core | null = null;
  protected readonly NODE_BB_SIZE_THRESHOLD = 23;
  protected draggedDistance = 0;
  protected mousePosition: { x: number; y: number } = { x: 0, y: 0 };
  protected debouncedZoomLevelChange = debounce(() => this.updateVisibilityBasedOnZoomAndNesting(), 200);

  public init(cy: cytoscape.Core, ctx?: any) {
    this.cy = cy;
    this.cy.ready(() => {
      //this.initCanvasLayer(ctx);
      this.addEventListeners();
      this.setupMousePositionTracking();
    });
  }

  public getVisibleViewportPan(isForNode = false) {
    if (!this.cy) return;
    const sidebarElement = document.getElementsByClassName('drawer')[0] as HTMLElement;
    const toolbarElement = document.getElementsByClassName('navbar')[0] as HTMLElement;

    const sidebarWidth = sidebarElement?.offsetWidth || 0;
    const toolbarHeight = toolbarElement?.offsetHeight || 0;

    // Calculate the bounding box of all nodes
    const boundingBox = isForNode ? this.cy.extent() : this.cy.nodes().boundingBox();

    // Compute the center of the bounding box
    const center = {
      x: (boundingBox.x1 + boundingBox.x2) / 2,
      y: (boundingBox.y1 + boundingBox.y2) / 2
    };

    const zoom = this.cy.zoom();

    // Compute the new pan, adjusting for the zoom, sidebar, and toolbar
    const viewportSize = {
      width: this.cy.width(),
      height: this.cy.height()
    };

    const newPan = isForNode ? {
      x: center.x - ((sidebarWidth / 2) / zoom),
      y: center.y + ((toolbarHeight / 2) / zoom)

    } : {
      x: (viewportSize.width / 2 - sidebarWidth / 2) - center.x * zoom,
      y: (viewportSize.height / 2 - toolbarHeight / 2) - center.y * zoom
    };

    return newPan;
  }

  public getMousePosition() {
    return this.mousePosition;
  }

  computeEdgeAngle(edge: any) {
    const sourcePos = edge.source().position();
    const targetPos = edge.target().position();

    const deltaX = targetPos.x - sourcePos.x;
    const deltaY = targetPos.y - sourcePos.y;

    // Compute the angle in radians
    const angle = Math.atan2(deltaY, deltaX);

    // Convert the angle to degrees
    return angle * (180 / Math.PI);
  }

  computeMarginBasedOnAngle(angle: any) {
    const offset = DefaultValues.link.labelOffset; // adjust as needed

    if (Math.abs(angle) <= 45 || Math.abs(angle) >= 135) {
      // Horizontal edge
      return {
        x: 0,
        y: offset
      };
    } else {
      return {
        x: angle > 0 ? offset : -offset,
        y: 0
      };
    }
  }

  protected abstract addEventListeners(): void;

  protected calculateDistanceToBoundingBox(mousePosition: any, bb: any, padding = 0) {
    // Apply padding as an inward contraction of the bounding box
    const adjustedBB = {
      left: bb.x1 + padding,
      right: bb.x2 + padding,
      top: bb.y1 + padding,
      bottom: bb.y2 - padding
    };

    const dx = Math.max(adjustedBB.left - mousePosition.x, 0, mousePosition.x - adjustedBB.right);
    const dy = Math.max(adjustedBB.top - mousePosition.y, 0, mousePosition.y - adjustedBB.bottom);

    return Math.sqrt(dx * dx + dy * dy);
  }

  protected getDistance(node1: cytoscape.NodeSingular, node2: cytoscape.NodeSingular, log?: boolean): number {
    // Check if either node is a child of the other
    const isNode1AncestorOfNode2 = node1?.descendants().some((descendant: any) => descendant?.id() === node2?.id());
    const isNode2AncestorOfNode1 = node2?.descendants().some((descendant: any) => descendant?.id() === node1?.id());

    if (isNode1AncestorOfNode2 || isNode2AncestorOfNode1 || node1 === null || node2 === null) {
      // One node is inside the other; return 0 as the distance
      return 0;
    }

    const bb1 = node1.boundingBox({ includeLabels: false });
    const bb2 = node2.boundingBox({ includeLabels: false });

    // Calculate the shortest distance between the edges of the two bounding boxes
    const dx = Math.max((bb1.x1 - bb2.x2) + this.NODE_BB_SIZE_THRESHOLD, 0, (bb2.x1 - bb1.x2) + this.NODE_BB_SIZE_THRESHOLD);
    const dy = Math.max((bb1.y1 - bb2.y2) + this.NODE_BB_SIZE_THRESHOLD, 0, (bb2.y1 - bb1.y2) + this.NODE_BB_SIZE_THRESHOLD);
    if (log) {
      console.log('Calculating distance', Math.sqrt(dx * dx + dy * dy), 'between', node1.data('name'), 'and', node2.data('name'));
    }
    return Math.sqrt(dx * dx + dy * dy);
  }

  protected findClosestNode(mousePosition: any, nodes: any, threshold: number) {
    let minDistance = threshold;
    let closestNode: NodeSingular | null = null;
    const compoundNodePadding = 0; // Padding for compound nodes

    nodes.forEach((node: any) => {
      const bb = node.boundingBox();
      const distance = this.calculateDistanceToBoundingBox(mousePosition, bb, node.isParent() ? compoundNodePadding : 5);

      // This part prioritizes nodes based on their depth in the hierarchy (deeper nodes first)
      if (distance < minDistance || (distance === minDistance && node.isChild() && (!closestNode || node.ancestors().length > closestNode.ancestors().length))) {
        minDistance = distance;
        closestNode = node;
      }
    });

    return closestNode;
  }

  protected markChildIfDraggedByParent(node: cytoscape.NodeSingular): void {
    if (!this.cy) return;

    if (node.isParent()) {
      const children = node.descendants();
      // Mark each child as being dragged by its parent
      children.forEach((child) => {
        child.data('draggedByParent', true);
      });
    } else {
      node.data('draggedByParent', false);
    }
  }

  protected unmarkChildIfDraggedByParent(node: cytoscape.NodeSingular): void {
    if (!this.cy) return;
    if (node.isParent()) {
      const children = node.children();

      // Mark each child as being dragged by its parent
      children.forEach((child) => {
        child.data('draggedByParent', false);
      });
    }
  }

  protected addGrabbedPositionToAllDescendants(root: NodeSingular) {

    // Is the root node a Cytoscape node or a plain object?
    if (root?.isNode && root?.isNode()) {
      root.data('grabbedPosition', { ...root.position() });
      const children = root.children().length > 0 ? root.children() : root.data('children');

      if (children && children.length) {
        children.forEach((child: any) => {
          this.addGrabbedPositionToAllDescendants(child);
        });
      }
    } else {
      // The root is a plain object
      // @ts-ignore
      const plainNode = root as GraphNode;
      // @ts-ignore
      plainNode['grabbedPosition'] = { x: root.x, y: root.y };

      if (plainNode.children && plainNode.children.length) {
        plainNode.children.forEach((child: any) => {
          this.addGrabbedPositionToAllDescendants(child);
        });
      }
    }
  }

  protected updateVisibilityBasedOnZoomAndNesting() {
    if (!this.cy) return;
    const cy = this.cy;
    const currentZoom = this.cy.zoom();
    const zoomRange = CyMaporiumServiceCore.MAX_ZOOM_LEVEL - CyMaporiumServiceCore.MIN_ZOOM_LEVEL;
    const zoomRatio = (currentZoom - CyMaporiumServiceCore.MIN_ZOOM_LEVEL) / zoomRange;
    const adjustedZoomRatio = (zoomRatio * (CyMaporiumServiceCore.MAX_ZOOM_LEVEL - CyMaporiumServiceCore.NESTING_ZOOM_START)) + CyMaporiumServiceCore.NESTING_ZOOM_START;

    cy.nodes().forEach((node) => {
      const nestingLevel = node.ancestors().length;
      const isVisible = nestingLevel <= Math.round(adjustedZoomRatio * nestingLevel);

      node.animate({
        style: {
          opacity: isVisible ? '1' : '0'
        },
        complete: function() {
          // Update connected edges
          node.connectedEdges().forEach((edge) => {
            const source = edge.source();
            const target = edge.target();
            let sourceVisible = false;
            let targetVisible = false;

            if (typeof source?.style === 'function') {
              sourceVisible = source.style('opacity') !== '0';
            } else {
              //@ts-ignore required hack , something overrides the core style function
              sourceVisible = source._private.style.opacity.value !== 0;
            }


            if (typeof target?.style === 'function') {
              targetVisible = target.style('opacity') !== 0;
            } else {
              //@ts-ignore required hack , something overrides the core style function
              targetVisible = target._private.style.opacity.value !== 0;
            }


            if (sourceVisible && targetVisible) {
              edge.style('opacity', '1');
              edge.style('opacity', null);
            } else {
              edge.style('opacity', '0');
            }
          });
          if (typeof node?.style === 'function') {
            node?.style('opacity', isVisible ? null : '0');
          }
        }
      });
    });
  }

  protected setupMousePositionTracking() {
    if (!this.cy) return;
    const mouseMove$ = fromEvent<MouseEvent>(document, 'mousemove').pipe(
      throttleTime(50) // Adjust the throttle time as needed
    );

    mouseMove$.subscribe(event => {
      if (!this.cy) return;
      // Get the bounding rectangle of the Cytoscape container
      const boundingRect = this.cy.container()?.getBoundingClientRect();
      if (!boundingRect) return;
      // Convert the mouse position to Cytoscape coordinates
      this.mousePosition = {
        x: (event.clientX - boundingRect.left - this.cy.pan().x) / this.cy.zoom(),
        y: (event.clientY - boundingRect.top - this.cy.pan().y) / this.cy.zoom()
      };
    });
  }


}
