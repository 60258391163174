import {Component, OnDestroy} from '@angular/core';
import {MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {CommonModule} from '@angular/common';
import {GptService} from '../api/services/gpt.service';
import {catchError, switchMap, throwError, timer} from 'rxjs';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import {TextInputComponent} from '../shared/forms/text-input/text-input.component';
import {SlideToggleInputComponent} from '../shared/forms/slide-select/slide-toggle-input.component';
import {MatTooltipModule} from '@angular/material/tooltip';

@Component(
  {
    selector: 'maporium-gpt-map-generator-dialog',
    template: `
      <div class="w-100 d-flex justify-content-between align-items-center dialog-header">
        <h2 class="align-items-center d-flex m-0 p-0"><span class="material-symbols-rounded me-2">cognition</span>
          Generate Smap
        </h2>
        <mat-icon class="material-symbols-rounded close-button"  mat-dialog-close matTooltip="Close the panel">
          close
        </mat-icon>
      </div>

      <div mat-dialog-content>
        <form [formGroup]="form">
          <app-text-input [label]="'Prompt*'" formControlName="prompt"></app-text-input>
          <mat-hint>Example: All Star Wars characters</mat-hint>
          <app-slide-toggle-input [label]="'Generate colors'"
                                  [disabled]="isGenerating"
                                  formControlName="generateColor">
          </app-slide-toggle-input>
        </form>
        <br>
        <p>Please NOTE: The map generation can take up to 3 Minutes, please be patient and dont close the window.</p>
        <p>In cases the map is complex the generation can be timeout, please narrow down your prompt or reduce
          complexity</p>
        <small class="text-danger" *ngIf="hasError">{{errorMessage}}</small>
      </div>
      <div mat-dialog-actions class="d-flex justify-content-center" *ngIf="form.valid" >
        <button mat-button color="accent" *ngIf="!isGenerating && form.valid" (click)="generate()">Generate</button>
        <mat-progress-bar mode="indeterminate" class="mt-3" *ngIf="isGenerating"></mat-progress-bar>
      </div>
    `,
    styles: [``],
    imports: [
      MatDialogModule,
      CommonModule,
      MatInputModule,
      MatButtonModule,
      MatProgressBarModule,
      ReactiveFormsModule,
      MatCheckboxModule,
      MatIconModule,
      MatTooltipModule,

      TextInputComponent,
      SlideToggleInputComponent
    ],
    standalone: true
  }
)
export class GptMapGeneratorDialogComponent implements OnDestroy{
  isGenerating = false;
  form = new FormGroup({
    prompt: new FormControl('', {validators:[Validators.required]}),
    generateColor: new FormControl(true)
  });

  hasError = false;
  public errorMessage = '';
  constructor(private matDialogRef: MatDialogRef<GptMapGeneratorDialogComponent>, private gptService: GptService) {
  }

  longPollingSubscription: any;

  get getPromptControl() {
    return this.form.get('prompt') as FormControl;
  }
  generate() {
    this.isGenerating = true;
    // @ts-ignore
    const prompt = this.getPromptControl.value as string;
    // @ts-ignore
    const generateColor = this.form.get('generateColor').value as boolean;
    this.gptService.generateMap(prompt, {generateColor})
      .pipe(
        catchError((error) => {
            this.isGenerating = false;
            return throwError(error);
          }
        ))
      .subscribe((response) => {
        this.longPollingSubscription =
          timer(0, 5000)
            .pipe(
              switchMap(() => this.gptService.pullPrompt(response.id)),
              catchError((error) => {
                  this.isGenerating = false;
                  return throwError(error);
                }
              )
            )
            .subscribe((response) => {
              if (response.finished && !response.hasError) {
                this.isGenerating = false;
                this.longPollingSubscription.unsubscribe();
                // @ts-ignore
                this.matDialogRef.close(JSON.parse(response.prompt));
              } else if(response.finished && response.hasError) {
                this.isGenerating = false;
                this.longPollingSubscription.unsubscribe();
                this.hasError = true;
                // @ts-ignore
                this.errorMessage = response.prompt['message'];
              }
            });

      });
  }

  ngOnDestroy(): void {
    if (this.longPollingSubscription) {
      this.longPollingSubscription.unsubscribe();
    }
  }
}
