import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {MatStepperModule} from '@angular/material/stepper';
import {ToursService} from '../../api/services/tour.service';
import {MapTour} from '../../api/interfaces/map-tour';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {ViewService} from '../../api/services/view.service';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatProgressBarModule} from '@angular/material/progress-bar';

@Component({
  selector: 'app-tours-panel',
  template: `
    <div class="app-tours-panel mat-elevation-z3" *ngIf="tour">
      <div class="panel-header d-flex align-items-end justify-content-between">
        <mat-icon class="material-symbols-rounded close-button"
                  matTooltip="Restart the story"
                  (click)="replay()">replay
        </mat-icon>
        <h4 class="text-center mb-0 pt-1" *ngIf="currentStep === 0 && showTourDescription">{{tour.name}}</h4>
        <h4 class="text-center mb-0 pt-1" *ngIf="currentStep > 0">{{tour.steps[currentStep - 1].savedView.name}}</h4>
        <mat-icon class="material-symbols-rounded close-button"
                  (click)="close()"
                  matTooltip="Close the panel">close
        </mat-icon>
      </div>
      <div class="step-navigation ps-1 pe-1">
        <ng-container *ngIf="currentStep === 0 && showTourDescription; else tourSteps">
          <div class="step-indicator d-flex align-items-center" *ngIf="animateStepChange"
               (animationend)="onAnimationEnd()">
            <span>{{tour.description ? tour.description : ''}}</span>
          </div>
          <div class="step-indicator" *ngIf="!animateStepChange">
            <span>{{tour.description ? tour.description : ''}}</span>
          </div>
        </ng-container>
        <ng-template #tourSteps>
          <div class="step-indicator d-flex align-items-center" *ngIf="animateStepChange"
               (animationend)="onAnimationEnd()">
            <span>{{tour.steps[currentStep - 1].savedView.description ? tour.steps[currentStep - 1].savedView.description : ''}}</span>
          </div>
          <div class="step-indicator" *ngIf="!animateStepChange">
            <span>{{tour.steps[currentStep - 1].savedView.description ? tour.steps[currentStep - 1].savedView.description : ''}}</span>
          </div>
        </ng-template>
      </div>
      <div class="row no-gutter">
        <div class="col-3 align-items-center justify-content-end d-flex">
          <span *ngIf="currentStep > 0"> {{currentStep}} of {{totalSteps}}</span>
        </div>
        <div class="col-6 align-items-center justify-content-center d-flex">
          <button mat-icon-button
                  matTooltip="Go to the story’s previous step"
                  (click)="prevStep()"
                  [disabled]="currentStep === 0">
            <span class="material-symbols-rounded">skip_previous</span>
          </button>
          <button mat-icon-button
                  matTooltip="Play the story"
                  (click)="togglePlay()"
                  [disabled]="currentStep - 1 === totalSteps - 1"
                  *ngIf="!isTourPlaying">
            <span class="material-symbols-rounded">play_circle</span>
          </button>
          <button mat-icon-button
                  matTooltip="Pause the story"
                  (click)="togglePlay()"
                  *ngIf="isTourPlaying">
            <span class="material-symbols-rounded">pause_circle</span>
          </button>
          <button mat-icon-button
                  matTooltip="Go to the story’s next step"
                  (click)="nextStep()"
                  [disabled]="currentStep - 1 === totalSteps - 1">
            <span class="material-symbols-rounded">skip_next</span>
          </button>
        </div>
        <div class="col-3 align-items-center justify-content-start d-flex">
          <mat-progress-bar *ngIf="isTourPlaying" style="width: 50%" [value]="progressSteps"></mat-progress-bar>
        </div>
      </div>

    </div>
  `,
  styleUrls: ['tours-panel.component.scss'],
  imports: [
    MatStepperModule,
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatProgressBarModule
  ],
  standalone: true
})

export class ToursPanelComponent implements OnInit, OnDestroy {
  public tour: MapTour | undefined;
  animateStepChange = false; // Flag to trigger animation
  public isTourPlaying = false;
  currentStep = 0;
  totalSteps = 0;
  showTourDescription = true;
  elapsedTime = 0;
  // How often we update the proggres bar
  intervalDuration = 100;
  progressSteps = 0;
  progressInterval: any;
  playTourTimer: any;
  animationInProgress = false;

  constructor(private tourService: ToursService,
              private savedViewService: ViewService,
              private cdr: ChangeDetectorRef) {
  }


  ngOnInit() {
    this.tourService.activateTourSubject
      .subscribe((tour) => {
        if (!tour) {
          this.tour = undefined;
          return;
        }
        this.tour = tour;
        this.totalSteps = tour.steps.length;
      });
  }

  ngOnDestroy(): void {
    // Clear the interval when the component is destroyed
    this.stopProgress();
  }

  // Call this method to start the progress bar animation
  startProgress(duration: number): void {
    this.stopProgress(); // Clear any existing intervals
    this.elapsedTime = 0; // Reset elapsed time

    this.progressInterval = setInterval(() => {
      this.elapsedTime += this.intervalDuration; // Increment the elapsed time
      // Update the progress bar with the new value
      this.progressSteps = (this.elapsedTime / duration) * 100;

      // If the total time has passed, clear the interval
      if (this.elapsedTime >= duration) {
        this.stopProgress();
        this.progressSteps = 100; // Ensure the progress bar shows as complete
      }
    }, this.intervalDuration);
  }

  stopProgress(): void {
    if (this.progressInterval) {
      this.progressSteps = 0;
      this.elapsedTime = 0;
      clearInterval(this.progressInterval);
    }
  }

  replay() {
    if (!this.tour) return;
    this.currentStep = 0;
    this.showTourDescription = true;
    this.isTourPlaying = false;
  }

  togglePlay() {
    if (!this.tour) return;
    this.isTourPlaying = !this.isTourPlaying;
    if (this.isTourPlaying) {
      this.playNextStepWithDelay();
    }
  }

  playNextStepWithDelay(viewStepAdjustment = 0) {
    if (this.playTourTimer) {
      clearTimeout(this.playTourTimer);
    }
    if (this.currentStep - 1 < this.totalSteps - 1 && this.isTourPlaying) {
      // Get the current step
      const currentStepObj = this.getCurrentStep(viewStepAdjustment);
      if (!currentStepObj) return;
      // Calculate the total duration for this step, including animation speed and wait time
      const totalDuration = currentStepObj.animationSpeed + currentStepObj.pauseDuration;
      // Play the next step
      this.nextStep(true);

      // Wait for the specified duration before playing the next step
      if (this.isTourPlaying) {
        this.playTourTimer = setTimeout(() => {
          this.playNextStepWithDelay(1); // Play the next step with delay
        }, totalDuration);
      }

      // if this is the last step set the flag to false
      if (this.currentStep - 1 === this.totalSteps - 1) {
        this.isTourPlaying = false;
        clearTimeout(this.playTourTimer);
      }
    } else {
      // You've reached the last step, you can perform any final actions here
      this.isTourPlaying = false;
      clearTimeout(this.playTourTimer);
    }
  }


  onAnimationEnd() {
    this.animateStepChange = false;
    this.cdr.detectChanges();
  }

  prevStep() {
    this.animationInProgress = true;
    if (this.currentStep > 1) {
      this.currentStep--;
      this.animateStepChange = true;
      this.cdr.detectChanges();
      if (!this.tour) return;
      this.playProgressIndicator();
      this.savedViewService.playView(
        this.tour.steps[this.currentStep - 1].savedView,
        this.tour.steps[this.currentStep - 1],
        () => {
          this.animationInProgress = false;
        }
      );
    } else if (this.currentStep === 1) {
      this.stopProgress();
      this.currentStep--;
      this.showTourDescription = true;
    }
  }

  nextStep(playViewStep = false) {
    this.animationInProgress = true;
    if (this.currentStep === 0 && !playViewStep) {
      this.showTourDescription = false;
      this.currentStep++;
      if (!this.tour) return;
      this.playProgressIndicator();

      this.savedViewService.playView(
        this.tour.steps[this.currentStep - 1].savedView,
        this.tour.steps[this.currentStep - 1],
        () => {
          this.animationInProgress = false;
        }
      );
    } else {
      this.playProgressIndicator();

      if (this.currentStep - 1 < this.totalSteps - 1) {
        this.currentStep++;
        this.animateStepChange = true;
        this.cdr.detectChanges();
        if (!this.tour) return;
        this.savedViewService.playView(
          this.tour.steps[this.currentStep - 1].savedView,
          this.tour.steps[this.currentStep - 1],
          () => {
            this.animationInProgress = false;
          }
        );
      }
    }
  }

  getCurrentStep(viewStepAdjustment = 1) {
    return this.tour?.steps[this.currentStep - viewStepAdjustment];
  }

  playProgressIndicator() {
    // @ts-ignore
    const currentStepObj = this.tour.steps[this.currentStep];
    if (currentStepObj) {
      this.stopProgress();
      this.startProgress(currentStepObj.animationSpeed + currentStepObj.pauseDuration);
    }
  }

  close() {
    this.tour = undefined;
    this.currentStep = 0;
    this.showTourDescription = true;
    this.tourService.activateTourSubject.next(null);
  }
}
