import {Component, Input} from '@angular/core';
import {DefaultValues, MODULE_NAMES} from '@maporium-workspace/shared';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {SliderInputComponent} from '../../../shared/forms/slider-input/slider-input.component';
import {TextInputComponent} from '../../../shared/forms/text-input/text-input.component';
import {SelectInputComponent} from '../../../shared/forms/select-input/select-input.component';
import {MatListModule} from '@angular/material/list';
import {ColorInputComponent} from '../../../shared/forms/color-input/color-input.component';
import { GraphNode } from '../../../api/interfaces/graph-node.interface';
import {TextHalignVisual, TextValignVisual} from '../../../api/interfaces/text-align.map';
import {HasModuleDirective} from '../../../packages/has-module.directive';

@Component({
  template: `
    <ng-container [formGroup]="formGroup">
      <fieldset formGroupName="properties" *ngIf="focusedNode">
        <!--      TODO: Temporaly set to tag module to hide for other plans-->
        <app-select-input formControlName="label"
                          class="mb-1"
                          [label]="'label'"
                          *appHasModule="MODULE_NAMES.TAG_BINDING"
                          [noIcons]="true"
                          [truncate]="true"
                          [isIcon]="true"
                          [metadata]="focusedNode.metadata"
                          placeholder="Set the node’s label based on a property">
        </app-select-input>
        <app-slider-input [label]="'colors'"
                          [value]="toNumber(focusedNodeProperties.opacity !== undefined && focusedNodeProperties.opacity !== null ? focusedNodeProperties.opacity : DefaultValues.node.properties.opacity)"
                          [isIcon]="true"
                          placeholder="Set the node’s shape filling"
                          formControlName="opacity"
                          [discrete]="true"
                          [max]="1"
                          [min]="0"
                          [step]="0.1">

        </app-slider-input>

        <ng-container *appHasModule="MODULE_NAMES.NODE_IMAGE">
          <app-slider-input [label]="'opacity'"
                            [value]="focusedNodeProperties.imageOpacity !== undefined ? focusedNodeProperties.imageOpacity : DefaultValues.node.properties.imageOpacity"
                            [isIcon]="true"
                            [discrete]="true"
                            placeholder="Set the opacity of the node’s image"
                            formControlName="imageOpacity"
                            [step]="0.1"
                            [max]="1"
                            [min]="0"></app-slider-input>
        </ng-container>

        <app-slider-input [label]="'format_size'"
                          [value]="toNumber(focusedNodeProperties.textFontSize !== undefined ? focusedNodeProperties.textFontSize : DefaultValues.node.properties.textFontSize)"
                          [isIcon]="true"
                          placeholder="Set the font size of the node’s label"
                          formControlName="textFontSize"
                          [discrete]="true"
                          [max]="16"
                          *appHasModule="MODULE_NAMES.NODE_SHAPE_FILLING"
                          [min]="0"
                          [step]="1">

        </app-slider-input>
        <ng-container *appHasModule="MODULE_NAMES.NODE_LABEL_ADJUSTMENT">

          <app-select-input formControlName="textHalign"
                            [label]="'border_vertical'"
                            placeholder="Set the horizontal position of the node’s label"
                            [isIcon]="true"
                            [options]="textHalignVisual">
          </app-select-input>

          <app-select-input formControlName="textValign"
                            [label]="'border_horizontal'"
                            placeholder="Set the vertical position of the node’s label"
                            [isIcon]="true"
                            [options]="textValignVisual">
          </app-select-input>

          <app-slider-input [label]="'text_rotation_none'"
                            [value]="toNumber(focusedNodeProperties.textMarginX !== undefined ? focusedNodeProperties.textMarginX : DefaultValues.node.properties.textMarginX)"
                            [isIcon]="true"
                            placeholder="Set the horizontal offset of the node’s label"
                            formControlName="textMarginX"
                            [discrete]="true"
                            [showTickMarks]="false"
                            [max]="32"
                            [min]="-32"
                            [step]="1">
          </app-slider-input>
          <app-slider-input [label]="'text_rotate_vertical'"
                            [value]="toNumber(focusedNodeProperties.textMarginY !== undefined ? focusedNodeProperties.textMarginY : DefaultValues.node.properties.textMarginY)"
                            [isIcon]="true"
                            placeholder="Set the vertical offset of the node’s label"
                            formControlName="textMarginY"
                            [discrete]="true"
                            [showTickMarks]="false"
                            [max]="16"
                            [min]="-16"
                            [step]="1">
          </app-slider-input>
        </ng-container>
      </fieldset>
    </ng-container>
  `,
  selector: 'maporium-node-style-advanced',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SliderInputComponent,
    TextInputComponent,
    SelectInputComponent,
    MatListModule,
    ColorInputComponent,
    HasModuleDirective,
  ],
  standalone: true,
})
export class NodeStyleAdvancedComponent {
  public readonly MODULE_NAMES = MODULE_NAMES;
  public readonly DefaultValues = DefaultValues;
  public readonly textHalignVisual = TextHalignVisual;
  public readonly textValignVisual = TextValignVisual;

  @Input() formGroup: any;
  @Input() metadata: any;
  @Input() focusedNode: GraphNode | undefined;

  get focusedNodeProperties() {
    return this.focusedNode?.properties || {
      label: '',
      textValign: DefaultValues.node.properties.textValign.toString(),
      textHalign: DefaultValues.node.properties.textHalign.toString(),
      textMarginX: DefaultValues.node.properties.textMarginX.toString(),
      textRotation: DefaultValues.node.properties.textRotation.toString(),
      imageOpacity: DefaultValues.node.properties.imageOpacity,
      opacity: DefaultValues.node.properties.opacity,
      textMarginY: DefaultValues.node.properties.textMarginY.toString(),
      textFontSize: DefaultValues.node.properties.textFontSize.toString(),
      textFontColor: DefaultValues.node.properties.textFontColor.toString(),
      scale: DefaultValues.node.properties.scale.toString(),
    };
  }

  toNumber(value: any) {
    return Number(value || 0);
  }

}
