export const MODULE_NAMES = {
  MEDIA: 'Media',
  MEDIA_PLUS: 'Media+',
  MEDIA_PLUS_PLUS: 'Media++',
  TECH_SUPPORT: 'Tech Support',
  TECH_SUPPORT_PLUS: 'Tech Support+',
  TECH_SUPPORT_PLUS_PLUS: 'Tech Support++',
  MAP_IMPORT: 'Map Import',
  MAP_FILTERING: 'Filtering',
  MAP_EXPORT: 'Map Export',
  MAP_SHARE: 'Map Share',
  MAP_SHARE_PLUS: 'Map Share+',
  MAP_SHARE_PLUS_PLUS: 'Map Share++',
  MAP_ADDITIONAL_DATA: 'Map Additional Data',
  MAP_AUTHOR_DATA: 'Map Author Data',
  NODE_SCALING: 'Node Scaling',
  NODE_SHAPE: 'Node Shape',
  NODE_COLOR: 'Node Color',
  NODE_STROKE: 'Node Stroke',
  NODE_IMAGE: 'Node Image',
  NODE_SHAPE_FILLING: 'Node Shape Filling',
  NODE_LABEL_ADJUSTMENT: 'Node Label Adjustment',
  METADATA_BINDING: 'Metadata Binding',
  LINK_COLOR: 'Link Color',
  LINK_STROKE: 'Link Stroke',
  LINK_SHAPING: 'Link Shaping',
  LINK_LABEL_ADJUSTMENT: 'Link Label Adjustment',
  TAG_BINDING: 'Tag Binding',
  TAG_MANAGEMENT: 'Tag Management',
  VIEW_USAGE: 'View Usage',
  VIEW_MANAGEMENT: 'View Management',
  TOUR_USAGE: 'Tour Usage',
  TOUR_MANAGEMENT: 'Tour Management',
  GPT_ENABLED: 'GPT Enabled',
}

export const PACKAGE_NAMES = {
  explorer: 'Explorer',
  creator: 'Creator',
  publisher: 'Publisher',
  developer: 'Developer',
}

/**
 * @deprecated Has to be updated to reflect the new service grid
 */
export const DefaultPackageModuleGrid = {
  [PACKAGE_NAMES.explorer]: [
    MODULE_NAMES.MAP_IMPORT,
    MODULE_NAMES.MAP_FILTERING,
    MODULE_NAMES.NODE_COLOR,
    MODULE_NAMES.LINK_COLOR,
    MODULE_NAMES.VIEW_USAGE,
  ],
  [PACKAGE_NAMES.creator]: [
    MODULE_NAMES.MEDIA,
    MODULE_NAMES.TECH_SUPPORT,
    MODULE_NAMES.MAP_IMPORT,
    MODULE_NAMES.MAP_SHARE,
    MODULE_NAMES.MAP_FILTERING,
    MODULE_NAMES.NODE_COLOR,
    MODULE_NAMES.NODE_IMAGE,
    MODULE_NAMES.NODE_SHAPE,
    MODULE_NAMES.NODE_SCALING,
    MODULE_NAMES.LINK_COLOR,
    MODULE_NAMES.LINK_STROKE,
    MODULE_NAMES.METADATA_BINDING,
    MODULE_NAMES.TAG_BINDING,
  ],
  [PACKAGE_NAMES.publisher]: [
    MODULE_NAMES.MEDIA_PLUS,
    MODULE_NAMES.TECH_SUPPORT_PLUS,
    MODULE_NAMES.MAP_EXPORT,
    MODULE_NAMES.MAP_IMPORT,
    MODULE_NAMES.MAP_SHARE_PLUS,
    MODULE_NAMES.MAP_FILTERING,
    MODULE_NAMES.NODE_COLOR,
    MODULE_NAMES.NODE_IMAGE,
    MODULE_NAMES.NODE_SHAPE,
    MODULE_NAMES.NODE_SCALING,
    MODULE_NAMES.LINK_COLOR,
    MODULE_NAMES.LINK_STROKE,
    MODULE_NAMES.METADATA_BINDING,
    MODULE_NAMES.TAG_BINDING,
    MODULE_NAMES.TAG_MANAGEMENT,
  ],
  [PACKAGE_NAMES.developer]: [
    MODULE_NAMES.MEDIA,
    MODULE_NAMES.MEDIA_PLUS,
    MODULE_NAMES.MEDIA_PLUS_PLUS,
    MODULE_NAMES.TECH_SUPPORT,
    MODULE_NAMES.TECH_SUPPORT_PLUS,
    MODULE_NAMES.TECH_SUPPORT_PLUS_PLUS,
    MODULE_NAMES.MAP_IMPORT,
    MODULE_NAMES.MAP_FILTERING,
    MODULE_NAMES.MAP_EXPORT,
    MODULE_NAMES.MAP_SHARE,
    MODULE_NAMES.MAP_SHARE_PLUS,
    MODULE_NAMES.MAP_SHARE_PLUS_PLUS,
    MODULE_NAMES.NODE_SCALING,
    MODULE_NAMES.NODE_SHAPE,
    MODULE_NAMES.NODE_COLOR,
    MODULE_NAMES.NODE_STROKE,
    MODULE_NAMES.NODE_IMAGE,
    MODULE_NAMES.NODE_LABEL_ADJUSTMENT,
    MODULE_NAMES.METADATA_BINDING,
    MODULE_NAMES.LINK_COLOR,
    MODULE_NAMES.LINK_STROKE,
    MODULE_NAMES.LINK_SHAPING,
    MODULE_NAMES.LINK_LABEL_ADJUSTMENT,
    MODULE_NAMES.TAG_BINDING,
    MODULE_NAMES.TAG_MANAGEMENT,
    MODULE_NAMES.VIEW_USAGE,
    MODULE_NAMES.VIEW_MANAGEMENT,
    MODULE_NAMES.TOUR_USAGE,
    MODULE_NAMES.TOUR_MANAGEMENT,
    MODULE_NAMES.GPT_ENABLED,
  ],
}
