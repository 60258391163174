import { Injectable, signal } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AppWideStateService {
  private readModeSignal = signal(false);
  private canvasLoadedSignal = signal(false);

  get readMode() {
    return this.readModeSignal.asReadonly();
  }

  setReadMode(value: boolean) {
    this.readModeSignal.set(value);
  }

  get canvasLoaded() {
    return this.canvasLoadedSignal.asReadonly();
  }

  setCanvasLoaded(value: boolean) {
    this.canvasLoadedSignal.set(value);
  }
}
