import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IdleService {
  private idleTimer: any;
  private idleMinutes = 30 * 60;
  private readonly idleThreshold = 1000 * this.idleMinutes; // Idle time


  resetTimer(): void {
    this.clearTimer();
    this.idleTimer = setTimeout(() => {
      window.location.reload(); // Refresh the app
    }, this.idleThreshold);
  }

  private clearTimer(): void {
    if (this.idleTimer) {
      clearTimeout(this.idleTimer);
    }
  }
}
