export const TextValignMap = [
  'top',
  'center',
  'bottom'
]

export const TextHalignMap = [
  'left',
  'center',
  'right'
]

export const TextValignVisual = [
  {key: 'top', value: 'top', icon: 'align_vertical_top'},
  {key: 'center', value: 'center', icon: 'align_vertical_center'},
  {key: 'bottom', value: 'bottom', icon: 'align_vertical_bottom'},
]

export const TextHalignVisual = [
  {key: 'left', value: 'left', icon: 'align_horizontal_left'},
  {key: 'center', value: 'center', icon: 'align_horizontal_center'},
  {key: 'right', value: 'right', icon: 'align_horizontal_right'},
];
