import {Component, Input} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {SelectInputComponent} from '../../../shared/forms/select-input/select-input.component';
import {DefaultValues, MODULE_NAMES} from '@maporium-workspace/shared';
import {HasModuleDirective} from '../../../packages/has-module.directive';
import {SliderInputComponent} from '../../../shared/forms/slider-input/slider-input.component';
import {GraphNodeLink} from '../../../api/interfaces/graph-node.interface';

@Component({
  template: `
    <ng-container [formGroup]="formGroup">
      <app-select-input formControlName="label"
                        [label]="'label'"
                        [isIcon]="true"
                        [noIcons]="true"
                        [truncate]="true"
                        [metadata]="metadata"
                        *appHasModule="MODULE_NAMES.TAG_BINDING"
                        placeholder="Set the link’s label based on a property">
      </app-select-input>
      <app-slider-input [label]="'format_size'"
                        [value]="toNumber(focusedLink?.textFontSize !== undefined ? focusedLink?.textFontSize : DefaultValues.link.textFontSize)"
                        [isIcon]="true"
                        placeholder="Set the font size of the link’s label"
                        formControlName="textFontSize"
                        [discrete]="true"
                        [max]="16"
                        *appHasModule="MODULE_NAMES.LINK_STROKE"
                        [min]="0"
                        [step]="1">
      </app-slider-input>
    </ng-container>
  `,
  selector: 'maporium-link-style-advanced',
  standalone: true,
  imports: [
    FormsModule,
    MatButtonModule,
    MatDividerModule,
    ReactiveFormsModule,
    SelectInputComponent,
    HasModuleDirective,
    SliderInputComponent
  ]
})
export class LinkStyleAdvancedComponent {
  protected readonly MODULE_NAMES = MODULE_NAMES;
  protected readonly DefaultValues = DefaultValues;

  @Input() formGroup: any;
  @Input() metadata: any;
  @Input() focusedLink: GraphNodeLink | undefined;

  toNumber(value: any) {
    return Number(value || 0);
  }

}
