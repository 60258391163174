import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {PackageService} from '../api/services/package.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[appHasModule], [appHasModules]',
  standalone: true
})
export class HasModuleDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private packageService: PackageService
  ) {}

  @Input() set appHasModule(moduleName: string) {
    if (moduleName && this.packageService.hasModule(moduleName)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  @Input() set appHasModules(moduleNames: string[]) {
    if (moduleNames && this.packageService.hasModules(moduleNames)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
