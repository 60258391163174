import { Component, Input } from '@angular/core';
import { DefaultValues, MODULE_NAMES } from '@maporium-workspace/shared';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SliderInputComponent } from '../../../shared/forms/slider-input/slider-input.component';
import { TextInputComponent } from '../../../shared/forms/text-input/text-input.component';
import { SelectInputComponent } from '../../../shared/forms/select-input/select-input.component';
import { MatListModule } from '@angular/material/list';
import { ColorInputComponent } from '../../../shared/forms/color-input/color-input.component';
import { GraphNode, NodeTypesVisual } from '../../../api/interfaces/graph-node.interface';
import { HasModuleDirective } from '../../../packages/has-module.directive';
import { ImageReachabilityDirective } from '../../../shared/directives/url-validator/image-url-validator.directive';

@Component({
  template: `
    <ng-container [formGroup]="formGroup">
      <ng-container *appHasModule="MODULE_NAMES.NODE_COLOR">
        <app-color-input [value]="focusedNode?.color || DefaultValues.node.color"
                         placeholder="Set the node’s color"
                         [icon]="'palette'"
                         formControlName="color"></app-color-input>
      </ng-container>

      <ng-container *appHasModule="MODULE_NAMES.NODE_SHAPE">
        <app-select-input formControlName="type"
                          placeholder="Set the node’s shape"
                          [label]="'shapes'"
                          [isIcon]="true"
                          [options]="nodeTypeVisual">

        </app-select-input>
      </ng-container>

      <app-slider-input [label]="'line_weight'"
                        [value]="toNumber(focusedNode?.weight !== undefined ? focusedNode!.weight : DefaultValues.node.weight)"
                        [isIcon]="true"
                        *appHasModule="MODULE_NAMES.NODE_STROKE"
                        placeholder="Set the thickness of the node’s frame"
                        formControlName="weight"
                        [discrete]="true"
                        [max]="4"
                        [min]="0"
                        [step]="1"></app-slider-input>

      <ng-container *appHasModule="MODULE_NAMES.NODE_IMAGE">
        <app-text-input formControlName="imageUrl"
                        placeholder="Set the node’s image via an image URL"
                        appImageReachability
                        [autoFocusAndSelect]="true"
                        [label]="'image'"
                        [isIcon]="true"></app-text-input>
      </ng-container>

      <ng-container *appHasModule="MODULE_NAMES.NODE_SCALING">
        <fieldset formGroupName="properties">
          <app-slider-input [label]="'bubble_chart'"
                            [value]="focusedNode!.properties?.scale|| DefaultValues.node.properties.scale"
                            [isIcon]="true"
                            [discrete]="true"
                            placeholder="Set the node’s scale, i.e. comparative size"
                            formControlName="scale"
                            [max]="4"
                            [min]="0"
                            [step]="1"></app-slider-input>
        </fieldset>
      </ng-container>
    </ng-container>
  `,
  selector: 'maporium-node-style-light',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SliderInputComponent,
    TextInputComponent,
    SelectInputComponent,
    MatListModule,
    ColorInputComponent,
    HasModuleDirective,
    ImageReachabilityDirective,
  ],
  standalone: true,
})
export class NodeStyleLightComponent {
  protected readonly MODULE_NAMES = MODULE_NAMES;
  protected readonly DefaultValues = DefaultValues;
  public readonly nodeTypeVisual = NodeTypesVisual;


  @Input() formGroup: any;
  @Input() focusedNode: GraphNode | undefined;
  toNumber(value: any) {
    return Number(value || 0);
  }

}
