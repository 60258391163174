import {Component, OnDestroy, OnInit,} from '@angular/core';
import {MatListModule} from '@angular/material/list';
import {MatLineModule} from '@angular/material/core';
import {CommonModule} from '@angular/common';
import {MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {MatDialog} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {ConfirmationDialogComponent} from './confirmation-dialog.component';
import {UserService} from '../api/services/user.service';
import {Subscription} from 'rxjs';
import {MapService} from '../api/services/map.service';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MapStateDto} from '@maporium-workspace/shared';
import {MapStateService} from '../api/services/map-state.service';
import {CreateNewMapStateDialogComponent} from './create-new-map-state-dialog.component';

@Component({
  selector: 'maporium-state-list',
  styleUrls: ['./state-list.bottomsheet.component.scss'],
  template: `
    <mat-nav-list class="list-wrapper">
      <a href="" mat-list-item *ngFor="let state of states"
         (click)="selectState(state); $event.preventDefault(); $event.stopPropagation()"
         class="d-flex justify-content-evenly p-0">
        <span class="row w-100 align-items-center m-0">
          <span class="col-2">
              <mat-icon class="material-symbols-rounded"
                        matTooltip="Everyone can see this state">visibility</mat-icon>
            <!--              <mat-icon class="material-symbols-rounded"
                                    matTooltip="Viewers with whom you share this smap cannot see this scene"
                                    *ngIf="view.excludeFromReadOnly">visibility_off</mat-icon>-->
          </span>
          <span class="col-6 pe-5" [matTooltip]="state?.description ? state.description : state.name">
              <span matLine>{{state.name}}</span>
          </span>
          <span class="col-4  d-flex justify-content-end">
            <button mat-icon-button
                    matTooltip="Edit the state’s definition"
                    (click)="edit(state);$event.preventDefault();  $event.stopPropagation()">
              <mat-icon class="material-symbols-rounded">edit</mat-icon>
            </button>

            <button mat-icon-button
                    matTooltip="Delete the state"
                    (click)="askForConfirmation(state);$event.preventDefault();  $event.stopPropagation()">
                    <mat-icon class="material-symbols-rounded">delete</mat-icon>
            </button>
          </span>
        </span>
      </a>
    </mat-nav-list>
    <mat-divider></mat-divider>
    <div class="p-0 mt-1">
          <span class="row w-100 align-items-center m-0">
            <button matTooltip="Create a new state of the smap from the base state"
                    mat-flat-button
                    (click)="createNew(); $event.preventDefault();">
              <mat-icon class="material-symbols-rounded">add_circle</mat-icon>
                Create State
              </button>
          </span>
    </div>
  `,

  standalone: true,
  imports: [
    MatListModule,
    CommonModule,
    MatLineModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
  ]
})

export class StateListBottomsheetComponent implements OnInit, OnDestroy {
  public states: any[] = [];
  private subSink: Subscription = new Subscription();

  constructor(private matDialog: MatDialog,
              private userService: UserService,
              private stateService: MapStateService,
              private mapService: MapService,
              private bottomSheetRef: MatBottomSheetRef<StateListBottomsheetComponent>) {
  }

  ngOnInit() {
    this.subSink.add(
      this.stateService.findAllForMap(this.mapService.getCurrentSelectedMapFromStore().id).subscribe((states) => {
        this.states = states;
      })
    );
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
  }

  selectState(state: MapStateDto) {
    this.bottomSheetRef.dismiss(state);
  }

  createNew() {
    const ref = this.matDialog.open(CreateNewMapStateDialogComponent, {width: '35%', autoFocus: 'input'});
    ref.afterClosed().subscribe((state: MapStateDto) => {
      if (state) {
        this.bottomSheetRef.dismiss(state);
      }
    });
  }

  edit(state: MapStateDto) {
    const ref = this.matDialog.open(CreateNewMapStateDialogComponent, {data: state, width: '35%', autoFocus: 'input'});
    ref.afterClosed().subscribe((v: MapStateDto) => {
      if (v) {
        this.states = this.states.map((state) => state.id === v.id ? v : state);
        const currentLocalState = this.stateService.getCurrentLocalState();
        if (currentLocalState?.id === v.id) {
          this.stateService.updateCurrentLocalState(v);
        }
      }
    });
  }

  delete(state: MapStateDto) {
    this.stateService.delete(state.id as string).subscribe(() => {
      this.states = this.states.filter((s) => s.id !== state.id);
      const currentStateId = this.stateService.getCurrentLocalState()?.id;
      if (currentStateId === state.id) {
        if(this.states.length > 0) {
          this.stateService.selectState(this.states[0]);
        } else {
          this.stateService.selectState(null);
        }
      }
    });
  }

  askForConfirmation(state: MapStateDto) {
    const ref = this.matDialog.open(ConfirmationDialogComponent, {
      width: '35%',
      data: {
        title: 'Delete State',
        icon: 'delete',
        message: `Are you sure that you want to delete the '${state.name}' state?`,
        confirmText: 'Delete'
      }
    });
    ref.afterClosed().subscribe((res) => {
      if (res) {
        this.delete(state);
      }
    });
  }
}
