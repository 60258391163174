import { isArray, isEmpty, isEqual } from 'lodash';

export function diff<T>(source: any, target: any): Partial<T> {
  const difference: Partial<T> = {};

  if (source == null || target == null) {
    return difference; // Return an empty difference if either source or target is null or undefined
  }

  Object.keys(target).forEach((key: string) => {
    const targetValue = target[key];
    const sourceValue = source.hasOwnProperty(key) ? source[key] : undefined;

    // Check if the values are different using lodash's isEqual for deep comparison
    // and exclude differences that are solely empty arrays
    if (!isEqual(sourceValue, targetValue) && !(isEmpty(targetValue) && isArray(targetValue))) {
      // @ts-ignore
      difference[key] = targetValue;
    }
  });

  return difference;
}
