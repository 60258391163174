import { Injectable, signal } from '@angular/core';
import { MapResource } from '../resource/map.resource';
import { GraphMap, GraphNode, GraphNodeMapKey, GraphNodeMapListKey } from '../interfaces/graph-node.interface';
import { Observable, Subject, tap } from 'rxjs';
import { UserService } from './user.service';
import { NodePropertiesDto } from '@maporium-workspace/shared';
import { isInIframe } from '../../shared/helpers/maporium.validators';
import { get, isEmpty, set } from 'lodash';
import { ActivatedRoute } from '@angular/router';

@Injectable({providedIn: 'root'})
export class MapService {

  /**
   * @deprecated TODO: Rework to signal
   */
  public mapChangedSubject = new Subject<GraphMap | undefined>();
  public openMapSheetSubject = new Subject<void>();
  /**
   * @deprecated TODO: Rework to signal
   */
  public mapNameChangedSubject = new Subject<string>();
  public readonly selectedMap = signal<GraphMap | undefined>(this.getCurrentSelectedMapFromStore());

  constructor(private resource: MapResource,
              private userService: UserService,
              private route: ActivatedRoute) {
  }

  get selectedMapSignal(): GraphMap | undefined {
    return this.selectedMap();
  }

  setCurrentSelectedMapToStore(map: GraphMap | undefined) {
    const isIframe = isInIframe();
    if (map) {
      if (isIframe) {
        const existingList = JSON.parse(localStorage.getItem(GraphNodeMapListKey) || '{}');
        if (!isEmpty(existingList)) {
          set(existingList, map.id, map);
          localStorage.setItem(GraphNodeMapListKey, JSON.stringify(existingList));
        } else {
          localStorage.setItem(GraphNodeMapListKey, JSON.stringify({ [map.id]: map }));
        }
      } else {
        localStorage.setItem(GraphNodeMapKey, JSON.stringify(map));
      }
    } else {
      localStorage.removeItem(GraphNodeMapKey);
    }
    this.selectedMap.set(map);
  }

  /**
   * Reads the current user from local storage and checks if the user is the author of the current local storage map
   */
  isSmapAuthor(): boolean {
    return this.userService.getCurrentUserFromStorage().id === this.selectedMap()?.owner.id;
  }

  removeCurrentSelectedMapFromStore(mapId?: string) {
    if (!mapId) {
      localStorage.removeItem(GraphNodeMapKey);
    }
  }

  getCurrentSelectedMapFromStore(): GraphMap {
    const isIframe = isInIframe();
    let mapId;
    if (isIframe) {
      mapId = this.route.snapshot.queryParams['mapId'];
    }
    const storedMap = isIframe ? localStorage.getItem(GraphNodeMapListKey) : localStorage.getItem(GraphNodeMapKey);
    if (this.hasNoStoredMap(mapId)) {
      return {} as GraphMap;
    } else {
      let storedMapObject = isIframe ? get(JSON.parse(storedMap as string), mapId) : JSON.parse(storedMap as string);
      if (storedMapObject?.map) {
        storedMapObject = {...storedMapObject.map};
        delete storedMapObject.map;
        this.setCurrentSelectedMapToStore(storedMapObject);
      }
      return storedMapObject;
    }
  }

  findTemplates(): Observable<GraphMap[]> {
    return this.resource.findTemplates();
  }

  findAllForUser(userId: string): Observable<GraphMap[]> {
    return this.resource.findAllForUser(userId);
  }

  findPublicMap(mapId: string): Observable<GraphMap> {
    return this.resource.publicGetMap(mapId);
  }

  findByIdWithRelation(id: string): Observable<GraphMap> {
    return this.resource.findByIdWithRelation(id)
      .pipe(
        tap((map) => {
          this.setCurrentSelectedMapToStore(map);
        })
      );
  }

  createForUser(map: Partial<GraphMap>): Observable<GraphMap> {
    return this.resource.createForUser(map);
  }

  update(map: Partial<GraphMap>): Observable<GraphMap> {
    return this.resource.update(map);
  }

  delete(mapId: string) {
    return this.resource.delete(mapId);
  }

  duplicate(map: Partial<GraphMap>): Observable<GraphMap> {
    return this.resource.duplicate(map);
  }

  exportMapWithRelationsRecursive(graphMap: GraphMap): Observable<BlobPart> {
    return this.resource.exportMapWithRelationsRecursive(graphMap.id)
      .pipe(tap((map) => {
        const snakeCasedMapName = graphMap.name.replace(/ /g, '_').toLowerCase();
        const noSpecialCharactersMapName = snakeCasedMapName.replace(/[^a-zA-Z0-9]/g, '_');
        // @ts-ignore
        const url = window.URL.createObjectURL(map);
        const a = document.createElement('a');
        a.href = url;
        a.download = noSpecialCharactersMapName+'.smap';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }));
  }

  copyToMap(nodes: GraphNode[], mapId: string, grandParent?: GraphNode | null): Observable<GraphNode[]> {
    return this.resource.copyToMap(nodes, mapId, grandParent);
  }

  importMapFromZip(file: File): Observable<GraphMap> {
    const formData = new FormData();
    formData.append('file', file);
    return this.resource.importMapFromZip(formData);
  }

  createGptMapWithNodeAndEdges(gptNodes: GptNode[], gptEdges: GptEdge[]) {
    const graphNodes = gptNodes.map((gptNode) => {
      const graphNode: GraphNode = {
        id: gptNode.id,
        name: gptNode.name,
        color: gptNode.color,
        properties: gptNode.properties,
        children: [],
        x: gptNode.position.x,
        y: gptNode.position.y,
        links: []
      };
      return graphNode;
    });
    const graphEdges = gptEdges.map((gptEdge) => {
      const graphEdge = {
        id: gptEdge.id,
        name: gptEdge.name,
        color: gptEdge.color,
        from: gptEdge.from,
        to: gptEdge.to
      };
      return graphEdge;
    });
    //@ts-ignore
    return this.resource.createGptMapWithNodeAndEdges(graphNodes, graphEdges, this.userService.getCurrentUserFromStorage().id);
  }

  shareMap(mapId: string, userIds: string[]) {
    return this.resource.shareMap(mapId, userIds);
  }

  unshareMap(mapId: string) {
    return this.resource.unshareMap(mapId);
  }

  hasNoStoredMap(mapId?: string): boolean {
    const storedMap = isInIframe() ? localStorage.getItem(GraphNodeMapListKey) : localStorage.getItem(GraphNodeMapKey);
    if (mapId && isInIframe()) {
      const iframeRelatedMap = get(JSON.parse(storedMap as string), mapId);
      return iframeRelatedMap === undefined || iframeRelatedMap === null || iframeRelatedMap === '' || iframeRelatedMap === 'undefined' || iframeRelatedMap === 'null';
    }
    return storedMap === undefined || storedMap === null || storedMap === '' || storedMap === 'undefined' || storedMap === 'null';
  }

  openPublicAccessToMap(mapId: string) {
    return this.resource.openPublicAccessToMap(mapId);
  }

  closePublicAccessToMap(mapId: string) {
    return this.resource.closePublicAccessToMap(mapId);
  }
}

export interface GptNode {
  id: string;
  name: string;
  color: string;
  properties: NodePropertiesDto;
  position: {
    x: number;
    y: number;
  }
}

export interface GptEdge {
  id: string;
  name: string;
  from: string;
  to: string;
  color: string;
}

export interface IFrameCurrentMapList {
  [uuid: string]: GraphMap;
}
