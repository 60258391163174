import {Core, NodeSingular} from 'cytoscape';
import { DefaultValues } from '@maporium-workspace/shared';

export const DOUBLE_TAP_SETTINGS_KEY = 'doubleTap';

export function doubleTapExtension(cy: Core, {options}: { options: { doubleTapDelay: number } }) {
  const doubleTapDelay = options.doubleTapDelay || DefaultValues.doubleTapDelay; // Adjust the delay as needed

  let tappedBefore: NodeSingular | null = null;
  let tappedTimeout: ReturnType<typeof setTimeout> | null = null;

  cy.on('tap', (event) => {
    const target = event.target;

    if (tappedTimeout && tappedBefore === target) {
      clearTimeout(tappedTimeout);
      tappedBefore = null;
      target.emit('doubleTap', event);
    } else {
      tappedTimeout = setTimeout(() => {
        tappedBefore = null;
      }, doubleTapDelay);
      tappedBefore = target;
    }
  });
}

export function setDoubleTapSetting(value: number) {
  localStorage.setItem(DOUBLE_TAP_SETTINGS_KEY, value.toString());
}

export function getDoubleTapSetting() {
  return parseInt(localStorage.getItem(DOUBLE_TAP_SETTINGS_KEY) || DefaultValues.doubleTapDelay.toString());
}
