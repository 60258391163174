import { User } from './user.interface';
import { NodePropertiesDto } from '@maporium-workspace/shared';
import { NodeStateData } from '../../../../../maporium/src/app/entities/types/node-state-data';
import { LinkStateData } from '../../../../../maporium/src/app/entities/types/link-state.data';
import { MetaData } from './metadata';

export interface GraphMap {
  id: string;
  name: string;
  description?: string;
  isTemplate: boolean;
  owner: User;
  nodes?: GraphNode[];
  createdAt?: Date;
  updatedAt?: Date;
  sharedWith?: User[] | undefined | null;
  metadata?: MetaData[];
  isReadonly?: boolean | undefined | null;
  isPublic: boolean | undefined;
  includeViewsAndTours?: boolean;
}
export interface GraphNode {
  id?: string;
  name?: string;
  description?: string;
  imageUrl?: string;
  color?: string;
  // @deprecated will be removed in future versions
  hasBorder?: boolean;
  properties?: NodePropertiesDto;
  type?: string;
  excludedFromState?: boolean;
  children: GraphNode[];
  parent?: GraphNode;
  weight?: number;
  map?: GraphMap;
  showBorder?: boolean;
  collapsed?: boolean;
  metadata?: MetaData[];
  x?: number;
  y?: number;
  links?: GraphNodeLink[] | null;
  sharedWith?: GraphNode[];
  reset?: boolean;
  states?: NodeStateData[];
  hasComments?: boolean;

}

export interface GraphNodeLink {
  id?: string;
  from: GraphNode;
  to: GraphNode;

  name?: string;
  description?: string;
  color?: string;
  label?: string;
  weight?: number | string;
  metadata?: MetaData[];
  type?: LinkType | string;
  shape?: LinkShape | string;
  direction?: LinkDirection | string;
  excludedFromState?: boolean;
  states?: LinkStateData[];
  textFontSize?: number | string;
  origin?: GraphNodeLink;
  reset?: boolean;
  hasComments?: boolean;
}

export type LinkType = 'BEZIER' | 'UNBUNDLED_BEZIER' | 'HAYSTACK' | 'SEGMENTS' | 'TAXI' | 'STRAIGHT_TRIANGLE';
export type LinkShape = 'solid' | 'dashed' | 'dotted';
export type LinkDirection = 'directed' | 'undirected';
export const LinkDirectionMap = [
  {key: 'directed', value: 'directed', icon: 'trending_flat'},
  {key: 'bidirected', value: 'bidirected', icon: 'width'},
  {key: 'undirected', value: 'undirected', icon: 'horizontal_rule'},
]


export const EdgeTypeMapCy = {
  BEZIER: 'bezier',
  UNBUNDLED_BEZIER: 'unbundled-bezier',
  HAYSTACK: 'haystack',
  SEGMENTS: 'segments',
  TAXI: 'taxi',
  STRAIGHT_TRIANGLE: 'straight-triangle',
}
export const EdgeTypeMapVisual = [
  {key: 'BEZIER', value: 'straight', icon: 'pen_size_2'},
  {key: 'UNBUNDLED_BEZIER', value: 'curved', icon: 'line_curve'},
]
export const EdgeShapeMap = {
  solid: 'solid',
  dashed: 'dashed',
  dotted: 'dotted',
}

export const EdgeShapeMapVisual = [
  {key: 'solid', value: 'solid', icon: 'horizontal_rule' },
  {key: 'dashed', value: 'dashed', icon: 'unknown_med' },
  {key: 'dotted', value: 'dotted', icon: 'more_horiz'},
]

export const NodeTypesCyto = [
  'ELLIPSE',
  'RECTANGLE',
  'TRIANGLE',
  'DIAMOND',
  'ROUND-DIAMOND',
  'ROUND-TRIANGLE',
  'ROUND-RECTANGLE',
  'BOTTOM-ROUND-RECTANGLE',
  'CUT-RECTANGLE',
  'BARREL',
  'RHOMBOID',
  'HEXAGON',
  'ROUND-HEXAGON',
  'HEPTAGON',
  'ROUND-HEPTAGON',
  'OCTAGON',
  'ROUND-OCTAGON',
  'STAR',
  'PENTAGON',
  'ROUND-PENTAGON',
  'TAG',
  'ROUND-TAG',
  'VEE',
];

export const NodeTypesVisual = [
  {key: 'ELLIPSE', value: 'circle', icon: 'circle'},
  {key: 'TRIANGLE', value: 'triangle', icon: 'change_history'},
  {key: 'RECTANGLE', value: 'square', icon: 'square'},
  {key: 'PENTAGON', value: 'pentagon', icon: 'pentagon'},
  {key: 'HEXAGON', value: 'hexagon', icon: 'hexagon'},
  // {key: 'HEPTAGON', value: 'heptagon'},
  // {key: 'OCTAGON', value: 'octagon'},
]

export const GraphNodeMapKey = 'graph-node-map';
export const GraphNodeMapListKey = 'graph-node-map-list';

export interface MultiUpdatePayload {
  nodeIds: string[],
  linkIds: string[],
  stateId: string,
  diff: {
    node?: Partial<GraphNode>,
    link: Partial<GraphNodeLink>
  }
}
