import { LinkTarget } from './link-target.enum';

export const LinkTargetSettings = {
  TARGET_TAB: {
    target: LinkTarget.SingleTab,
    label: 'single tab',
    icon: 'tab'
  },
  TARGET_WINDOW: {
    target: LinkTarget.SingleWindow,
    label: 'single window',
    icon: 'web_asset'
  },
  MULTI_TAB: {
    target: LinkTarget.MultiTab,
    label: 'multiple tabs',
    icon: 'tab_group'
  },
  MULTI_WINDOW: {
    target: LinkTarget.MultiWindow,
    label: 'multiple windows',
    icon: 'select_window'
  }
};
