import { Directive, ElementRef, OnInit, OnDestroy, Self } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import {environment} from '../../../../environments/environment';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[appImageReachability]',
  standalone: true,
})
export class ImageReachabilityDirective implements OnInit, OnDestroy {
  private subscription: Subscription | undefined;

  constructor(
    private el: ElementRef,
    private http: HttpClient,
    @Self() private control: NgControl
  ) {}

  ngOnInit() {
    // @ts-ignore
    this.subscription = this.control.valueChanges.subscribe(newValue => {
      this.checkImageReachability(newValue);
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  async checkImageReachability(url: string) {
    // Remove previously set class if any
    this.el.nativeElement.classList.remove('unreachable-image');

    // Check reachability
    if (url) {
      try {
        await this.http.get(environment.apiUrl + '/public/image?url=' + url, { responseType: 'blob' }).toPromise();
      } catch (error) {
        // If an error occurs (image is not reachable), add the class
        this.el.nativeElement.classList.add('unreachable-image');
      }
    }
  }
}
