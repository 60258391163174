// BaseCustomInputComponent (base component)
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Component, forwardRef} from '@angular/core';
import {debounceTime, noop, tap} from 'rxjs';

@Component({
  selector: 'maporium-base-custom-input',
  template: '', // No template content in the base class
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BaseCustomInputComponent),
      multi: true,
    },
  ],
  standalone: true,
})
export class BaseCustomInputComponent implements ControlValueAccessor {

  formControl: FormControl = new FormControl<string>('');


  onChange: (value: string) => void = noop;
  onTouch: () => void = noop;

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.formControl.disable() : this.formControl.enable();
  }

  writeValue(value: string): void {
    this.formControl.setValue(value, { emitEvent: false });
  }

  ngOnInit(): void {
    this.formControl.valueChanges
      .pipe(
        debounceTime(200),
        tap(value => this.onChange(value)),
      )
      .subscribe();
  }
}
