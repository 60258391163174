import {Injectable} from '@angular/core';
import {GptResource} from '../resource/gpt.resource';
import {GptMapResponse} from '../interfaces/gpt-map.response';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GptService {
  public generatedSubject = new Subject<GptMapResponse>();

  constructor(private resource: GptResource) {
  }

  generateMap(prompt: string, options: {generateColor: boolean}) {
    return this.resource.generateMap(prompt, options);
  }

  pullPrompt(id: string) {
    return this.resource.pullPormpt(id);
  }
}
