<form [formGroup]="formGroup" autocomplete="off" *ngIf="formGroup">
  <maporium-title-description (fieldsChange)="onTitleDescriptionChange($event)"
                              [isNew]="focusedLink?.isNew || false"
                              [fields]="{title: formGroup.get('name')?.value, description: formGroup.get('description')?.value || ''}"
                              [isEditing]="true"></maporium-title-description>

  <div class="mt-3">
    <cdk-accordion [multi]="true" class="accordion">
      <maporium-accordion-item *appHasModule="MODULE_NAMES.METADATA_BINDING"
                               [sections]="focusedLink?.metadata?.length || 0"
                               [title]="'Properties'">
        <maporium-meta-data-control (extendMeta)="extendMeta.emit()" formControlName="metadata">
        </maporium-meta-data-control>
      </maporium-accordion-item>
      <maporium-accordion-item [title]="'Comments'" [sections]="commentsCount">
        <maporium-comments (updated)="this.focusedLink!.hasComments = $event; updateLinkEvent.emit(this.focusedLink)"
                           (commentCount)="commentsCount = $event"
                           [entityId]="focusedLink!.id"
                           [entityType]="'link'">
        </maporium-comments>
      </maporium-accordion-item>
      <maporium-accordion-item [sections]="7" [title]="'Styles'">
        <maporium-link-style-light [focusedLink]="focusedLink" [formGroup]="formGroup"></maporium-link-style-light>
        <div class="justify-content-center d-flex mt-1">
          <button (click)="toggleAdvancedStyles()"
                  class="show-more-button"
                  mat-flat-button>
            <span class="show-more-button-title">{{ showMoreStyles ? 'less' : 'more' }}</span>
            <span class="suffix-icon">
            <mat-icon>{{ showMoreStyles ? 'expand_less' : 'expand_more' }}</mat-icon>
          </span>
          </button>
        </div>
        @if (showMoreStyles) {
          <maporium-link-style-advanced [formGroup]="formGroup"
                                        [metadata]="focusedLink?.metadata"></maporium-link-style-advanced>
        }
      </maporium-accordion-item>

      @if (!isDefaultState) {
        <maporium-accordion-item [title]="'States'">
          <app-select-input formControlName="excludedFromState"
                            class="mb-1"
                            [label]="'unknown_2'"
                            [isIcon]="true"
                            [options]="excludeFromStateOptions"
                            placeholder="Decide whether to include or exclude the link in the active state">
          </app-select-input>
          <div class="row mt-2 justify-content-center no-gutter">
            <button class="reset-button w-50"
                    mat-flat-button
                    (click)="resetState()">Revert to Base
            </button>
          </div>
        </maporium-accordion-item>
      }
    </cdk-accordion>
  </div>
</form>
