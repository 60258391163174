import { Injectable } from '@angular/core';
import { MetadataResource } from '../resource/metadata.resource';

@Injectable({ providedIn: 'root' })
export class MetadataService {
  constructor(private resource: MetadataResource) {
  }

  createOneForMap(mapId: string, body: { key: string, value: string, order: number }) {
    return this.resource.createOneForMap(mapId, body);
  }
}
