import { Component, forwardRef, Input, ElementRef, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import {BaseCustomInputComponent} from '../base-custom-input.component';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'app-text-area',
  standalone: true,
  styles: [`
    textarea {
      border-radius: 2px;
      outline: none;
      border: 2px solid black;
    }
  `],
  template: `
    <div class="row align-items-start no-gutter mt-2">
      <div class="col-4 pe-1" *ngIf="label && !isIcon">
        {{label}}
      </div>
      <div class="col-2 justify-content-center align-items-start d-flex"
           *ngIf="label && isIcon"
           [matTooltip]="placeholder">
        <span class="material-symbols-rounded"><label>{{label}}</label></span>
      </div>
      <div class="pt-1" [ngClass]="{'col-8': label !== undefined && label !== '' && !isIcon, 'col-10': label !== '' && isIcon}">
        <textarea
          class="w-100"
          #textAreaElement
          rows="3"
          [formControl]="formControl"
          [value]="value"></textarea>
      </div>
    </div>
  `,
  imports: [FormsModule, ReactiveFormsModule, MatTooltipModule, CommonModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextAreaComponent),
      multi: true,
    },
  ],
})
export class TextAreaComponent extends BaseCustomInputComponent{
  @Input() label = '';
  @Input() placeholder = '';
  @Input() value: string | undefined = '';
  @Input() isIcon = false;

  @Input() autoFocusAndSelect = false;
  @ViewChild('textAreaElement') textAreaElement: ElementRef | undefined;
  ngAfterViewInit(): void {
    if (this.autoFocusAndSelect) {
      // Use setTimeout to ensure the text area is rendered and can be focused.
      setTimeout(() => {
        if (this.textAreaElement === undefined) return;
        this.textAreaElement.nativeElement.focus();
        this.textAreaElement.nativeElement.select(); // Select all content
      });
    }
  }
}
