import { Core } from 'cytoscape';
import { GraphMap, GraphNodeMapKey } from '../app/api/interfaces/graph-node.interface';
import { CytoscapeService } from '../app/services/cy-services/cytoscape.service';
import { signal } from '@angular/core';

export const PAN_ZOOM_STORAGE_KEY = 'pan-zoom-storage';
export const COLLAPSED_NODE_PAN_STATE_KEY = 'collapsedNodePanState';
export const GENERAL_COLLAPSED_PAN_STATE_KEY = 'generalCollapsedPanState';

export let ZOOM_IN_PROGRESS = false;

let core: Core;

export function storePanZoomDynamic(cy: Core) {
  if (!cy) return;
  core = cy;
  core.on('pan zoom', panZoomListener);
}

export function destroyPanZoom(cy: Core) {
  if (!cy) return;
  cy.off('pan zoom', panZoomListener);
}

export function restorePanZoomDynamic(cyService?: CytoscapeService, useAnimation = true) {
  if (!core) return;
  const localStorageMapString = localStorage.getItem(GraphNodeMapKey) as string;
  const map = JSON.parse(localStorageMapString !== 'undefined' ? localStorageMapString : '{}');
  if (!map) return;
  const panZoomStorage = JSON.parse(localStorage.getItem(PAN_ZOOM_STORAGE_KEY) as string) as PanZoomStorage[];
  if (!panZoomStorage) {
    if (cyService) {
      cyService.centerCanvas();
    }
    return;
  }
  // find the setting for the current map
  const currentMap = panZoomStorage.find(p => p.map.id === map.id);
  if (!currentMap) {
    if (cyService) {
      cyService.centerCanvas();
    }
    return;
  }
  ZOOM_IN_PROGRESS = true;
  if (useAnimation) {
    core.animate({
      pan: currentMap.pan,
      zoom: currentMap.zoom,
      duration: 500,
      easing: 'ease-in-out',
      complete: () => {
        ZOOM_IN_PROGRESS = false;
      }
    });
  } else {
    core.pan(currentMap.pan);
    core.zoom(currentMap.zoom);
    ZOOM_IN_PROGRESS = false;
  }
}

export function setPanZoomForCollapsedNode(nodeId: string, collapsed = false) {
  const pan = core.pan();
  const zoom = core.zoom();
  const state = {
    nodeId,
    pan,
    zoom,
    collapsed
  };
  localStorage.removeItem(GENERAL_COLLAPSED_PAN_STATE_KEY);
  localStorage.setItem(COLLAPSED_NODE_PAN_STATE_KEY, JSON.stringify(state));
}

export function setPanZoomGeneral() {
  const pan = core.pan();
  const zoom = core.zoom();
  const state = {
    pan,
    zoom,
  }
  invalidatePanZoomForCollapsedNode();
  localStorage.setItem(GENERAL_COLLAPSED_PAN_STATE_KEY, JSON.stringify(state));
}

export function restoreGeneralPanZoom(cy: Core) {
  try {
    const state = JSON.parse(localStorage.getItem(GENERAL_COLLAPSED_PAN_STATE_KEY) as string) as CollapsedNodePanState;
    if (!state) return null;
    return state;
  } catch (e) {
    return null;
  }
}

export function invalidateGeneralPanZoom() {
  localStorage.removeItem(GENERAL_COLLAPSED_PAN_STATE_KEY);
}

export function restorePanZoomForCollapsedNode(nodeId: string): CollapsedNodePanState | null {
  try {
    const state = JSON.parse(localStorage.getItem(COLLAPSED_NODE_PAN_STATE_KEY) as string) as CollapsedNodePanState;
    if (!state) return null;
    if (state.nodeId !== nodeId) return null;
    return state;
  } catch (e) {
    return null;
  }
}

export function restoreSavedPanZoomForCollapsedNode(nodeId: string, cy: Core, complete?: (collapsed: boolean) => void) {
  ExpandCollapseInProgress.set(true);
  const state =  restorePanZoomForCollapsedNode(nodeId);
  if (state) {
    cy.animate({
      pan: state.pan,
      zoom: state.zoom,
      duration: 500,
      easing: 'ease-in-out',
      complete() {
        ExpandCollapseInProgress.set(false);
        if (complete) {
          complete(state.collapsed);
        }
      }
    });
  }
}

export function invalidatePanZoomForCollapsedNode() {
  localStorage.removeItem(COLLAPSED_NODE_PAN_STATE_KEY);
}

export const ExpandCollapseInProgress = signal(false);

function panZoomListener() {
  const map = JSON.parse(localStorage.getItem(GraphNodeMapKey) as string);
  if (!map) return;
  const existing = JSON.parse(localStorage.getItem(PAN_ZOOM_STORAGE_KEY) as string) as PanZoomStorage[];
  if (existing && existing.length) {
    const existingMap = existing.find(e => e.map.id === map.id);
    if (existingMap) {
      existingMap.pan = core.pan();
      existingMap.zoom = core.zoom();
      localStorage.setItem(PAN_ZOOM_STORAGE_KEY, JSON.stringify(existing));
      return;
    } else {
      existing.push({
        pan: core.pan(),
        zoom: core.zoom(),
        map
      });
      localStorage.setItem(PAN_ZOOM_STORAGE_KEY, JSON.stringify(existing));
      return;
    }
  } else {
    localStorage.setItem(PAN_ZOOM_STORAGE_KEY, JSON.stringify([{
      pan: core.pan(),
      zoom: core.zoom(),
      map
    }]));
  }

}

export interface PanZoomStorage {
  pan: { x: number, y: number };
  zoom: number;
  map: GraphMap;
}

export interface CollapsedNodePanState {
  nodeId: string;
  pan: { x: number, y: number };
  zoom: number;
  collapsed: boolean;
}
