import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {MapTour} from '../interfaces/map-tour';

@Injectable({ providedIn: 'root' })
export class TourResource {
  private readonly baseUrl = environment.apiUrl + '/tours';

  constructor(private http: HttpClient) {
  }

  getOne(id: string) {
    return this.http.get<MapTour>(this.baseUrl + '/' + id);
  }
  getAll(mapId: string) {
    return this.http.get<MapTour[]>(this.baseUrl + '/map/' + mapId);
  }

  getAllPublic(mapId: string) {
    return this.http.get<MapTour[]>(environment.apiUrl + '/public/map/' + mapId + '/tours');
  }

  create(tour: MapTour) {
    return this.http.post<MapTour>(this.baseUrl, tour);
  }

  update(tour: MapTour) {
    return this.http.patch<MapTour>(this.baseUrl, tour);
  }

  delete(tourId: string) {
    return this.http.delete<void>(this.baseUrl + '/' + tourId);
  }

}
