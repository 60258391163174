import { DefaultValues, MODULE_NAMES } from '@maporium-workspace/shared';
import { FormControl, FormGroup } from '@angular/forms';
import { isEqual } from 'lodash';
import { debounceTime, distinctUntilChanged } from 'rxjs';

export abstract class SidebarFormAbstractComponent<T> {
  public showMoreStyles = false;
  abstract formGroup: FormGroup;
  protected readonly MODULE_NAMES = MODULE_NAMES;
  protected readonly DefaultValues = DefaultValues;
  protected debounceTime = 500;

  init() {
    this.showMoreStyles = localStorage.getItem('showMoreStyles') === 'true';
    this.formGroup.valueChanges
      .pipe(
        debounceTime(this.debounceTime),
        distinctUntilChanged((prev, curr) => isEqual(prev, curr))
      )
      .subscribe((form) => {
        this.update();
      });
  }

  get metadataControl(): FormControl {
    return this.formGroup.get('metadata') as FormControl;
  }

  toggleAdvancedStyles() {
    this.showMoreStyles = !this.showMoreStyles;
    localStorage.setItem('showMoreStyles', this.showMoreStyles.toString());
  }

  abstract selectNameInput(): void;

  abstract patchForm(model: T): void;

  abstract resetState(): void;

  // Use it to update the data , use for call emit on output
  abstract update(): void;
}
