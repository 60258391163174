import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GeneralInfo} from '../interfaces/general-info';

@Injectable({
  providedIn: 'root'
})
export class SettingsResource {
  private readonly baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) {
  }

  getGeneralInfo(): Observable<GeneralInfo> {
    return this.http.get<GeneralInfo>(this.baseUrl + '/settings/general-info');
  }

  saveGeneralInfo(generalInfo: Partial<GeneralInfo>): Observable<GeneralInfo> {
    return this.http.post<GeneralInfo>(this.baseUrl + '/settings/general-info', generalInfo);
  }
}
