import {Component, OnDestroy, OnInit,} from '@angular/core';
import {MatListModule} from '@angular/material/list';
import {MatLineModule} from '@angular/material/core';
import {CommonModule} from '@angular/common';
import {MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {MatDialog} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {ConfirmationDialogComponent} from './confirmation-dialog.component';
import {UserService} from '../api/services/user.service';
import {Subscription} from 'rxjs';
import {ViewService} from '../api/services/view.service';
import {CreateNewViewDialogComponent} from './create-new-view-dialog.component';
import {SavedView} from '../api/interfaces/saved-view';
import {MapService} from '../api/services/map.service';
import {MatTooltipModule} from '@angular/material/tooltip';
import { isInIframe } from '../shared/helpers/maporium.validators';

@Component({
  selector: 'maporium-views-list',
  styleUrls: ['./views-list.bottomsheet.component.scss'],
  template: `
    <mat-nav-list class="list-wrapper">
      <a href="" mat-list-item *ngFor="let view of views"
         (click)="selectView(view); $event.preventDefault(); $event.stopPropagation()"
         class="d-flex justify-content-evenly p-0">
        <span class="row w-100 align-items-center m-0">
          <span class="col-2">
              <mat-icon class="material-symbols-rounded"
                        *ngIf="!view.excludeFromReadOnly"
                        matTooltip="Everyone can see this scene">visibility</mat-icon>
              <mat-icon class="material-symbols-rounded"
                        matTooltip="Viewers with whom you share this smap cannot see this scene"
                        *ngIf="view.excludeFromReadOnly">visibility_off</mat-icon>
          </span>
          <span class="col-6 pe-5" [matTooltip]="view?.description ? view.description : view.name">
              <span matLine>{{view.name}}</span>
          </span>
          <span class="col-4  d-flex justify-content-end">
            <button mat-icon-button
                    matTooltip="Edit the scene’s definition"
                    (click)="edit(view);$event.preventDefault();  $event.stopPropagation()">
              <mat-icon class="material-symbols-rounded">edit</mat-icon>
            </button>

            <button mat-icon-button
                    matTooltip="Delete the scene"
                    (click)="askForConfirmation(view);$event.preventDefault();  $event.stopPropagation()">
                    <mat-icon class="material-symbols-rounded">delete</mat-icon>
            </button>
          </span>
        </span>
      </a>
    </mat-nav-list>
    <mat-divider></mat-divider>
    <div class="p-0 mt-1">
          <span class="row w-100 align-items-center m-0">
            <button matTooltip="Create a new scene based on the current view"
                    mat-flat-button
                    (click)="createNew(); $event.preventDefault();">
              <mat-icon class="material-symbols-rounded">add_circle</mat-icon>
                Create Scene
              </button>
          </span>
    </div>
  `,

  standalone: true,
  imports: [
    MatListModule,
    CommonModule,
    MatLineModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
  ]
})

export class ViewsListBottomsheetComponent implements OnInit, OnDestroy {
  public views: any[] = [];
  private subSink: Subscription = new Subscription();

  constructor(private matDialog: MatDialog,
              private userService: UserService,
              private viewService: ViewService,
              private mapService: MapService,
              private bottomSheetRef: MatBottomSheetRef<ViewsListBottomsheetComponent>) {
  }

  ngOnInit() {
    this.subSink.add(
      (isInIframe() ? this.viewService.getAllPublic(this.mapService.getCurrentSelectedMapFromStore().id) : this.viewService.getAll(this.mapService.getCurrentSelectedMapFromStore().id))
        .subscribe((views) => {
          this.views = views;
        })
    );
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
  }

  selectView(view: SavedView) {
    this.bottomSheetRef.dismiss(view);
  }

  createNew() {
    const ref = this.matDialog.open(CreateNewViewDialogComponent, {width: '35%', autoFocus: 'input'});
    ref.afterClosed().subscribe((view: SavedView) => {
      const newView = {...view, isNew: true};
      if (view) {
        this.bottomSheetRef.dismiss(newView);
      }
    });
  }

  edit(view: SavedView) {
    const ref = this.matDialog.open(CreateNewViewDialogComponent, {data: view, width: '35%', autoFocus: 'input'});
    ref.afterClosed().subscribe((v: SavedView) => {
      if (v) {
        this.views = this.views.map((view) => view.id === v.id ? v : view);
      }
    });
  }

  delete(view: SavedView) {
    view.map = this.mapService.getCurrentSelectedMapFromStore();
    this.viewService.delete(view, () => this.views = this.views.filter((v) => v.id !== view.id));
  }

  askForConfirmation(view: SavedView) {
    const ref = this.matDialog.open(ConfirmationDialogComponent, {
      width: '35%',
      data: {
        title: 'Delete Scene',
        icon: 'delete',
        message: `Are you sure that you want to delete the '${view.name}' scene?`,
        confirmText: 'Delete'
      }
    });
    ref.afterClosed().subscribe((res) => {
      if (res) {
        this.delete(view);
      }
    });
  }
}
