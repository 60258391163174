import { Injectable, signal, WritableSignal } from '@angular/core';
import { CytoscapeService } from './cy-services/cytoscape.service';
// @ts-ignore
import * as undoRedo from 'cytoscape-undo-redo';
import cytoscape from 'cytoscape';
import { NodeService } from '../api/services/node.service';
import { GraphNode, GraphNodeLink } from '../api/interfaces/graph-node.interface';
import { isEmpty } from 'lodash';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UndoRedoService {
  public undoRedo: any;
  private restoreSignal: WritableSignal<{nodes: GraphNode[], links: GraphNodeLink[]} | null> = signal(null);

  get restored() {
    return this.restoreSignal.asReadonly();
  }

  constructor(private cyService: CytoscapeService, private nodeService: NodeService) {
    setTimeout(() => {
      this.initHandlers();
    }, 300);
  }

  initHandlers() {
    if (!this.cyService.cy) {
      return;
    }
    cytoscape.use(undoRedo);
    const undoRedoOptions = {
      isDebug: true,
      stackSizeLimit: 10,
      undoableDrag: true
    };
    //@ts-ignore
    this.undoRedo = this.cyService.cy.undoRedo(undoRedoOptions);
    const ur = this.undoRedo;
    ur.action('remove', (event: { ids: string[] }) => this.onRemove(event), (event: {ids: string[]}) => this.onUndo(event));
  }

  emitRemove(element: { ids: string[] }) {
    this.undoRedo.do('remove', element);
  }

  onRemove(element: { ids: string[] }) {
    return element;
  }


  onUndo(event: {ids: string[]}) {
    this.nodeService.restoreWithChildren(event.ids)
      .subscribe((res) => {
        this.restoreSignal.set(res);
      });
  }
}
