import { GraphNode, GraphNodeLink } from '../../api/interfaces/graph-node.interface';
import { set } from 'lodash';

export interface MultiElementProperties {
  color?: string;
  // Thickness of node and link
  weight?: number;
  textFontSize?: string;
  name?: string;
  properties?: {
    textFontSize?: string;
  }
  // Exclusion from state
  excludedFromState?: boolean;
}

export interface NodeBatchChangeProperties{
  type?: string;
  imageUrl?: string;
  imageOpacity?: string | number;
  scale?: string | number;
  opacity?: string | number;
}

export interface LinkBatchChangeProperties {
  // links shape
  type?: string;
  // links Stroke
  shape?: string;
  direction?: string;
}

export interface MultiUpdateEvent {
  node?: Partial<GraphNode>,
  general?: Partial<MultiElementProperties>
  link?: Partial<GraphNodeLink>
}

export function getMultiElementPropertiesFromDiff(diff: Partial<MultiElementProperties>) {
  const multiElementProperties: MultiElementProperties = {};

  if (diff.color !== undefined) {
    multiElementProperties.color = diff.color;
  }

  if (diff.name !== undefined) {
    multiElementProperties.name = diff.name;
  }

  if (diff.weight !== undefined) {
    multiElementProperties.weight = diff.weight;
  }

  if (diff.textFontSize !== undefined) {
    set(multiElementProperties, 'textFontSize', diff.textFontSize);
    set(multiElementProperties, 'properties.textFontSize', diff.textFontSize)
  }
  if (diff.properties !== undefined) {
    if (diff.properties.textFontSize !== undefined) {
      set(multiElementProperties, 'properties.textFontSize', diff.properties.textFontSize);
      set(multiElementProperties, 'textFontSize', diff.properties.textFontSize);
    }
  }

  if (diff.excludedFromState !== undefined) {
    multiElementProperties.excludedFromState = diff.excludedFromState;
  }

  return multiElementProperties;
}

export function getNodeBatchChangePropertiesFromDiff(diff: Partial<GraphNode>) {
  const nodeBatchChangeProperties: NodeBatchChangeProperties = {};

  if (diff.type !== undefined) {
    nodeBatchChangeProperties.type = diff.type;
  }

  if (diff.imageUrl !== undefined) {
    nodeBatchChangeProperties.imageUrl = diff.imageUrl;
  }

  if (diff.properties !== undefined) {
    if (diff.properties.scale !== undefined) {
      set(nodeBatchChangeProperties, 'properties.scale', diff.properties.scale);
    }

    if (diff.properties.opacity !== undefined) {
      set(nodeBatchChangeProperties, 'properties.opacity', diff.properties.opacity);
    }

    if (diff.properties.imageOpacity !== undefined) {
      set(nodeBatchChangeProperties, 'properties.imageOpacity', diff.properties.imageOpacity);
    }

    if (diff.properties.textMarginX !== undefined) {
      set(nodeBatchChangeProperties, 'properties.textMarginX', diff.properties.textMarginX);
    }

    if (diff.properties.textMarginY !== undefined) {
      set(nodeBatchChangeProperties, 'properties.textMarginY', diff.properties.textMarginY);
    }

    if (diff.properties.textValign !== undefined) {
      set(nodeBatchChangeProperties, 'properties.textValign', diff.properties.textValign);
    }

    if (diff.properties.textHalign !== undefined) {
      set(nodeBatchChangeProperties, 'properties.textHalign', diff.properties.textHalign);
    }

  }

  return nodeBatchChangeProperties;
}

export function getLinkBatchChangePropertiesFromDiff(diff: Partial<GraphNodeLink>) {
  const linkBatchChangeProperties: LinkBatchChangeProperties = {};

  if (diff.type !== undefined) {
    linkBatchChangeProperties.type = diff.type;
  }

  if (diff.shape !== undefined) {
    linkBatchChangeProperties.shape = diff.shape;
  }

  if (diff.direction !== undefined) {
    linkBatchChangeProperties.direction = diff.direction;
  }

  return linkBatchChangeProperties;
}
