import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MetaData } from '../interfaces/metadata';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MetadataResource {
  private readonly baseUrl = environment.apiUrl + '/metadata';

  constructor(private http: HttpClient) {
  }

  createOneForMap(mapId: string, body: { key: string, value: string, order: number }): Observable<MetaData> {
    return this.http.post<MetaData>(`${this.baseUrl}/map/${mapId}`, body);
  }
}
