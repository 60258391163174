import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MetaDataControlComponent } from '../../forms/key-value-table/meta-data-control.component';
import { NgClass } from '@angular/common';
import { CdkAccordionItem } from '@angular/cdk/accordion';
import { HasModuleDirective } from '../../../packages/has-module.directive';
import { MatIcon } from '@angular/material/icon';
import { camelCase } from 'lodash';

@Component({
  selector: 'maporium-accordion-item',
  templateUrl: './maporium-accordion-item.component.html',
  styleUrls: ['./maporium-accordion-item.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    MetaDataControlComponent,
    ReactiveFormsModule,
    NgClass,
    CdkAccordionItem,
    HasModuleDirective,
    MatIcon
  ]
})
export class MaporiumAccordionItemComponent {
  @Input() sections: number = 0;
  @Input() title: string = '';

  private readonly EXPAND_STORAGE_KEY = 'maporium-accordion-item-expand';

  get isExpanded() {
    const storedState = localStorage.getItem(this.EXPAND_STORAGE_KEY);
    const storedStateObject = storedState ? JSON.parse(storedState) : {
      [camelCase(this.title)]: false
    };
    return storedStateObject[camelCase(this.title)];
  }

  onExpand(isExpanded: boolean) {
    const storedState = localStorage.getItem(this.EXPAND_STORAGE_KEY);
    const storedStateObject = storedState ? JSON.parse(storedState) : {};
    storedStateObject[camelCase(this.title)] = isExpanded;
    localStorage.setItem(this.EXPAND_STORAGE_KEY, JSON.stringify(storedStateObject));
  }
}
