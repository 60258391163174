import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  standalone: true,
  selector: 'input[autoSelect], textarea[autoSelect]'
})
export class AutoSelectInputDirective {
  @Input() autoSelectEnabled = true;
  private isFirstFocus = true;

  constructor(private el: ElementRef<HTMLInputElement | HTMLTextAreaElement>) {}

  @HostListener('focus', ['$event'])
  onSelectEvent(event: Event): void {
    if (!this.autoSelectEnabled || !this.isFirstFocus) {
      return;
    }

    this.isFirstFocus = false;
    (event.target as HTMLInputElement | HTMLTextAreaElement).select();
  }

  @HostListener('blur')
  onInputBlur(): void {
    this.isFirstFocus = true;
  }
}
