import {DefaultValues} from '../consts/default-values';
export interface NodePropertiesDto {
  expanded?: boolean;
  label?: string;
  textValign: 'top' | 'center' | 'bottom' | string;
  textHalign: 'left' | 'center' | 'right' | string;
  textMarginX: number | string;
  textMarginY: number | string;
  textFontSize: number | string;
  textRotation: number | string;
  textFontColor: string;
  imageOpacity: number | string;
  scale: number | string;
  opacity: number | string;
}

export const DefaultNodeProperties: NodePropertiesDto = {
  expanded: DefaultValues.node.properties.expanded,
  label: DefaultValues.node.properties.label,
  textFontColor: DefaultValues.node.properties.textFontColor,
  textHalign: DefaultValues.node.properties.textHalign as any,
  textValign: DefaultValues.node.properties.textValign as any,
  textMarginY: DefaultValues.node.properties.textMarginY,
  textMarginX: DefaultValues.node.properties.textMarginX,
  textRotation: DefaultValues.node.properties.textRotation,
  imageOpacity: DefaultValues.node.properties.imageOpacity,
  textFontSize: DefaultValues.node.properties.textFontSize,
  scale: DefaultValues.node.properties.scale,
  opacity: DefaultValues.node.properties.opacity
}
