import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatatifyDirective } from '../../shared/directives/datatify.directive';
import { OrderByPipe } from '../../shared/pipes/orderBy.pipe';
import { GraphNode } from '../../api/interfaces/graph-node.interface';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { MetaDataViewerComponent } from '../../shared/forms/meta-data-viewer/meta-data-viewer.componet';
import {
  TitleDescriptionInputComponent
} from '../../shared/forms/title-description-input/title-description-input.component';
import { CdkAccordion } from '@angular/cdk/accordion';
import {
  MaporiumAccordionItemComponent
} from '../../shared/components/maporium-accordion/maporium-accordion-item.component';
import { CommentsComponent } from '../../shared/components/comments/comments.component';
import { isInIframe } from '../../shared/helpers/maporium.validators';

@Component({
  selector: 'maporium-node-view',
  standalone: true,
  template: `
    <ng-container *ngIf="focusedNode">
      <maporium-title-description [isEditing]="false"
                                  [fields]="{title: focusedNode.name || '', description: focusedNode.description}">
      </maporium-title-description>

      <div class="mt-1">
        <cdk-accordion [multi]="true" class="accordion">
          <maporium-accordion-item [title]="'Properties'"
                                   [sections]="focusedNode.metadata?.length || 0">
            <maporium-meta-viewer [metadata]="focusedNode.metadata"></maporium-meta-viewer>
          </maporium-accordion-item>
          @if (!isInIframe()) {
            <maporium-accordion-item [title]="'Comments'" [sections]="commentsCount">
              <maporium-comments [entityId]="focusedNode!.id"
                                 (updated)="hasComments.emit($event)"
                                 (commentCount)="commentsCount = $event"
                                 [entityType]="'node'"
                                 [isEditing]="true"></maporium-comments>
            </maporium-accordion-item>
          }
        </cdk-accordion>
      </div>

    </ng-container>
  `,
  imports: [
    CommonModule,
    DatatifyDirective,
    OrderByPipe,
    MatTooltipModule,
    MatDividerModule,
    MetaDataViewerComponent,
    TitleDescriptionInputComponent,
    CdkAccordion,
    MaporiumAccordionItemComponent,
    CommentsComponent
  ]
})
export class NodeViewComponent {
  @Input() focusedNode: GraphNode | undefined;
  @Output() hasComments = new EventEmitter<boolean>();
  commentsCount = 0;

  get metadata(): any[]{
    return this.focusedNode?.metadata || [];
  }

  protected readonly isInIframe = isInIframe;
}
