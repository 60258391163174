import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MarkdownService {
  private readonly STORAGE_KEY = 'markdownEnabled';
  private markdownEnabled = signal(localStorage.getItem(this.STORAGE_KEY) === 'true' || false);


  get markdownEnabledSignal(): boolean {
    return this.markdownEnabled();
  }

  enableMarkdown() {
    localStorage.setItem(this.STORAGE_KEY, 'true');
    this.markdownEnabled.set(true);
  }

  disableMarkdown() {
    localStorage.setItem(this.STORAGE_KEY, 'false');
    this.markdownEnabled.set(false);
  }

  static convertToMarkdown(input: string) {
    if (input.trim() === '') {
      return '';
    }
    // Split the input string by lines
    const lines = input.split('\n');
    let result = '';
    let emptyLineCount = 0;

    lines.forEach((line, index) => {
      if (line.trim() === '') {
        emptyLineCount++;
      } else {
        if (emptyLineCount > 0) {
          result += ' '.repeat(2 * emptyLineCount) + '\\\n'.repeat(emptyLineCount);
          emptyLineCount = 0;
        }
        result += line;
        if (index < lines.length - 1) {
          result += '\n';
        }
      }
    });

    if (emptyLineCount > 0) {
      result += ' '.repeat(2 * emptyLineCount) + '\\\n'.repeat(emptyLineCount);
    }

    return result;
  }

  static cleanUpMarkdown(markdown: string) {
    return markdown
      .replace(/\\\s*<\/p>/g, '</p>')           // Remove backslash before closing </p> tags
      .replace(/\\\s*<\/li>/g, '</li>')         // Remove backslash before closing </li> tags
      .replace(/\\\s*<\/ul>/g, '</ul>')         // Remove backslash before closing </ul> tags
      .replace(/\\\s*<\/ol>/g, '</ol>')         // Remove backslash before closing </ol> tags
      .replace(/\\\s*<br>/g, '<br>')            // Remove backslash before <br> tags
      .replace(/\\\s*\n/g, '\n')                // Remove backslash before newlines
      .replace(/\n{2,}/g, '\n\n')               // Ensure no more than two consecutive newlines
      .replace(/\s*\\\s*$/g, '');            // Ensure no more than two consecutive newlines
  }


}
