import { ChangeDetectorRef, Component, effect, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CytoscapeService } from '../services/cy-services/cytoscape.service';
import { NodeService } from '../api/services/node.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MapListBottomsheetComponent } from '../dialogs/map-list.bottomsheet.component';
import { GraphMap, GraphNodeMapKey } from '../api/interfaces/graph-node.interface';
import { MapService } from '../api/services/map.service';
import { UserService } from '../api/services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LinkViewMode, SettingsService } from '../api/services/setting.service';
import { SettingsDialogComponent } from '../dialogs/settings-dialog.component';
import { catchError, finalize, forkJoin, throwError } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GptMapGeneratorDialogComponent } from '../dialogs/gpt-map-generator-dialog.component';
import { GptService } from '../api/services/gpt.service';
import { ShareMapDialogComponent } from '../dialogs/share-map.dialog';
import { User } from '../api/interfaces/user.interface';
import { ViewService } from '../api/services/view.service';
import { ViewsListBottomsheetComponent } from '../dialogs/views-list.bottomsheet.component';
import { TourListBottomsheetComponent } from '../dialogs/tour-list.bottomsheet.component';
import { ToursService } from '../api/services/tour.service';
import {
  DefaultNodeProperties,
  DefaultValues,
  MapStateDto,
  MODULE_NAMES,
  PACKAGE_NAMES
} from '@maporium-workspace/shared';
import { PackageService } from '../api/services/package.service';
import { ReleaseNotesDialogComponent } from '../dialogs/release-notes-dialog.componet';
import { environment } from '../../environments/environment';
import { BlockUIService } from 'ng-block-ui';
import { CreateNewTourDialogComponent } from '../dialogs/create-new-tour-dialog.component';
import { CreateNewViewDialogComponent } from '../dialogs/create-new-view-dialog.component';
import { SavedView } from '../api/interfaces/saved-view';
import { MapTour } from '../api/interfaces/map-tour';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CreateNewMapDialogComponent } from '../dialogs/create-new-map-dialog.component';
import { MapDialogsService } from '../dialogs/map-dialogs.service';
import { DEFAULT_MAP_STATE, MapStateService } from '../api/services/map-state.service';
import { CreateNewMapStateDialogComponent } from '../dialogs/create-new-map-state-dialog.component';
import { StateListBottomsheetComponent } from '../dialogs/state-list.bottomsheet.component';
import {
  invalidateGeneralPanZoom,
  invalidatePanZoomForCollapsedNode
} from '../../cytoscape-extensions/pan-zoom-storage';
import { isInIframe } from '../shared/helpers/maporium.validators';
import { AppWideStateService } from '../services/app-state/app-wide-state.service';
import { EmbedDialogComponent } from '../dialogs/embed-dialog.component';

@Component({
  selector: 'maporium-toolbar',
  styleUrls: ['./top-toolbar.component.scss'],
  templateUrl: './top-toolbar.component.html',
})

export class TopToolbarComponent implements OnInit {
  public currentMap: GraphMap | undefined;
  public currentUser: User | undefined;
  public maps: GraphMap[] = [];
  public showGptSave = false;
  public isEditingMapName = false;
  public mapEditForm = new FormGroup({
    name: new FormControl('', Validators.required),
  });

  readonly MODULE_NAMES = MODULE_NAMES;

  public isLoggedIn = false;
  private isLoading = false;
  public buildNumber = {
    build: '',
    version: '',
  };
  public isSidebarOpen = false;
  showVersionInfo = false;
  showBuildInfo = environment.showBuildInfo;
  public servicePlan = '';
  tours: any[] = [];
  views: SavedView[] = [];
  mapStates: MapStateDto[] = [];
  defaultState = DEFAULT_MAP_STATE;
  packagenames = PACKAGE_NAMES;
  isIframe = isInIframe();
  readModeAppState = false;
  constructor(private cyService: CytoscapeService,
              private nodeService: NodeService,
              private matBottomSheet: MatBottomSheet,
              private mapService: MapService,
              private settingsService: SettingsService,
              private tourService: ToursService,
              private viewService: ViewService,
              private blockUiService: BlockUIService,
              private gptService: GptService,
              private cdr: ChangeDetectorRef,
              private userService: UserService,
              private router: Router,
              private packageService: PackageService,
              private toursService: ToursService,
              private snackBar: MatSnackBar,
              private route: ActivatedRoute,
              private mapDialogsService: MapDialogsService,
              private mapStateService: MapStateService,
              private appWideStateService: AppWideStateService,
              private dialog: MatDialog) {
    effect(() => {
      this.readModeAppState = this.appWideStateService.readMode();
    });

    effect(() => {
      const isIframe = isInIframe();
      const canvasLoaded = this.appWideStateService.canvasLoaded();
      if (isIframe && canvasLoaded) {
        setTimeout(() => {
          this.centerCanvas();
        }, 400);
      }
    });
  }
  @HostListener('document:click', ['$event'])
  handleDocumentClick(event: MouseEvent) {
    if (!this.isEditingMapName) {
      return;
    }
    const target = event.target as HTMLElement;
    if (!target.className.includes('map-name-container') && !target.className.includes('app-text-input')) {
      this.stopMapEdit();
    }
  }

  onMapNameKeydown(event: KeyboardEvent) {
    const newMapName = this.mapEditForm.getRawValue();
    if (newMapName?.name === this.currentMap?.name && (event.key === 'Return' || event.key === 'Enter')) {
      this.isEditingMapName = false;
      //@ts-ignore
      event?.target?.blur();
      return;
    }

    if (this.mapEditForm.invalid) return;

    if (event.key === 'Enter' || event.key === 'Return') {
      this.stopMapEdit(event);
    }
  }

  ngOnInit(): void {
    this.router.events.subscribe(() => {
      // Check if we are on dashboard
      if (this.router.url.includes('/dashboard')) {
        if (!this.isLoading) {
          this.handleMapsLoad();
          if (!this.isIframe) {
            this.loadToursAndViews();
          }
        }
      } else if (this.router.url === '/login') {
        this.isLoggedIn = false;
      }
    });
    this.mapService.mapChangedSubject.subscribe((map) => {
      // @ts-ignore
      this.currentMap = map?.map || map;
      this.mapEditForm.setValue({name: this.currentMap?.name || ''});
    });

    this.mapService.mapNameChangedSubject.subscribe((name) => {
      if (!this.currentMap) return;
      this.currentMap.name = name;
      this.mapEditForm.setValue({ name: name });
    });

    this.packageService.userPackageStream()
      .subscribe(() => {
        this.cdr.detectChanges();
      });
  }

  get isBlocking() {
    return this.blockUiService.isActive('UI');
  }

  get canManageViews() {
    return this.packageService.hasModule(MODULE_NAMES.VIEW_MANAGEMENT);
  }

  get canManageTours() {
    return this.packageService.hasModule(MODULE_NAMES.TOUR_MANAGEMENT);
  }

  public loadToursAndViews(emitMenuChange = true) {
    if (!this.packageService.hasModule(MODULE_NAMES.TOUR_USAGE) && !isInIframe()) {
      return;
    }

    const mapId = this.mapService.getCurrentSelectedMapFromStore().id;
    if (!mapId) {
      return;
    }
    if (this.isLoading && !isInIframe()) return;


    this.isLoading = true;
    const publicOrPrivateTours = isInIframe() ? this.toursService.getAllPublic(mapId) : this.toursService.getAll(mapId);
    const publicOrPrivateViews = isInIframe() ? this.viewService.getAllPublic(mapId) : this.viewService.getAll(mapId);

    forkJoin([publicOrPrivateTours, publicOrPrivateViews])
      .subscribe({
        next: ([tours, views]) => {
          this.tours = tours;
          this.views = views;
          if (emitMenuChange) {
            CytoscapeService.menuChangedSubject.next();
          }
        }, complete: () => this.isLoading = false
      });
  }

  getToolTipForMap() {
    if (!this.currentMap) return '';
    if (this.currentMap.isReadonly) {
      return `Perform some action on the current smap, shared with you by ${this.currentMap?.owner?.name + ' ' + this.currentMap?.owner?.lastName}`;
    }
    const sharedWithCount = this.currentMap?.sharedWith?.length || 0;
    return sharedWithCount === 0 ? 'Perform some action on the current smap, owned by you' :
      `Perform some action on the current smap, owned by you and shared with ${sharedWithCount} ${sharedWithCount === 1 ? 'viewer' : 'viewers'}`;
  }

  openReleaseNotes() {
    this.dialog.open(ReleaseNotesDialogComponent, {
      width: '35%',
    });
  }

  stopMapEdit(event?: any) {
    if (this.mapEditForm.invalid) return;
    this.isEditingMapName = false;
    const newMapName = this.mapEditForm.getRawValue();
    if (newMapName?.name === this.currentMap?.name) {
      return;
    }
    if (newMapName && this.currentMap) {
      const payload = {
        ...this.currentMap,
      }
      payload.name = newMapName?.name || '';
      if (!this.isLoading) {
        this.isLoading = true;
        this.mapService.update(payload)
          .pipe(finalize(() => this.isLoading = false))
          .subscribe((res) => {
            this.mapService.setCurrentSelectedMapToStore(res);
            this.mapService.mapNameChangedSubject.next(res.name);
          });
      }

    }

    if (event) {
      event.target.blur();
    }
  }

  createTour() {
    if (this.currentMap?.isReadonly) {
      return;
    }
    const ref = this.dialog.open(CreateNewTourDialogComponent);
    ref.afterClosed().subscribe((tour: any) => {
      if (tour) {
        const mapId = this.mapService.getCurrentSelectedMapFromStore().id;
        if (!mapId) {
          return;
        }
        (isInIframe() ? this.toursService.getAllPublic(mapId) : this.toursService.getAll(mapId)).subscribe();
        CytoscapeService.menuChangedSubject.next();
      }
    });
  }

  createView() {
    if (this.currentMap?.isReadonly) {
      return;
    }
    const ref = this.dialog.open(CreateNewViewDialogComponent, {width: '35%', autoFocus: 'input'});
    ref.afterClosed().subscribe((view: SavedView) => {
      if (view) {
        CytoscapeService.menuChangedSubject.next();
      }
    });
  }

  createNewMap() {
    const ref = this.dialog.open(CreateNewMapDialogComponent, {width: '35%', autoFocus: 'input'});
    ref.afterClosed().subscribe((map: GraphMap) => {
      const newMap = {...map, isNew: true};
      if (map) {
        this.tourService.activateTourSubject.next(null);
        this.selectMap(newMap);
      }
    });
  }

  async startTour(tour: MapTour) {
    if (tour.steps.length === 0) {
      this.snackBar.open('This tour has no steps', 'Dismiss', {duration: 60000});
    } else {
      await this.tourService.playTour(tour);
    }
  }

  async startView(view: SavedView) {
    await this.selectView(view);
  }


  handleGpt(addTo: 'map' | 'new') {
    this.nodeService.addGptToSubject.next(addTo);
  }

  toggleEdges(type: LinkViewMode) {
    this.settingsService.setLinkViewMode(type);
    this.settingsService.linkViewModeSignal.set(type);
  }

  get currentEdgeView() {
    return this.settingsService.linkViewMode;
  }

  toggleBuildNumber() {
    if (isInIframe()) {
      return;
    }
    this.showVersionInfo = !this.showVersionInfo;
    localStorage.setItem('buildNumber', this.showVersionInfo.toString());
  }

  public handleMapsLoad() {
    this.isLoading = true;
    const mapId = this.route.snapshot.queryParamMap.get('mapId');
    const sharedMapId = localStorage.getItem('sharedMapId');
    if (this.readModeAppState) {
      if (!mapId) {
        this.router.navigate(['/404']);
        return;
      }
      this.mapService.findPublicMap(mapId as string)
        .pipe(
          catchError((err) => {
            console.log(err);
            this.isLoading = false;
            this.router.navigate(['/404']);
            return throwError(err);
          }),
        )
        .subscribe((map) => {
          this.currentMap = map;
          this.mapService.mapChangedSubject.next(map);
          this.mapService.setCurrentSelectedMapToStore(map);
          this.initAll();
          this.router.navigate([], {
            relativeTo: this.route,
            queryParamsHandling: isInIframe() ? 'preserve' : undefined
          });
        });
    } else {
      this.loadMapForUser(mapId as string, sharedMapId as string);
    }
  }

  reloadMapList() {
    this.isLoading = true;
    this.mapService.findAllForUser(this.userService.getCurrentUserFromStorage().id)
      .pipe(
        catchError((err) => {
          this.isLoading = false;
          return throwError(err);
        })
      )
      .subscribe((maps) => {
        this.isLoading = false;
        // @ts-ignore
        this.maps = maps;
      });
  }

  openStateMenu() {
    this.loadMapStates();
  }
  loadMapStates() {
    const mapId = this.mapService.getCurrentSelectedMapFromStore().id;
    if (!mapId || this.readModeAppState) {
      return;
    }
    this.mapStateService.findAllForMap(mapId).subscribe((states) => {
      this.mapStates = states;
    });
  }

  selectMapState(state: MapStateDto) {
    this.mapStateService.selectState(state);
  }

  private async initAll() {
    const isIframe = isInIframe();
    if (!isIframe) {
      await this.packageService.init();
      this.currentUser = this.userService.getCurrentUserFromStorage();
      this.mapService.openMapSheetSubject.subscribe(() => this.selectMap());
      this.nodeService.createNodeSubject.subscribe((pos) => {
        this.createDefaultNode(pos);
      });
      this.settingsService.getGeneralInfo().subscribe((info) => {
        this.buildNumber = {
          build: info.buildNumber,
          version: environment.version
        };
        this.showVersionInfo = localStorage.getItem('buildNumber') === 'true' || false;
      });
      this.packageService.loadUserPackage()
        .subscribe((res) => {
          this.servicePlan = res?.package.name || '';
        });
    }
    this.isSidebarOpen = this.settingsService.getLocalStorageSettings()?.isSidebarOpen || false;
    this.settingsService.localStorageSettingsChanged.subscribe((settings) => {
      if (settings.isSidebarOpen !== undefined) {
        this.isSidebarOpen = settings.isSidebarOpen;
      }
    });
    CytoscapeService.menuChangedSubject.subscribe(() => {
      this.loadToursAndViews(false);
    });
    this.settingsService.initLocalLinkViewMode();
  }

  openGptDialog() {
    const ref = this.dialog.open(GptMapGeneratorDialogComponent, {
      width: '35%',
      hasBackdrop: false,
      data: {}
    });
    ref.afterClosed().subscribe((result) => {
      if (result) {
        this.gptService.generatedSubject.next(result);
        this.showGptSave = true;
      }
    });
  }

  cancelGptGeneratedMap() {
    location.reload();
  }

  openSettings() {
    this.dialog.open(SettingsDialogComponent, {
      width: '35%',
    });
  }

  toggleSidebar() {
    this.settingsService.toggleSidebar();
    this.isSidebarOpen = this.settingsService.getLocalStorageSettings()?.isSidebarOpen || false;
  }

  createDefaultNode(pos?: { x: number, y: number }) {
    if (pos) {
      // @ts-ignore
      this.createNodeAtPosition('', DefaultValues.node.color, 'ELLIPSE', pos);
    } else {
      // @ts-ignore
      this.createNode('', DefaultValues.node.color, 'ELLIPSE', this.getCyBoundingBoxCenter());
    }
  }

  createNode(name: string, color: string = DefaultValues.node.color, type: string, position: {
    x: number,
    y: number
  }, metadata?: any) {
    const cy = this.cyService.cy;
    if (!cy) {
      return;
    }

    // @ts-ignore
    const payload = {
      name,
      color,
      type,
      x: 0,
      y: 0,
      metadata,
      children: [],
      properties: DefaultNodeProperties,
      map: this.currentMap
    };
    this.nodeService.create(payload).subscribe((res) => {
      this.nodeService.loadSubject.next(res);
    });

  }

  createNodeAtPosition(name: string,
                       color: string = DefaultValues.node.color,
                       type: string,
                       position: { x: number; y: number },
                       metadata?: any) {

    const cy = this.cyService.cy;
    if (!cy) {
      return;
    }
    const payload = {
      name,
      color,
      type,
      x: position.x,
      y: position.y,
      metadata,
      properties: DefaultNodeProperties,
      children: [],
      map: this.currentMap
    };

    this.nodeService.create(payload).subscribe((res) => {
      this.nodeService.loadSubject.next(res);
    });
  }

  shareMap() {
    this.dialog.open(ShareMapDialogComponent, {
      width: '35%',
      data: {
        // @ts-ignore
        mapId: this.currentMap.id
      }
    });
  }

  embedMap() {
    const ref = this.dialog.open(EmbedDialogComponent, {
      width: '50%',
      autoFocus: false,
      data: {
        // @ts-ignore
        mapId: this.currentMap.id,
        isPublic: this.currentMap?.isPublic || false
      }
    });
    ref.afterClosed().subscribe((res) => {
      if (res?.published) {
        this.currentMap!.isPublic = res.published;
        this.mapService.setCurrentSelectedMapToStore(this.currentMap!);
      } else if (res) {
        this.currentMap!.isPublic = false;
        this.mapService.setCurrentSelectedMapToStore(this.currentMap!);
      }
    });
  }

  createMapState() {
    const ref = this.dialog.open(CreateNewMapStateDialogComponent, {
      width: '35%',
    });

    ref.afterClosed().subscribe((state: MapStateDto) => {
      if (state) {
       this.selectMapState(state);
      }
    });
  }

  get currentNotDefaultMapState() {
    const currentMapState = this.mapStateService.getCurrentLocalState();
    if (currentMapState?.id === undefined) {
      return null;
    }
    return currentMapState?.name;
  }

  get currentNotDefaultMapStateId() {
    const currentMapState = this.mapStateService.getCurrentLocalState();
    if (currentMapState?.id === undefined) {
      return null;
    }
    return currentMapState?.id;
  }

  centerCanvas() {
    invalidateGeneralPanZoom();
    invalidatePanZoomForCollapsedNode();
    this.cyService.centerCanvas();
  }


  selectMap(map?: GraphMap) {
    if (map) {
      //@ts-ignore
      if (map?.isNew) {
        this.maps.push(map);
      }
      invalidateGeneralPanZoom();
      invalidatePanZoomForCollapsedNode();
      this.mapService.setCurrentSelectedMapToStore(map);
      this.mapService.mapChangedSubject.next(map);
      this.tourService.activateTourSubject.next(null);
      this.loadToursAndViews();
      this.settingsService.initLocalLinkViewMode();
    } else {
      const ref = this.matBottomSheet.open(MapListBottomsheetComponent);
      ref.afterDismissed().subscribe((map: MapFromList) => {
        if (map) {
          invalidateGeneralPanZoom();
          invalidatePanZoomForCollapsedNode();
          if (map?.isNew) {
            this.maps.push(map);
          } else {
            // Update map in list
            const index = this.maps.findIndex((m) => m.id === map.id);
            this.maps[index] = map;
          }

          this.mapService.setCurrentSelectedMapToStore(map);
          this.mapService.mapChangedSubject.next(map);
          this.tourService.activateTourSubject.next(null);
          this.loadToursAndViews();
          this.settingsService.initLocalLinkViewMode();
        }
      });
    }
  }

  openStateListSheet() {
    const ref = this.matBottomSheet.open(StateListBottomsheetComponent);

    ref.afterDismissed().subscribe(async (state: any) => {
      if (state) {
        this.selectMapState(state);
      }
    });

  }

  async selectTour(tour?: MapTour) {
    if (this.currentMap?.isReadonly) {
      return;
    }
    if (tour) {
      await this.tourService.playTour(tour);
    } else {
      const ref = this.matBottomSheet.open(TourListBottomsheetComponent);
      ref.afterDismissed().subscribe(async (tour: any) => {
        if (tour) {
          await this.tourService.playTour(tour);
        }
        this.loadToursAndViews();
      });
    }
  }

  selectView(view?: SavedView) {
    if (this.currentMap?.isReadonly && !view) {
      return;
    }
    if (view) {
      this.viewService.playView(view);
    } else {
      const ref = this.matBottomSheet.open(ViewsListBottomsheetComponent);
      ref.afterDismissed().subscribe((view: any) => {
        if (view) {
          this.viewService.playView(view);
        }
        this.loadToursAndViews();
      });
    }
  }

  private getCyBoundingBoxCenter() {
    const cy = this.cyService.cy;
    if (!cy) {
      return {
        x: 0,
        y: 0
      };
    }
    const bb = cy.elements().boundingBox();
    return {
      x: (bb.x1 + bb.x2) / 2,
      y: (bb.y1 + bb.y2) / 2
    };
  }

  sendMail() {
    window.open('mailto:support@maporium.com', '_blank');
  }

  visitWebsite() {
    window.open('https://www.maporium.com', '_blank');
  }

  editMap() {
    if (!this.currentMap) return;
    if (this.currentMap.isReadonly) return;

    this.mapDialogsService.edit(this.currentMap).subscribe((m: GraphMap) => {
      if (m) {
        // this.bottomSheetRef.dismiss(map);
        this.maps = this.maps.map((m) => {
          if (m.id === m.id) {
            return m;
          }
          return m;
        });
        if(this.mapService.getCurrentSelectedMapFromStore()?.id === m.id) {
          this.mapService.mapNameChangedSubject.next(m.name);
          this.mapService.setCurrentSelectedMapToStore(m);
        }
      }
    });
  }

  duplicateMap() {
    if (!this.currentMap) return;
    if (this.currentMap.isReadonly) return;

    this.mapDialogsService.duplicate(this.currentMap).subscribe((m: GraphMap) => {
      if (m) {
        this.tourService.activateTourSubject.next(null);
      }
    });
  }

  deleteMapConfirm() {
    if (!this.currentMap) return;
    if (this.currentMap.isReadonly) return;
    this.mapDialogsService.deleteConfirmation(this.currentMap).subscribe((res) => {
      if (res) {
        this.deleteMap();
      }
    });
  }

  deleteMap() {
    if (!this.currentMap) return;
    if (this.currentMap.isReadonly) return;
    this.mapService.delete(this.currentMap.id).subscribe(() => {
      if (!this.currentMap) return;
      const mapId = this.currentMap.id;
      const storedMap = this.mapService.getCurrentSelectedMapFromStore();

      if (storedMap && storedMap.id === mapId) {
        this.mapService.removeCurrentSelectedMapFromStore();
      }
      const mapsWithoutDeleted = this.maps.filter((m) => m.id !== mapId);
      this.maps = mapsWithoutDeleted;
      if (mapsWithoutDeleted.length > 0) {
        this.mapService.mapChangedSubject.next(mapsWithoutDeleted[0]);
        this.mapService.setCurrentSelectedMapToStore(mapsWithoutDeleted[0]);
      } else {
        this.mapService.mapChangedSubject.next(undefined);
        this.mapService.setCurrentSelectedMapToStore(undefined);
      }
    });
  }

  removeMapFromSharedWithMe() {
    if (!this.currentMap) return;
    const mapId = this.currentMap.id;
    this.mapService.unshareMap(mapId).subscribe(() => {
      const storedMap = this.mapService.getCurrentSelectedMapFromStore();

      if (storedMap && storedMap.id === mapId) {
        this.mapService.removeCurrentSelectedMapFromStore();
      }

      const mapsWithoutDeleted = this.maps.filter((m) => m.id !== mapId);
      this.maps = mapsWithoutDeleted;
      if (mapsWithoutDeleted.length > 0) {
        this.mapService.mapChangedSubject.next(mapsWithoutDeleted[0]);
        this.mapService.setCurrentSelectedMapToStore(mapsWithoutDeleted[0]);
      } else {
        this.mapService.mapChangedSubject.next(undefined);
        this.mapService.setCurrentSelectedMapToStore(undefined);
      }
    });
  }

  exportMap() {
    if (!this.currentMap) return;
    if (this.currentMap.isReadonly) return;
    this.mapDialogsService.download(this.currentMap);
  }

  private loadMapForUser(mapId?: string, sharedMapId?: string) {
    this.mapService.findAllForUser(this.userService.getCurrentUserFromStorage().id)
      .pipe(
        catchError((err) => {
          this.isLoading = false;
          return throwError(err);
        })
      )
      .subscribe((maps) => {
        this.isLoggedIn = true;
        // @ts-ignore
        this.maps = maps;
        const storedMap = localStorage.getItem(GraphNodeMapKey);
        const hasSelectedMapsInStorage = storedMap !== null && storedMap !== undefined && storedMap !== 'undefined';
        if (mapId || sharedMapId) {
          const map = this.maps.find((m) => m.id === (mapId || sharedMapId));
          if (map) {

            this.currentMap = map;
            this.mapService.mapChangedSubject.next(map);
            this.mapService.setCurrentSelectedMapToStore(map);
            this.router.navigate([], {
              relativeTo: this.route
            });
          }

        }

        if (this.maps.length > 0 && !hasSelectedMapsInStorage && !mapId && !sharedMapId) {
          this.mapService.mapChangedSubject.next(this.currentMap);
          this.mapService.setCurrentSelectedMapToStore(this.currentMap);
        }
        if (hasSelectedMapsInStorage && !mapId && !sharedMapId) {
          const map = JSON.parse(storedMap || '{}');
          this.mapService.mapChangedSubject.next(map);
          this.mapService.setCurrentSelectedMapToStore(map);
        }
        this.isLoading = false;
        localStorage.removeItem('sharedMapId');
        this.initAll();
      });
  }

  protected readonly isInIframe = isInIframe;
}

interface MapFromList extends GraphMap {
  isNew?: boolean;
  isDeleted?: boolean;
}
