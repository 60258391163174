import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { User } from '../api/interfaces/user.interface';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { map, Observable, startWith } from 'rxjs';
import { UserService } from '../api/services/user.service';
import { MapService } from '../api/services/map.service';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'maporium-share-map-dialog',
  styles: [`
    ::ng-deep {

    .share-map-form {
      --mdc-theme-primary: #000000;
      .mdc-floating-label {
        color:  #000000 !important;
      }
    }

    }
  `],
  template: `
    <div class="w-100 d-flex justify-content-between align-items-center dialog-header">
      <h2 class="align-items-center d-flex m-0 p-0"><span class="material-symbols-rounded me-2">person_add</span>
        Share Smap
      </h2>
      <mat-icon class="material-symbols-rounded close-button"  mat-dialog-close matTooltip="Close the panel">
        close
      </mat-icon>
    </div>
    <div mat-dialog-content class="p-3 px-4">
      <form class="share-map-form">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Users</mat-label>
          <mat-chip-grid #chipGrid aria-label="User selection">
            <mat-chip-row *ngFor="let email of emails" (removed)="remove(email)">
              {{getUserName(email)}} <{{email}}>
              <button matChipRemove [attr.aria-label]="'remove ' + email">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
          </mat-chip-grid>
          <input #userInput
                 [formControl]="userCtrl"
                 [matChipInputFor]="chipGrid"
                 [matAutocomplete]="auto"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 (matChipInputTokenEnd)="add($event)"/>
          <mat-autocomplete #auto="matAutocomplete"
                            (optionSelected)="selected($event)">
            <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
              {{user}}
            </mat-option>
          </mat-autocomplete>

        </mat-form-field>
      </form>
      <mat-error *ngIf="userCtrl.hasError('invalidAutocompleteObject') && userCtrl.dirty">One of the Emails is not
        recognized. Click one of the autocomplete options.
      </mat-error>

    </div>
    <div mat-dialog-actions class="d-flex justify-content-center" >
      <button mat-button (click)="update()" color="accent" *ngIf="hasChanges">Share</button>
    </div>
  `,
  standalone: true,
  imports: [
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatTooltipModule,
    MatInputModule,
    MatTableModule,
    CommonModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatAutocompleteModule
  ]
})
export class ShareMapDialogComponent {
  separatorKeysCodes: number[] = [ENTER, COMMA];
  userCtrl = new FormControl('');
  filteredUsers: Observable<string[]>;
  emails: string[] = [];
  allUserEmails: string[] = [];
  allAvailableUsers: User[] = [];
  sharedWithUsers: User[] = [];
  hasChanges = false;

  //@ts-ignore
  @ViewChild('userInput') userInput: ElementRef<HTMLInputElement>;

  constructor(private userService: UserService,
              private mapService: MapService,
              private dialogRef: MatDialogRef<ShareMapDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private data: { mapId: string }) {
    this.userService.getAvailableUsersForMapSharing(data.mapId)
      .subscribe((users: User[]) => {
        this.allUserEmails = users.map(user => user.email) as string[];
        this.allAvailableUsers = users;
      });

    this.mapService.findByIdWithRelation(data.mapId).subscribe((map) => {
      if(map.sharedWith){
        this.emails = map.sharedWith.map(user => user.email) as string[];
        this.sharedWithUsers = map.sharedWith;
      }
    });

    this.filteredUsers = this.userCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) => (fruit ? this._filter(fruit) : this.allUserEmails.slice())),
    );
  }

  add(event: MatChipInputEvent): void {
    if (this.userCtrl.invalid) return;
    const value = (event.value || '').trim();

    if (value) {
      const index = this.allUserEmails.indexOf(value);
      if (index === -1) return;
      this.emails.push(value);
      //remove from allUserEmails
      this.sharedWithUsers.push(this.allAvailableUsers[index]);
      if (index >= 0) {
        this.allUserEmails.splice(index, 1);
      }
    }

    // Clear the input value
    event.chipInput.clear();

    this.userCtrl.setValue(null);
    this.hasChanges = true;
  }

  //TODO: rework this
  getUserName(email: string): string {
    if (!email) return '';
    const user = this.sharedWithUsers.find(user => user.email === email);
    if (!user) return '';
    return user?.name as string;
  }

  remove(email: string): void {
    const index = this.emails.indexOf(email);

    if (index >= 0) {
      this.allUserEmails.push(email);
      this.allAvailableUsers.push(this.sharedWithUsers[index]);
      this.emails.splice(index, 1);
      this.sharedWithUsers.splice(index, 1);
    }

    this.hasChanges = true;

  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.emails.push(event.option.viewValue);
    //remove from allUserEmails
    const index = this.allUserEmails.indexOf(event.option.viewValue);
    this.sharedWithUsers.push(this.allAvailableUsers[index]);
    if (index >= 0) {
      this.allUserEmails.splice(index, 1);
    }
    this.userInput.nativeElement.value = '';
    this.userCtrl.setValue(null);
    this.hasChanges = true;

  }

  update() {
    const userIds = [...this.allAvailableUsers, ...this.sharedWithUsers].filter(user => this.emails.includes(user.email as string)).map(user => user.id);
    const unique = [...new Set(userIds)];
    this.mapService.shareMap(this.data.mapId, unique).subscribe(() => this.dialogRef.close());
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allUserEmails.filter(fruit => fruit.toLowerCase().includes(filterValue));
  }
}
