import { Injectable } from '@angular/core';
import { OpenaiResource } from '../resource/openai.resource';
import { MetaData } from '../interfaces/metadata';
import { Observable } from 'rxjs';
import { ExtendMetadataDto } from '../interfaces/extend-metadata.dto';

@Injectable({ providedIn: 'root' })
export class OpenaiService {
  constructor(private resource: OpenaiResource) {
  }

  extendMetadata(dto: ExtendMetadataDto): Observable<MetaData[]> {
    return this.resource.extendMetadata(dto);
  }

}
