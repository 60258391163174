import { Injectable, signal } from '@angular/core';
import { SettingsService } from '../api/services/setting.service';

@Injectable({providedIn: 'root'})
export class SidebarService {
  private editModeSignal = signal(this.loadFromLocalStorage());

  constructor(private settingsService: SettingsService) {
  }

  get editMode() {
    return this.editModeSignal();
  }

  toggleEditMode() {
    this.editModeSignal.set(!this.editModeSignal());
  }

  enableEditMode() {
    this.editModeSignal.set(true);
    this.setLocalStorageSettings(true);
  }

  disableEditMode() {
    this.editModeSignal.set(false);
    this.setLocalStorageSettings(false);
  }

  private loadFromLocalStorage(): boolean {
    return this.settingsService.getLocalStorageSettings().isEditMode || false;
  }

  private setLocalStorageSettings(isEditMode: boolean) {
    const settings = this.settingsService.getLocalStorageSettings();
    settings.isEditMode = isEditMode;
    this.settingsService.setLocalStorageSettings(settings);
  }
}
