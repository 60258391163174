import {Component, ElementRef, ViewChild} from '@angular/core';
import {MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {GeneralInfo} from '../api/interfaces/general-info';
import {SettingsService} from '../api/services/setting.service';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {MapService} from '../api/services/map.service';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';

@Component({
  selector: 'maporium-import-map-dialog',
  template: `
    <div class="w-100 d-flex justify-content-between align-items-center dialog-header">
      <h2 class="align-items-center d-flex m-0 p-0"><span class="material-symbols-rounded me-2">publish</span>
        Import Smap
      </h2>
      <mat-icon class="material-symbols-rounded close-button"  mat-dialog-close matTooltip="Close the panel">
        close
      </mat-icon>
    </div>
    <div mat-dialog-content>
        <form>
            <input #fileInput type="file" name="file" id="file" accept=".zip, .smap">
        </form>
    </div>
    <div mat-dialog-actions class="d-flex align-items-center justify-content-evenly">
      <button mat-button mat-dialog-close>Cancel</button>
      <button mat-button color="accent" (click)="importMap()" class="import-btn">Import</button>
    </div>
  `,
  imports: [
    MatDialogModule,
    CommonModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatTooltipModule,

    MatIconModule
  ],
  styles: [
    `
    `
  ],
  standalone: true
})
export class ImportMapDialogComponent {
  @ViewChild('fileInput') fileInputRef!: ElementRef<HTMLInputElement>;
  constructor(private mapService: MapService, private matDialogRef: MatDialogRef<ImportMapDialogComponent>) {
  }

  importMap() {
    if(!this.fileInputRef.nativeElement.files) return;
    const file = this.fileInputRef.nativeElement.files[0];
    this.mapService.importMapFromZip(file).subscribe(() => {
      this.matDialogRef.close();
    });
  }


}
