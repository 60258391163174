export const DefaultValues = {
  highlightedElementOpacity: 0.5,
  weightMultiplier: 0.8,
  brightnessMultiplier: 3,
  ghostLinkSourceColor: 'rgba(0,0,0,0.5)',
  ghostLinkTargetColor: 'rgba(0,0,0,0.5)',
  doubleTapDelay: 350,
  node: {
    containerDarken: 1,
    nodeOverlayOpacity: 0.5,
    nodeDropOverlayOpacity: 0.3,
    nodeDragOpacity: 0.3,
    nodeHighlightColor: '#000000',
    containerOpacity: 0,
    color: '#777777',
    borderColor: '#000000',
    type: 'ELLIPSE',
    hasBorder: true,
    collapsed: false,
    weight: 2,
    x: 0,
    y: 0,
    properties: {
      expanded: true,
      label: 'name',
      textFontColor: '#000000',
      textHalign: 'center',
      textValign: 'bottom',
      textMarginY: 1,
      textMarginX: 0,
      textRotation: 0,
      imageOpacity: 1,
      opacity: 0.2,
      textFontSize: 5,
      scale: 1
    }
  },
  link: {
    color: '#777777',
    label: 'name',
    type: 'BEZIER',
    direction: 'directed',
    opacity: 1,
    shape: 'solid',
    textFontSize: 5,
    textMarginY: 5,
    textMarginX: 5,
    labelOffset: 5,
    weight: 1
  }
}
