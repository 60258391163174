import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { BaseCustomInputComponent } from '../base-custom-input.component';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { TruncatePipe } from '../../pipes/truncate.pipe';
import { MatListModule } from '@angular/material/list';


@Component({
  selector: 'app-select-input',
  standalone: true,
  template: `
    <div class="row align-items-center no-gutter mt-2 select-input">
      <div class="col-4 pe-1" *ngIf="label && !isIcon">
        {{label}}
      </div>
      <div class="col-2 justify-content-center align-items-center d-flex"
           *ngIf="label && isIcon"
           [matTooltip]="placeholder">
        <span class="material-symbols-rounded"><label>{{label}}</label></span>
      </div>
      <div class="dropdown-wrapper" [ngClass]="{'col-8': label !== undefined && label !== '' && !isIcon, 'col-10': label !== '' && isIcon}">
        <div class="custom-dropdown d-flex align-items-center justify-content-between"
             [matMenuTriggerFor]="menu"
             #menuTrigger
             (keydown)="handleKeydown($event)"
             (click)="setMenuWidth()"
             tabindex="0">
          <div *ngIf="selectedOptionValue as selectedOption" class="d-flex justify-content-start align-items-center value-container">
            <span class="material-symbols-rounded selected-icon ms-1" *ngIf="selectedOption.icon">  {{selectedOption.icon }}</span>
            <span class="ms-2 selected-input">{{ selectedOption.value }}</span>
          </div>
          <div *ngIf="!selectedOption && hasBlank">
            <span class="ms-2 selected-input">{{blankValue}}</span>
          </div>
          <span class="material-symbols-rounded">arrow_drop_down</span>
          <mat-menu #menu="matMenu" class="select-input-menu">
            <ng-container *ngIf="hasBlank">
              <button mat-menu-item (click)="selectBlank()">
                <mat-icon class="empty-icon"></mat-icon>
                <span>{{blankValue}}</span>
              </button>
              <mat-divider *ngIf="options.length>0"></mat-divider>
            </ng-container>
            <button mat-menu-item *ngFor="let option of options;"
                    (click)="selectOption(option)"
                    [class.truncate]="truncate"
                    class="justify-content-start">
              <ng-container *ngIf="!noIcons">
                <mat-icon fontSet="material-symbols-outlined"
                          *ngIf="option.icon"
                          [fontIcon]="option.icon"> {{ option.icon }}
                </mat-icon>
                <mat-icon class="empty-icon" *ngIf="!option.icon"></mat-icon>
              </ng-container>
              <span [matTooltip]="option.value">{{option.value}}</span>
            </button>
          </mat-menu>
        </div>
        <select [formControl]="formControl" hidden>
          <option *ngFor="let option of options; " [value]="option.key">{{ option.value }}</option>
        </select>
      </div>
    </div>

  `,
  styleUrls: ['./select-input.component.scss'],
  imports: [FormsModule, ReactiveFormsModule, CommonModule, MatTooltipModule, MatMenuModule, MatIconModule, TruncatePipe, MatListModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectInputComponent),
      multi: true,
    },
  ],
})
export class SelectInputComponent extends BaseCustomInputComponent implements OnInit, OnChanges{
  @Input() label = '';
  @Input() placeholder = '';
  @Input() value: string | number | undefined = '';
  @Input() isIcon = false;
  @Input() truncate = false;
  @Input() metadata: any;
  @Input() noIcons = false;
  @Input() hasBlank = false;
  @Input() blankValue = 'blank';
  @Input() options: {key: string, value: string, icon?: string}[] = [];
  @ViewChild('menuTrigger') trigger: ElementRef | undefined;
  @ViewChild(MatMenuTrigger) menuTrigger!: MatMenuTrigger;


  selectedOption: { key: string; value: string; icon?: string; } | undefined;
  isDropdownOpen = false;

  constructor(private renderer: Renderer2) {
    super();

    this.formControl.valueChanges.subscribe((value) => {
      this.selectedOption = this.options.find((option) => option.key === value);
    });
  }

  get selectedOptionValue() {
    const formValue = this.formControl.value;
    if (formValue === null || formValue === undefined) {
      return null;
    }
    return this.options.find((option) => option.key === formValue);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.metadata?.length !== undefined) {
      this.options = [
        {key: 'name', value: 'Name'},
        ...this.metadata.map((metaData: { key: any; }) => {
          return {key: metaData.key, value: metaData.key}
        })
      ];
    }
  }

  selectBlank() {
    this.selectedOption = undefined;
    this.formControl.setValue(undefined);
    this.isDropdownOpen = false;
  }

  handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter' || event.key === 'ArrowDown' || event.key === ' ') {
      this.menuTrigger?.openMenu();
      event.preventDefault();
    } else if (event.key === 'ArrowUp') {
      this.menuTrigger?.closeMenu();
      event.preventDefault();
    }

  }

  selectOption(option:  {key: string, value: string, icon?: string}) {
    this.selectedOption = option;
    this.formControl.setValue(option.key);
    this.isDropdownOpen = false;
  }

  setMenuWidth() {
    if (this.trigger === undefined) {
      return;
    }
    const width = this.trigger.nativeElement.offsetWidth;
    const menuElement = document.querySelector('.mat-mdc-menu-panel');
    if (menuElement) {
      this.renderer.setStyle(menuElement, 'min-width', `${width}px`);
    }
  }
}
