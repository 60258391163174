import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GraphMap, GraphNode, GraphNodeLink } from '../interfaces/graph-node.interface';

@Injectable({ providedIn: 'root' })
export class MapResource {
  private baseUrl= environment.apiUrl + '/map';
  constructor(private httpClient: HttpClient) {}

  findAllForUser(userId: string): Observable<GraphMap[]> {
    return this.httpClient.get<GraphMap[]>(this.baseUrl + '/user/' + userId);
  }

  findTemplates(): Observable<GraphMap[]> {
    return this.httpClient.get<GraphMap[]>(this.baseUrl + '/templates');
  }

  findByIdWithRelation(id: string): Observable<GraphMap> {
    return this.httpClient.get<GraphMap>(this.baseUrl + '/with-users/' + id);
  }

  createForUser(map: Partial<GraphMap>): Observable<GraphMap> {
    return this.httpClient.post<GraphMap>(this.baseUrl + '/create', map);
  }

  update(map: Partial<GraphMap>): Observable<GraphMap> {
    return this.httpClient.put<GraphMap>(this.baseUrl + '/' + map.id, map);
  }

  duplicate(map: Partial<GraphMap>): Observable<GraphMap> {
    return this.httpClient.post<GraphMap>(this.baseUrl + '/duplicate/', map);
  }

  delete(mapId: string): Observable<void> {
    return this.httpClient.delete<void>(this.baseUrl + '/' + mapId);
  }

  exportMapWithRelationsRecursive(mapId: string): Observable<BlobPart> {
    // @ts-ignore
    return this.httpClient.get<BlobPart>(this.baseUrl + '/export/' + mapId, { responseType: 'blob'});
  }

  copyToMap(nodes: GraphNode[], mapId: string, grandParent?: GraphNode | null): Observable<GraphNode[]> {
    return this.httpClient.post<GraphNode[]>(this.baseUrl + '/copy/' + mapId, {nodes, grandParent});
  }

  importMapFromZip(file: FormData): Observable<GraphMap> {
    return this.httpClient.post<GraphMap>(this.baseUrl + '/import', file);
  }

  createGptMapWithNodeAndEdges(graphNodes: GraphNode[], links: GraphNodeLink[], userId: string) {
    return this.httpClient.post(this.baseUrl + '/gpt/' + userId, {nodes: graphNodes, links});
  }

  shareMap(mapId: string, userIds: string[]) {
    return this.httpClient.post(this.baseUrl + '/share/' + mapId, {userIds});
  }

  unshareMap(mapId: string) {
    return this.httpClient.post(this.baseUrl + '/unshare/' + mapId, {});
  }

  publicGetMap(mapId: string): Observable<GraphMap> {
    return this.httpClient.get<GraphMap>(environment.apiUrl + '/public/map/' + mapId);
  }

  openPublicAccessToMap(mapId: string) {
    return this.httpClient.post(this.baseUrl + '/publish/' + mapId, {});
  }

  closePublicAccessToMap(mapId: string) {
    return this.httpClient.post(this.baseUrl + '/unpublish/' + mapId, {});
  }
}
