import cytoscape, { NodeSingular } from 'cytoscape';

import { ShapeDrawing } from './lib/shape-drawing';
import chroma from 'chroma-js';

const cy = cytoscape;

export function drawGradientNode(cy: any, selector = '.collapsed') {
  const layer = cy.cyCanvas();
  const canvas = layer.getCanvas();
  const ctx = canvas.getContext('2d');
  const drawing = new ShapeDrawing();
  cy.on('render cyCanvas.resize', () => {

    layer.resetTransform(ctx);
    layer.clear(ctx);
    layer.setTransform(ctx);

    // Draw model elements
    cy.$(selector).forEach(function (node: NodeSingular) {
      const bb = node.boundingBox({includeLabels: false});
      const bbCenter  = {
        x: (bb.x1 + bb.x2) / 2,
        y: (bb.y1 + bb.y2) / 2,
      }
      const pos = bbCenter;

      const halfWidth = node.width() / 2;
      const halfHeight = node.height() / 2;

      // Offset the gradient's center based on node shape
      const gradientCenterX = pos.x - halfWidth * 0.25;
      const gradientCenterY = pos.y - halfHeight * 0.25;

      // Calculate radial gradient radii based on node shape
      const calculatedGradientRadius = calculateGradientRadiusBasedOnShape(node.data('type'), halfWidth, halfHeight);
      const gradientRadiusX = calculatedGradientRadius.x;
      const gradientRadiusY = calculatedGradientRadius.y;



      // Create a radial gradient with the adjusted center and radii
      const gradient = ctx.createRadialGradient(
        gradientCenterX, gradientCenterY, 0, // Inner circle position (offset center)
        pos.x, pos.y, gradientRadiusY // Outer circle position (radius)
      );

      gradient.addColorStop(0, chroma(node.data('color')).brighten(2).hex()); // Inner color
      gradient.addColorStop(1, chroma(node.data('color')).darken().hex());// Outer color

      ctx.fillStyle = gradient;

      ctx.beginPath();
      // Implement custom paths for each shape
      if (node.data('type') === 'ELLIPSE') {
        drawing.drawEllipsePath(ctx, pos.x, pos.y, 2 * gradientRadiusX, 2 * gradientRadiusY);
      } else if (node.data('type') === 'RECTANGLE') {
        ctx.rect(pos.x - gradientRadiusX, pos.y - gradientRadiusY, 2 * gradientRadiusX, 2 * gradientRadiusY);
      } else if (node.data('type') === 'TRIANGLE') {
        ctx.moveTo(pos.x, pos.y - gradientRadiusY);
        ctx.lineTo(pos.x + gradientRadiusX, pos.y + gradientRadiusY);
        ctx.lineTo(pos.x - gradientRadiusX, pos.y + gradientRadiusY);
        ctx.closePath();
      } else if (node.data('type') === 'DIAMOND') {
        ctx.moveTo(pos.x, pos.y - gradientRadiusY);
        ctx.lineTo(pos.x + gradientRadiusX, pos.y);
        ctx.lineTo(pos.x, pos.y + gradientRadiusY);
        ctx.lineTo(pos.x - gradientRadiusX, pos.y);
        ctx.closePath();
      } else if (node.data('type') === 'ROUND-DIAMOND') {
        ctx.moveTo(pos.x, pos.y - gradientRadiusY);
        ctx.lineTo(pos.x + gradientRadiusX, pos.y);
        ctx.lineTo(pos.x, pos.y + gradientRadiusY);
        ctx.lineTo(pos.x - gradientRadiusX, pos.y);
        ctx.closePath();
      } else if(node.data('type') === 'ROUND-TRIANGLE') {
        const rad = gradientRadiusX / 4;  // Adjust for the roundness you want
        const top = { x: pos.x, y: pos.y - gradientRadiusY };
        const bottomRight = { x: pos.x + gradientRadiusX, y: pos.y + gradientRadiusY };
        const bottomLeft = { x: pos.x - gradientRadiusX, y: pos.y + gradientRadiusY };
        ctx.moveTo(top.x, top.y + rad);
        ctx.lineTo(bottomLeft.x + rad, bottomLeft.y - rad);
        ctx.arcTo(bottomLeft.x, bottomLeft.y, pos.x, pos.y, rad);
        ctx.lineTo(bottomRight.x - rad, bottomRight.y - rad);
        ctx.arcTo(bottomRight.x, bottomRight.y, top.x, top.y, rad);
        ctx.lineTo(top.x, top.y + rad);
        ctx.arcTo(top.x, top.y, bottomLeft.x, bottomLeft.y, rad);
        ctx.closePath();
      } else if(node.data('type') === 'ROUND-RECTANGLE') {
        const rad = gradientRadiusX / 4; // Adjust for the roundness you want
        ctx.moveTo(pos.x - gradientRadiusX + rad, pos.y - gradientRadiusY);
        ctx.arcTo(pos.x + gradientRadiusX, pos.y - gradientRadiusY, pos.x + gradientRadiusX, pos.y + gradientRadiusY, rad);
        ctx.arcTo(pos.x + gradientRadiusX, pos.y + gradientRadiusY, pos.x - gradientRadiusX, pos.y + gradientRadiusY, rad);
        ctx.arcTo(pos.x - gradientRadiusX, pos.y + gradientRadiusY, pos.x - gradientRadiusX, pos.y - gradientRadiusY, rad);
        ctx.arcTo(pos.x - gradientRadiusX, pos.y - gradientRadiusY, pos.x + gradientRadiusX, pos.y - gradientRadiusY, rad);
        ctx.closePath();
      } else if (node.data('type') === 'BOTTOM-ROUND-RECTANGLE') {
        const rad = gradientRadiusX / 4;
        ctx.moveTo(pos.x - gradientRadiusX, pos.y - gradientRadiusY);
        ctx.lineTo(pos.x + gradientRadiusX, pos.y - gradientRadiusY);
        ctx.arcTo(pos.x + gradientRadiusX, pos.y + gradientRadiusY, pos.x - gradientRadiusX, pos.y + gradientRadiusY, rad);
        ctx.arcTo(pos.x - gradientRadiusX, pos.y + gradientRadiusY, pos.x - gradientRadiusX, pos.y - gradientRadiusY, rad);
        ctx.closePath();
      } else if (node.data('type') === 'PENTAGON') {
        drawPolygon(ctx, pos.x, pos.y, gradientRadiusX, 5, 198);
      } else if (node.data('type') === 'HEXAGON') {
        drawPolygon(ctx, pos.x, pos.y, gradientRadiusX, 6, 0);
      } else if (node.data('type') === 'HEPTAGON') {
        drawPolygon(ctx, pos.x, pos.y, gradientRadiusX, 7, 13);
      } else if (node.data('type') === 'OCTAGON') {
        drawPolygon(ctx, pos.x, pos.y, gradientRadiusX, 8, 203);
      } else if (node.data('type') === 'RHOMBOID') {
        const skew = gradientRadiusX / 4; // Adjust for skewness

        ctx.moveTo(pos.x - gradientRadiusX, pos.y - gradientRadiusY);
        ctx.lineTo(pos.x + gradientRadiusX - skew, pos.y - gradientRadiusY);
        ctx.lineTo(pos.x + gradientRadiusX, pos.y + gradientRadiusY);
        ctx.lineTo(pos.x - gradientRadiusX + skew, pos.y + gradientRadiusY);
        ctx.closePath();
      } else if (node.data('type') === 'STAR') {
        const spikes = 5;
        const outerRadius = gradientRadiusX;
        const innerRadius = gradientRadiusX / 2.5; // Adjust for inner size

        ctx.beginPath();
        ctx.moveTo(pos.x, pos.y - outerRadius);

        for (let i = 0; i < spikes; i++) {
          const angle = (i * 2 * Math.PI) / spikes;
          ctx.lineTo(pos.x + innerRadius * Math.sin(angle + Math.PI / spikes), pos.y - innerRadius * Math.cos(angle + Math.PI / spikes));
          ctx.lineTo(pos.x + outerRadius * Math.sin(angle + 2 * Math.PI / spikes), pos.y - outerRadius * Math.cos(angle + 2 * Math.PI / spikes));
        }

        ctx.closePath();
      } else if(node.data('type') === 'TAG') {
        const cutSize = gradientRadiusX / 4;
        ctx.moveTo(pos.x - gradientRadiusX, pos.y - gradientRadiusY);
        ctx.lineTo(pos.x + gradientRadiusX - cutSize, pos.y - gradientRadiusY);
        ctx.lineTo(pos.x + gradientRadiusX, pos.y);
        ctx.lineTo(pos.x + gradientRadiusX - cutSize, pos.y + gradientRadiusY);
        ctx.lineTo(pos.x - gradientRadiusX, pos.y + gradientRadiusY);
        ctx.closePath();
      }

      ctx.fill();
      ctx.closePath();
    });
  });
}


function drawPolygon(ctx: any,
                     centerX: number,
                     centerY: number,
                     radius: number,
                     sides: number,
                     rotationDegrees = 0) {
  const rotationAngleRadiant = (Math.PI / 180) * rotationDegrees;
  ctx.beginPath();
  for (let i = 0; i < sides; i++) {
    const x = centerX + radius * Math.cos(rotationAngleRadiant + (i * 2 * Math.PI) / sides);
    const y = centerY + radius * Math.sin(rotationAngleRadiant + (i * 2 * Math.PI) / sides);

    if (i === 0) {
      ctx.moveTo(x, y)
    } else {
      ctx.lineTo(x, y);
    }
  }
  ctx.closePath();
}

function calculateGradientRadiusBasedOnShape(shape: string, width: number, height: number) {
  let gradientRadiusX = width;
  let gradientRadiusY = height;

  switch (shape) {
    case 'PENTAGON': {
      gradientRadiusX = width * 1.2;
      gradientRadiusY = height * 1.2;
    } break;
    case 'HEXAGON': {
      gradientRadiusX = width * 1.1;
      gradientRadiusY = height * 1.1;
    } break;
    case 'HEPTAGON': {
      gradientRadiusX = width * 1.1;
      gradientRadiusY = height * 1.1;
    } break;
    case 'OCTAGON': {
      gradientRadiusX = width * 1.1;
      gradientRadiusY = height * 1.1;
    } break;
  }

  return {
    x: gradientRadiusX,
    y: gradientRadiusY,
  }
}
