import { Component } from '@angular/core';
import { MarkdownModule } from 'ngx-markdown';
import { HttpClient } from '@angular/common/http';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-release-notes-dialog',
  template: `
    <div class="w-100 d-flex justify-content-between align-items-start dialog-header">
      <h2 class="align-items-center d-flex p-0 m-0"><span class="material-symbols-rounded me-2">help</span>Release Notes</h2>
      <mat-icon class="material-symbols-rounded close-button" (click)="closeDialog()" matTooltip="Close the panel">close</mat-icon>
    </div>
    <markdown class="p-3 md-content" [data]="markdownContent"></markdown>
  `,
  styles: [
    `
      .md-content {
        height: 50vh;
        overflow: auto;
      }

    `
  ],
  preserveWhitespaces: true,
  imports: [
    MarkdownModule,
    MatIconModule,
    MatTooltipModule
  ],
  standalone: true
})
export class ReleaseNotesDialogComponent {
  public markdownContent = '';

  constructor(private dialogRef: MatDialogRef<ReleaseNotesDialogComponent>,
              httpClient: HttpClient) {
    httpClient.get('assets/release/RELEASE-NOTES.MD', {responseType: 'text'}).subscribe((data: string) => {
      this.markdownContent = data;
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
