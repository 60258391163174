import {Injectable} from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {BehaviorSubject, catchError, filter, Observable, switchMap, take, tap, throwError} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../environments/environment';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {

  constructor(private router: Router,
              private activateRoute: ActivatedRoute,
              private http: HttpClient) {}

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken = localStorage.getItem('accessToken');

    if (accessToken) {
      request = this.addToken(request, accessToken);
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && !request.url.includes('/auth/refresh-token')) {
          if (this.isRefreshing) {
            return this.refreshTokenSubject.pipe(
              filter((result) => result !== null),
              take(1),
              switchMap(() => next.handle(this.addToken(request, localStorage.getItem('accessToken') || '')))
            );
          }
          this.isRefreshing = true;
          this.refreshTokenSubject.next(null);
          return this.refreshToken().pipe(
            switchMap(({ accessToken }) => {
              localStorage.setItem('accessToken', accessToken);
              this.isRefreshing = false;
              this.refreshTokenSubject.next(accessToken);
              return next.handle(this.addToken(request, accessToken));
            }),
            catchError((err) => {
              this.isRefreshing = false;
              localStorage.removeItem('accessToken');
              const mapId = this.activateRoute.snapshot.queryParams['mapId'];

              this.router.navigate(['/login'],  mapId ? {queryParams: {mapId}}: undefined);
              return throwError(err);
            })
          );
        } else {
          return throwError(error);
        }
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private refreshToken(): Observable<any> {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
      return throwError('No refresh token');
    }
    return this.http.post<{accessToken: string}>( environment.apiUrl + '/auth/refresh-token', { refreshToken }).pipe(
      tap(({ accessToken }) => {
        localStorage.setItem('accessToken', accessToken);
      })
    );
  }
}


